import React, { Fragment, useContext, useEffect, useState } from 'react';
import { withLayout } from '../../components/HOC/WithLayout';
import Portal from '../../components/Portal/Portal';
import { Button, PageHeader } from 'antd';
import { I18nContext } from 'react-i18next';
import { AppContext } from '../../context/AppProvider';
import Sidebar from '../../components/Sidebar/Sidebar';
import ProvidersList from '../../components/CRUD/provider/ProvidersList/ProvidersList';
import { DownloadOutlined, PlusOutlined } from '@ant-design/icons';
import ProviderForm from '../../components/CRUD/provider/ProviderForm/ProviderForm';
import dayjs from 'dayjs';
import json2CSVDownload from '../../modules/json2csv-download/json2csv-download';
import Skeleton from '../../components/Skeleton/Skeleton';
import Pagination from '../../components/Pagination/Pagination';
import ProviderFilters from '../../components/CRUD/provider/ProviderFilters/ProviderFilters';
import './providers-list-page.scss';
import { notify } from '../../modules/notify/notify';

const ProvidersListPage = props => {
    const {i18n} = useContext(I18nContext);
    const appContext = useContext(AppContext);
    const [createSidebarIsVisible, setCreateSidebarIsVisible] = useState(false);
    const [providers, setProviders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [downloading, setDownloading] = useState(false);
    const [filters, setFilters] = useState(JSON.parse(localStorage.getItem('providerFilters')));

    const search = (page = 1) => {
        setLoading(true);
        appContext.services.provider
            .search(page, filters)
            .then(providers => setProviders(providers))
            .catch(console.error)
            .finally(() => setLoading(false));
    };

    const createProvider = values => {
        appContext.services.provider
            .create(values)
            .then(response => {
                if (!response.success) return;
                notify.success({description: i18n.t('global.item_updated')});
                setCreateSidebarIsVisible(false);
                search();
            })
            .catch(console.error);
    };

    const downloadCSV = filters => {
        setDownloading(true);
        appContext.services.provider
            .search(1, filters, true)
            .then(providers => {
                const fields = ['idProvider', 'name', 'email', 'phone', 'contact', 'registration', 'active'];
                const rows = providers.map(provider => {
                    return {
                        ...provider,
                        contact: provider.internalContact,
                        registration: dayjs.unix(provider.tmsCreated).format('DD/MM/YYYY HH:mm:ss')
                    }
                });

                json2CSVDownload(fields, rows, i18n.t('entity.provider.title', {count: providers.length}));
            })
            .catch(error => {
                notify.error({description: i18n.t('global.request_error')});
                console.error(error);
            })
            .finally(() => setDownloading(false));
    };

    const persistFilters = filters => {
        localStorage.removeItem('providerFilters');
        if (filters && Object.keys(filters).length) {
            localStorage.setItem('providerFilters', JSON.stringify(filters));
        }
    };

    useEffect(() => {
        persistFilters(filters);
        search(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    return (
        <Fragment>
            <div className="providers-list-page">
                <ProviderFilters filters={filters}
                                 onFilter={filters => setFilters(filters)}
                />
                <Skeleton loading={loading}>
                    <ProvidersList data={providers?.items}
                                   onUpdate={search}
                    />
                    <Pagination totalItems={providers?.totalItems}
                                currentPage={providers?.currentPage}
                                itemsPerPage={providers?.itemsPerPage}
                                onChange={page => search(page)}
                    />
                </Skeleton>
            </div>

            <Portal.In target="page-header">
                <PageHeader title={i18n.t('page.providers.title')}
                            extra={[
                                <Button key="1"
                                        loading={downloading}
                                        icon={<DownloadOutlined />}
                                        disabled={!providers?.totalItems}
                                        onClick={event => downloadCSV(filters)}>
                                    CSV
                                </Button>,
                                <Button key="2"
                                        type="primary"
                                        icon={<PlusOutlined />}
                                        onClick={event => setCreateSidebarIsVisible(true)}>
                                    {i18n.t('entity.provider.title')}
                                </Button>
                            ]}
                />
            </Portal.In>

            <Sidebar visible={createSidebarIsVisible}
                     title={i18n.t('entity.provider.create_title')}
                     onClose={event => setCreateSidebarIsVisible(false)}>
                <ProviderForm onSubmit={createProvider} />
            </Sidebar>
        </Fragment>
    );
};

export default withLayout(ProvidersListPage);
