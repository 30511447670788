import SelectField from '../../../Form/Fields/SelectField';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../../context/AppProvider';
import debounce from '../../../../modules/debounce/debounce';
import {Fragment} from "react";

const ClientSelectField = props => {
    const appContext = useContext(AppContext);
    const [clients, setClients] = useState([]);
    const [query, setQuery] = useState({});
    const [fetching, setFetching] = useState(true);
    const [currentClientId, setCurrentClientId] = useState(null);

    const search = () => {
        const query = {};
        if (currentClientId)
            query.client = currentClientId;

        appContext.services.client
            .query(query)
            .then(clients => {
                const responseClients = clients ?? [];

                setClients(responseClients)
            })
            .finally(() => setFetching(false));
    };

    useEffect(() => {
        setCurrentClientId(props?.client ?? null)
    }, [props]);

    useEffect(search, [query, currentClientId]);

    const selectFieldProps = {
        ...props,
        options: clients,
        labelInValue: true,
        loading: fetching,
        onSearch: debounce(setQuery, 1000),
    };

    if (currentClientId !== null) {
        selectFieldProps.value = currentClientId;
    }

    if (!fetching) {
        return (
            <Fragment>
                <SelectField {...selectFieldProps} />
            </Fragment>
        );
    } else {
        // Puedes mostrar un mensaje de carga u otra interfaz mientras se cargan los datos.
        return <div>Cargando...</div>;
    }
};

export default ClientSelectField;
