import React, { Fragment, useContext, useState } from 'react';
import { AppContext } from '../../context/AppProvider';
import { isAdmin } from '../../modules/user-roles/user-roles';
import { Menu, Tag } from 'antd';
import logo from '../../assets/images/logo_agenciaseso.svg';
import { Link, useLocation } from 'react-router-dom';
import { MenuOutlined, RightOutlined, UserOutlined, LogoutOutlined } from '@ant-design/icons';
import './main-navigation.scss';
import { I18nContext } from 'react-i18next';

const MainNavigation = props => {
    const routerLocation = useLocation();
    const initialPathname = props.default || routerLocation.pathname;
    const [currentLocaltion, setCurrentLocation] = useState(initialPathname);
    const appContext = useContext(AppContext);
    const {i18n} = useContext(I18nContext);
    const [collapsed, setCollapsed] = useState(true);

    const logOut = () => {
        appContext.services.base.logout();
        props.history.replace('/login');
    };

    const toggleCollapsed = event => {
        setCollapsed(!collapsed);
    };

    return (
        <nav className="main-navigation">
            <header className="main-navigation__header">
                <Link to="/"
                      className="main-navigation__brand">
                    <img src={logo} alt="Agencia SEO"/>
                </Link>
                <div className={`main-navigation__toggler ${!collapsed ? 'main-navigation__toggler--is-open' : ''}`}
                     onClick={toggleCollapsed}>
                    <MenuOutlined />
                </div>
            </header>
            <section className={`main-navigation__nav main-navigation__nav--eager ${!collapsed ? 'main-navigation__nav--is-visible' : ''}`}>
                <Menu selectedKeys={[currentLocaltion]}
                      defaultOpenKeys={['/link-types', '/status', '/price-types', '/topics', '/users', '/media-types'].includes(currentLocaltion) ? ['other'] : null}
                      mode="inline"
                      onClick={event => setCurrentLocation(event.key)}>
                    <Menu.Item key="/media"
                               icon={<RightOutlined />}>
                        <Link to="/media">
                            {i18n.t('page.media.menu')}
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="/providers"
                               icon={<RightOutlined />}>
                        <Link to="/providers">
                            {i18n.t('page.providers.menu')}
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="/clients"
                               icon={<RightOutlined />}>
                        <Link to="/clients">
                            {i18n.t('page.clients.menu')}
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="/packs"
                               icon={<RightOutlined />}>
                        <Link to="/packs">
                            {i18n.t('page.packs.menu')}
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="/transactions"
                               icon={<RightOutlined />}>
                        <Link to="/transactions">
                            {i18n.t('page.transactions.menu')}
                        </Link>
                    </Menu.Item>
                    <Menu.SubMenu key="other"
                        title={<span><RightOutlined /><span>{i18n.t('global.other_plural')}</span></span>}>
                        <Menu.Item key="/clients-budgets"
                                   icon={<RightOutlined />}>
                            <Link to="/clients-budgets">
                                {i18n.t('page.clients_budgets.menu')}
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="/link-types"
                                   icon={<RightOutlined />}>
                            <Link to="/link-types">
                                {i18n.t('page.link_types.menu')}
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="/status"
                                   icon={<RightOutlined />}>
                            <Link to="/status">
                                {i18n.t('page.status.menu')}
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="/price-types"
                                   icon={<RightOutlined />}>
                            <Link to="/price-types">
                                {i18n.t('page.price_types.menu')}
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="/topics"
                                   icon={<RightOutlined />}>
                            <Link to="/topics">
                                {i18n.t('page.topics.menu')}
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="/users"
                                   icon={<RightOutlined />}>
                            <Link to="/users">
                                {i18n.t('page.users.menu')}
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="/media-types"
                                   icon={<RightOutlined />}>
                            <Link to="/media-types">
                                {i18n.t('page.media_types.menu')}
                            </Link>
                        </Menu.Item>
                    </Menu.SubMenu>
                </Menu>
            </section>
            <section className={`main-navigation__nav ${!collapsed ? 'main-navigation__nav--is-visible' : ''}`}>
                <Menu selectedKeys={[currentLocaltion]}
                      mode="inline"
                      onClick={event => setCurrentLocation(event.key)}>
                    <Menu.Item key="3"
                               icon={<UserOutlined />}>
                        {props.user.username}
                        {!!isAdmin(props.user) &&
                        <Fragment> <Tag color="magenta">Admin</Tag></Fragment>}
                    </Menu.Item>
                    <Menu.Item onClick={logOut}
                               icon={<LogoutOutlined />}>
                        Logout
                    </Menu.Item>
                </Menu>
            </section>
        </nav>
    );
};

export default MainNavigation;
