import React, { Fragment, useContext, useEffect, useState } from 'react';
import { withLayout } from '../../components/HOC/WithLayout';
import Portal from '../../components/Portal/Portal';
import {Button, PageHeader} from 'antd';
import { I18nContext } from 'react-i18next';
import { DownloadOutlined, PlusOutlined } from '@ant-design/icons';
import Sidebar from '../../components/Sidebar/Sidebar';
import { AppContext } from '../../context/AppProvider';
import MediaList from '../../components/CRUD/media/MediaList/MediaList';
import CustomMediaColumns from '../../components/CRUD/media/MediaList/CustomMediaColumns';
import MediaForm from '../../components/CRUD/media/MediaForm/MediaForm';
import './media-list-page.scss';
import json2CSVDownload from '../../modules/json2csv-download/json2csv-download';
import Skeleton from '../../components/Skeleton/Skeleton';
import Pagination from '../../components/Pagination/Pagination';
import getCountryName from '../../modules/get-country/get-country-name';
import getProvinceName from '../../modules/get-province/get-province-name';
import MediaFilters from '../../components/CRUD/media/MediaFilters/MediaFilters';
import { notify } from '../../modules/notify/notify';

const MediaListPage = props => {
    const {i18n} = useContext(I18nContext);
    const appContext = useContext(AppContext);
    const [createSidebarIsVisible, setCreateSidebarIsVisible] = useState(false);
    const [customColumnsIsVisible, setCustomColumnsIsVisible] = useState(false);
    const [media, setMedia] = useState([]);
    const [loading, setLoading] = useState(true);
    const [downloading, setDownloading] = useState(false);
    const [filters, setFilters] = useState({});
    const [columns, setColumns] = useState([]);

    const search = (page = 1) => {

        setLoading(true);
        appContext.services.media
            .search(page, filters)
            .then(media => setMedia(media))
            .catch(console.error)
            .finally(() => setLoading(false));
    };

    const createMedia = values => {
        appContext.services.media
            .create(values)
            .then(response => {
                if (!response.success) return;
                notify.success({description: i18n.t('global.item_updated')});
                setCreateSidebarIsVisible(false);
                search();
            })
            .catch(error => {
                console.error(error);
                notify.error({message: `${error.name}: ${error.message}`, description: i18n.t('entity.pack.error.unique_constraint_violation_exception')});
            });
    };

    const downloadCSV = filters => {
        setDownloading(true);
        appContext.services.media
            .search(1, filters, true)
            .then(async media => {
                const columnsVisible = await appContext.services.media.getListColumns(i18n);

                const defaultFields = ['idMedia'];
                const fieldsToShow = [...defaultFields, ...columnsVisible.filter(c => c.visible).map(c => c.exportField)];

                const fields = fieldsToShow;
                const rows = media.map(media => {
                    return {
                        ...media,
                        country: getCountryName(media.country),
                        province: getProvinceName(media.province, media.country),
                        linkTypes: media?.linkType?.length
                            ? media.linkType.map(linktype => linktype.type).join(', ')
                            : '',
                        topics: media?.topics?.length
                            ? media.topics.map(topic => topic.name).join(', ')
                            : '',
                        type: media?.type?.name || '',
                        pvp_price: media?.price?.length
                            ? media.price.map(price => price.pvpPrice + '€').join(', ')
                            : '',
                        managed_price: media?.price?.length
                            ? media.price.map(price => price.managedPrice + '€').join(', ')
                            : '',
                        media_price: media?.price?.length
                            ? media.price.map(price => price.mediaPrice + '€').join(', ')
                            : '',
                    };
                });

                json2CSVDownload(fields, rows, i18n.t('entity.media.title', {count: media.length}));
            })
            .catch(console.error)
            .finally(() => setDownloading(false));
    };

    const toggleCustomColumnsSidebarVisibility = visible => {
        setCustomColumnsIsVisible(visible);
    };

    const persistFilters = filters => {
        localStorage.removeItem('mediaFilters');
        if (filters && Object.keys(filters).length) {
            localStorage.setItem('mediaFilters', JSON.stringify(filters));
        }
    };

    const getColumns = () => {
        const columns = appContext.services.media.getListColumns(i18n);
        setColumns(columns);
    };

    useEffect(() => {
        persistFilters(filters);
        search(1);
        getColumns();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    return (
        <Fragment>
            <div className="media-list-page">
                <MediaFilters filters={filters}
                              onFilter={filters => setFilters(filters)}
                />
                <Skeleton loading={loading}>
                    <MediaList columns={columns} toggleCustomColumnsSidebarVisibility={toggleCustomColumnsSidebarVisibility} data={media?.items}
                               onUpdate={search}
                    />
                    <Pagination totalItems={media?.totalItems}
                                currentPage={media?.currentPage}
                                itemsPerPage={media?.itemsPerPage}
                                onChange={page => search(page)}
                    />
                </Skeleton>
            </div>

            <Portal.In target="page-header">
                <PageHeader title={i18n.t('page.media.title')}
                            extra={[
                                <Button key="1"
                                        loading={downloading}
                                        icon={<DownloadOutlined />}
                                        disabled={!media?.totalItems}
                                        onClick={event => downloadCSV(filters)}>
                                    CSV
                                </Button>,
                                <Button key="2"
                                        type="primary"
                                        icon={<PlusOutlined />}
                                        onClick={event => setCreateSidebarIsVisible(true)}>
                                    {i18n.t('entity.media.title')}
                                </Button>
                            ]}
                />
            </Portal.In>

            <Sidebar visible={createSidebarIsVisible}
                     title={i18n.t('entity.media.create_title')}
                     onClose={event => setCreateSidebarIsVisible(false)}>
                <MediaForm onSubmit={createMedia} />
            </Sidebar>
            <Sidebar visible={customColumnsIsVisible}
                     footerCloseBtnText={i18n.t('global.close')}
                     title={i18n.t('entity.media.customize_columns')}
                     onClose={event => setCustomColumnsIsVisible(false)}>
                <CustomMediaColumns columns={columns} onColumnsUpdate={getColumns}/>
            </Sidebar>
        </Fragment>
    );
};

export default withLayout(MediaListPage);
