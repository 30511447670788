import React, { Fragment, useContext, useState } from 'react';
import { Space, Table } from 'antd';
import { I18nContext } from 'react-i18next';
import { AppContext } from '../../../../context/AppProvider';
import ConfirmButton from '../../../ConfirmButton/ConfirmButton';
import EditButton from '../../../EditButton/EditButton';
import Sidebar from '../../../Sidebar/Sidebar';
import StatusForm from '../StatusForm/StatusForm';
import { notify } from '../../../../modules/notify/notify';
import StatusTag from '../../../StatusTag/StatusTag';

const StatusList = props => {
    const {i18n} = useContext(I18nContext);
    const appContext = useContext(AppContext);
    const [editSidebarIsVisible, setEditSidebarIsVisible] = useState(false);
    const [currentStatus, setCurrentStatus] = useState({});

    const removeStatus = idStatus => {
        appContext.services.status
            .remove(idStatus)
            .then(response => {
                notify.success({description: i18n.t('global.item_updated')});
                props.onUpdate();
            });
    };

    const updateStatus = (values, id) => {
        appContext.services.status
            .update(id, values)
            .then(response => {
                if (!response.success) return;
                setEditSidebarIsVisible(false);
                notify.success({description: i18n.t('global.item_updated')});
                props.onUpdate();
            })
            .catch(console.error);
    };

    const getColumns = i18n => {
        return [
            {
                title: i18n.t('Id'),
                dataIndex: 'idStatus',
                key: 'idStatus'
            },
            {
                title: i18n.t('entity.status.property.description'),
                dataIndex: 'description',
                key: 'description',
                render: (column, row) => <StatusTag status={row} />
            },
            {
                title: '',
                dataIndex: 'actions',
                key: 'actions',
                align: 'right',
                render: (column, row) => (
                    <Space>
                        <ConfirmButton disabled={row.isBlocked || row.transactions?.length}
                                       onConfirm={event => removeStatus(row.idStatus)}
                        />
                        <EditButton onClick={event => {
                            setCurrentStatus(row);
                            setEditSidebarIsVisible(true);
                        }} />
                    </Space>
                )
            }
        ];
    };

    return (
        <Fragment>
            <Table columns={getColumns(i18n)}
                   dataSource={props.data}
                   pagination={false}
                   rowKey={row => row.idStatus}
            />
            <Sidebar visible={editSidebarIsVisible}
                     title={props.entityTitle}
                     onClose={event => setEditSidebarIsVisible(false)}>
                <StatusForm status={currentStatus}
                            onSubmit={updateStatus}
                />
            </Sidebar>
        </Fragment>
    );
};

StatusList.defaultProps = {
    onUpdate: () => {}
};

export default StatusList;
