import React, { Fragment, useContext, useEffect, useState } from 'react';
import { withLayout } from '../../components/HOC/WithLayout';
import Portal from '../../components/Portal/Portal';
import { Button, PageHeader } from 'antd';
import { I18nContext } from 'react-i18next';
import { AppContext } from '../../context/AppProvider';
import Sidebar from '../../components/Sidebar/Sidebar';
import { DownloadOutlined, PlusOutlined } from '@ant-design/icons';
import TransactionsList from '../../components/CRUD/transaction/TransactionsList/TransactionsList';
import TransactionForm from '../../components/CRUD/transaction/TransactionForm/TransactionForm';
import './transactions-list-page.scss';
import dayjs from 'dayjs';
import json2CSVDownload from '../../modules/json2csv-download/json2csv-download';
import TransactionFilters from '../../components/CRUD/transaction/TransactionFilters/TransactionFilters';
import Skeleton from '../../components/Skeleton/Skeleton';
import Pagination from '../../components/Pagination/Pagination';
import { notify } from '../../modules/notify/notify';
import { ACTION, PACK_ACTION } from '../../components/CRUD/constants';

const TransactionsListPage = props => {
    const {i18n} = useContext(I18nContext);
    const appContext = useContext(AppContext);
    const [createSidebarIsVisible, setCreateSidebarIsVisible] = useState(false);
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [downloading, setDownloading] = useState(false);
    const [filters, setFilters] = useState(JSON.parse(localStorage.getItem('transactionFilters')));

    const search = (page = 1) => {
        setLoading(true);
        appContext.services.transaction
            .search(page, filters)
            .then(transactions => setTransactions(transactions))
            .catch(console.error)
            .finally(() => setLoading(false));
    };

    const createTransaction = values => {
        appContext.services.transaction
            .create(values, PACK_ACTION.DEDUCT)
            .then(response => {
                if (!response.success) return;
                notify.success({description: i18n.t('global.item_updated')});
                setCreateSidebarIsVisible(false);
                search();
            })
            .catch(console.error);
    };

    const downloadCSV = filters => {
        setDownloading(true);
        appContext.services.transaction
            .search(1, filters, true)
            .then(transactions => {
                const fields = ['idTransaction', 'urlOrigin', 'urlDestination', 'anchor', 'price', 'status', 'shared', 'active', 'tmsCreated', 'tmsUpdated'];
                const rows = transactions.map(transaction => {
                    return {
                        ...transaction,
                        status: transaction?.status?.description,
                        tmsCreated: dayjs.unix(transaction.tmsCreated).format('DD/MM/YYYY HH:mm:ss'),
                        tmsUpdated: dayjs.unix(transaction.tmsUpdated).format('DD/MM/YYYY HH:mm:ss')
                    }
                });

                json2CSVDownload(fields, rows, i18n.t('entity.transaction.title', {count: transactions.length}));
            })
            .catch(console.error)
            .finally(() => setDownloading(false));
    };

    const persistFilters = filters => {
        localStorage.removeItem('transactionFilters');
        if (filters && Object.keys(filters).length) {
            localStorage.setItem('transactionFilters', JSON.stringify(filters));
        }
    };

    useEffect(() => {
        persistFilters(filters);
        search(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    return (
        <Fragment>
            <div className="transactions-list-page">
                <TransactionFilters filters={filters}
                                    onFilter={filters => setFilters(filters)}
                />
                <Skeleton loading={loading}>
                    <TransactionsList data={transactions?.items}
                                      onUpdate={search}
                    />
                    <Pagination totalItems={transactions?.totalItems}
                                currentPage={transactions?.currentPage}
                                itemsPerPage={transactions?.itemsPerPage}
                                onChange={page => search(page)}
                    />
                </Skeleton>
            </div>

            <Portal.In target="page-header">
                <PageHeader title={i18n.t('page.transactions.title')}
                            extra={[
                                <Button key="1"
                                        loading={downloading}
                                        icon={<DownloadOutlined />}
                                        disabled={!transactions?.totalItems}
                                        onClick={event => downloadCSV(filters)}>
                                    CSV
                                </Button>,
                                <Button key="2"
                                        type="primary"
                                        icon={<PlusOutlined />}
                                        onClick={event => setCreateSidebarIsVisible(true)}>
                                    {i18n.t('entity.transaction.title')}
                                </Button>
                            ]}
                />
            </Portal.In>

            <Sidebar visible={createSidebarIsVisible}
                     title={i18n.t('entity.transaction.create_title')}
                     onClose={event => setCreateSidebarIsVisible(false)}>
                <TransactionForm action={ACTION.CREATE}
                                 onSubmit={createTransaction}
                />
            </Sidebar>
        </Fragment>
    );
};

export default withLayout(TransactionsListPage);
