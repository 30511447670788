import BaseService from './BaseService';
import convertToSelectOptions from '../modules/convert-to-select-options/convert-to-select-options';


export default class MediaService extends BaseService {
    entityEndpoint = 'media';

    findAll(page = 1) {
        return super.findAll.call(this, this.entityEndpoint, page);
    }

    saveListColumns (newData) {
        // Guarda el contenido de la tabla en localStorage
        localStorage.setItem('mediaColumnsData', JSON.stringify(newData));
        localStorage.setItem('mediaColumnsVersion', this.getListColumnsVersion());
    };
    getListColumnsVersion () {
        return '1.3';
    };
    getListColumns (i18n) {
            const storageData = localStorage.getItem('mediaColumnsData');

            const mediaColumnsVersion = this.getListColumnsVersion();
            const storedMediaColumnsVersion = localStorage.getItem('mediaColumnsVersion');

            if (storedMediaColumnsVersion && storedMediaColumnsVersion === mediaColumnsVersion && storageData) {
                console.info('Columns from storage');
                return JSON.parse(storageData);
            } else {
                console.info('Columns from live data');
            }

            //Nombre - Dominio - Tipos de link - país - PVP - contacto.
            return [
                {
                    name: i18n.t('entity.media.property.name'),
                    title: i18n.t('entity.media.property.name'),
                    key: 'name',
                    exportField: 'name',
                    index: 0,
                    visible: true,
                },
                {
                    key: 'domain',
                    name: i18n.t('entity.media.property.domain'),
                    title: i18n.t('entity.media.property.domain'),
                    index: 2,
                    exportField: 'domain',
                    visible: true,
                },
                {
                    key: 'linkType',
                    exportField: 'linkTypes',
                    name: i18n.t('entity.media.property.link_type_plural'),
                    title: i18n.t('entity.media.property.link_type_plural'),
                    index: 3,
                    visible: true,
                },
                {
                    name: i18n.t('entity.media.property.country'),
                    title: i18n.t('entity.media.property.country'),
                    exportField: 'country',
                    index: 4,
                    visible: true,
                    key: 'country',
                },
                {
                    name: i18n.t('entity.media.property.pvp_price'),
                    title: i18n.t('entity.media.property.pvp_price'),
                    exportField: 'pvp_price',
                    visible: true,
                    index: 5,
                    key: 'pvp_price',
                },
                {
                    name: i18n.t('entity.media.property.provider'),
                    title: i18n.t('entity.media.property.provider'),
                    exportField: 'provider',
                    visible: true,
                    index: 6,
                    key: 'provider',
                },
                {
                    key: 'type',
                    name: i18n.t('entity.media.property.media_type'),
                    title: i18n.t('entity.media.property.media_type'),
                    exportField: 'type',
                    index: 7,
                    visible: false,
                },
                {
                    name: i18n.t('entity.media.property.province'),
                    title: i18n.t('entity.media.property.province'),
                    exportField: 'province',
                    visible: false,
                    index: 8,
                    key: 'province',
                },
                {
                    name: i18n.t('entity.media.property.managed_price'),
                    title: i18n.t('entity.media.property.managed_price'),
                    exportField: 'managed_price',
                    visible: false,
                    index: 9,
                    key: 'managed_price',
                },
                {
                    name: i18n.t('entity.media.property.media_price'),
                    title: i18n.t('entity.media.property.media_price'),
                    exportField: 'media_price',
                    visible: false,
                    index: 10,
                    key: 'media_price',
                },
                {
                    name: i18n.t('global.registration_date'),
                    title: i18n.t('global.registration_date'),
                    exportField: 'registration_date',
                    visible: false,
                    index: 11,
                    key: 'tmsCreated',
                },
                {
                    name: i18n.t('page.topics.title'),
                    title: i18n.t('page.topics.title'),
                    exportField: 'topics',
                    visible: false,
                    index: 12,
                    key: 'topics',
                },
                {
                    name: i18n.t('global.active'),
                    title: i18n.t('global.active'),
                    exportField: 'active',
                    visible: false,
                    index: 13,
                    key: 'active',
                },
            ];
    }

    findList(active = true) {
        const endpoint = `${this.entityEndpoint}/list`;

        return this
            .fetch(`${this.API_URL}${endpoint}`, {method: 'GET'}, {active})
            .then(response => convertToSelectOptions(response, 'idMedia', 'name'));
    }

    search(page = 1, filters = {}, list = undefined) {
        //const prices = filters?.prices ?? [];
        const managedPriceRangesQuery = filters?.managedPriceRange ?? [];
        const mediaPriceRangeQuery = filters?.mediaPriceRange ?? [];
        const pvpPriceRangesQuery = filters?.pvpPriceRange ?? [];

        const adaptedFilters = {
            ...filters,
            creationRange: filters?.creationRange ? filters.creationRange.join(',') : undefined,
            topics: filters?.topics ? filters.topics.join(',') : undefined,
            linkTypes: filters?.linkTypes ? filters.linkTypes.join(',') : undefined,
            //prices: [...new Set(prices)].join(',') || undefined,
            //pvpPrices: filters?.pvpPrices ? filters.pvpPrices.join(',') : undefined,
            //managedPrices: filters?.managedPrices ? filters.managedPrices.join(',') : undefined,
            provider: filters?.provider ? filters.provider.value : undefined,
            managedPriceRange: [...new Set(managedPriceRangesQuery)].join(',') || undefined,
            pvpPriceRange: [...new Set(pvpPriceRangesQuery)].join(',') || undefined,
            mediaPriceRange: [...new Set(mediaPriceRangeQuery)].join(',') || undefined,
        };

        return super.search.call(this, this.entityEndpoint, page, adaptedFilters, list);
    }

    query(query) {
        return super.query.call(this, this.entityEndpoint, query)
            .then(response => response ? convertToSelectOptions(response, 'idMedia', 'name') : []);
    }

    findById(idMedia) {
        return super.findById.call(this, this.entityEndpoint, idMedia);
    }

    remove(idMedia) {
        return super.remove.call(this, this.entityEndpoint, idMedia);
    }

    toggleActive(idMedia, active) {
        return super.toggleActive.call(this, this.entityEndpoint, idMedia, active);
    }

    create(values) {
        return super.create.call(this, this.entityEndpoint, values);
    }

    update(idMedia, values) {
        return super.update.call(this, this.entityEndpoint, idMedia, values);
    }

    updatePricing(id, values) {
        const endpoint = `${this.entityEndpoint}/${id}/update-pricing`;
        const data = new URLSearchParams();
        Object.entries(values).map(([key, value]) => data.append(key, value.toString()));

        return this
            .fetch(`${this.API_URL}${endpoint}`,
                {
                    method: 'PUT',
                    body: data
                },
                null,
                {'Content-Type': 'application/x-www-form-urlencoded'}
            )
            .then(response => response);
    }

    addTopic(idMedia, idTopic) {
        const endpoint = `${this.entityEndpoint}/${idMedia}/add-topic/${idTopic}`;

        return this
            .fetch(`${this.API_URL}${endpoint}`, {method: 'PUT'})
            .then(response => response);
    }

    removeTopic(idMedia, idTopic) {
        const endpoint = `${this.entityEndpoint}/${idMedia}/remove-topic/${idTopic}`;

        return this
            .fetch(`${this.API_URL}${endpoint}`, {method: 'PUT'})
            .then(response => response);
    }

    addLinkType(idMedia, idLinkType) {
        const endpoint = `${this.entityEndpoint}/${idMedia}/add-link-type/${idLinkType}`;

        return this
            .fetch(`${this.API_URL}${endpoint}`, {method: 'PUT'})
            .then(response => response);
    }

    removeLinkType(idMedia, idLinkType) {
        const endpoint = `${this.entityEndpoint}/${idMedia}/remove-link-type/${idLinkType}`;

        return this
            .fetch(`${this.API_URL}${endpoint}`, {method: 'PUT'})
            .then(response => response);
    }
}
