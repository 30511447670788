import React, { useContext } from 'react';
import { Drawer, Space } from 'antd';
import { I18nContext } from 'react-i18next';
import './sidebar.scss';
import Button from '../Button/Button';
import Portal from '../Portal/Portal';

const Sidebar = props => {
    const {i18n} = useContext(I18nContext);

    return (
        <div className="sidebar">
            <Drawer title={props.title}
                    placement={props.placement}
                    width={620}
                    destroyOnClose={true}
                    visible={props.visible}
                    onClose={props.onClose}
                    footer={
                        <div className="sidebar__footer">
                            {props.footer || <Space>
                                <Button onClick={props.onClose}>
                                    { props.footerCloseBtnText || i18n.t('global.cancel')}
                                </Button>
                                <Portal.Out id="sidebar-footer" />
                            </Space>}
                        </div>
                    }>
                <div className="sidebar__contents">{props.children}</div>
            </Drawer>
        </div>
    );
};

Sidebar.defaultProps = {
    title: '',
    placement: 'right',
    visible: false,
    onClose: () => {},
    onAccept: () => {},
    footerCloseBtnText: '',
};

export default Sidebar;
