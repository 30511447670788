import React, { Fragment, useContext, useState } from 'react';
import Button from '../../../Button/Button';
import { ShoppingCartOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { I18nContext } from 'react-i18next';
import { notify } from '../../../../modules/notify/notify';
import { AppContext } from '../../../../context/AppProvider';
import ClientBudgetForm from '../ClientBudgetForm/ClientBudgetForm';
import Sidebar from '../../../Sidebar/Sidebar';
import { ACTION } from '../../constants';

const CreateClientBudgetButton = props => {
    const {i18n} = useContext(I18nContext);
    const appContext = useContext(AppContext);
    const [createSidebarIsVisible, setCreateSidebarIsVisible] = useState(false);
    const [currentClientBudget, setCurrentClientBudget] = useState({});

    const createClientBudget = values => {
        appContext.services.clientsBudgets
            .create(values)
            .then(response => {
                if (!response.success) return;
                notify.success({description: i18n.t('global.item_updated')});
                setCreateSidebarIsVisible(false);
                props.onCreate();
            })
            .catch(console.error);
    };

    return (
        <Fragment>
            <Tooltip title={i18n.t('entity.clientBudget.create_title')}>
                <div>
                    <Button icon={<ShoppingCartOutlined />}
                            shape="circle"
                            onClick={event => {
                                setCurrentClientBudget({
                                });
                                setCreateSidebarIsVisible(true);
                            }}
                    />
                </div>
            </Tooltip>

            <Sidebar visible={createSidebarIsVisible}
                     title={i18n.t('entity.clientBudget.create_title')}
                     onClose={event => setCreateSidebarIsVisible(false)}>
                <ClientBudgetForm clientBudget={currentClientBudget}
                                 action={ACTION.CREATE}
                                 onSubmit={createClientBudget}
                />
            </Sidebar>
        </Fragment>
    );
};

CreateClientBudgetButton.defaultProps = {
    onCreate: () => {}
};

export default CreateClientBudgetButton;
