import BaseService from './BaseService';
import convertToSelectOptions from '../modules/convert-to-select-options/convert-to-select-options';
import transactionPostProcessor from '../modules/post-processor/transaction-post-processor';

export default class TransactionService extends BaseService {
    entityEndpoint = 'transaction';

    search(page = 1, filters = {}, list = undefined) {
        const adaptedFilters = {
            ...filters,
            creationRange: filters?.creationRange ? filters.creationRange.join(',') : undefined,
            client: filters?.client ? filters.client.value : undefined,
            media: filters?.status ? filters.status.value : undefined,
            pack: filters?.pack ? filters.pack.value : undefined,
            publicationRange: filters?.publicationRange ? filters.publicationRange.join(',') : undefined
        };

        return super.search.call(this, this.entityEndpoint, page, adaptedFilters, list);
    }

    query(query) {
        return super.query.call(this, this.entityEndpoint, query)
            .then(response => response ? convertToSelectOptions(response, 'idTransaction', 'urlOrigin') : []);
    }

    findAll(page = 1) {
        return super.findAll.call(this, this.entityEndpoint, page);
    }

    findById(idTransaction) {
        return super.findById.call(this, this.entityEndpoint, idTransaction);
    }

    remove(idTransaction, packAction) {
        const endpoint = `${this.entityEndpoint}/${idTransaction}`;
        const params = {packAction} || {};
        console.log(packAction, params);

        return this
            .fetch(`${this.API_URL}${endpoint}`, {method: 'DELETE'}, params)
            .then(response => response);
    }

    toggleActive(idTransaction, active) {
        return super.toggleActive.call(this, this.entityEndpoint, idTransaction, active);
    }

    create(values, packAction = null) {
        const adaptedValues = packAction
            ? {...values, packAction}
            : values;
        return super.create.call(this, this.entityEndpoint, transactionPostProcessor(adaptedValues));
    }

    update(idTransaction, values, packAction = null) {
        const adaptedValues = packAction
            ? {...values, packAction}
            : values;
        return super.update.call(this, this.entityEndpoint, idTransaction, transactionPostProcessor(adaptedValues));
    }
}
