import React from 'react';
import AppProvider from './context/AppProvider';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRoutes from './routes/AppRoutes';
import PortalProvider from './context/PortalProvider';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n/i18n';
import getLanguage from './modules/get-language/get-language';
import dayjs from 'dayjs';
import es from 'dayjs/locale/es';
import en from 'dayjs/locale/en';

const App = props => {
    dayjs.locale(getLanguage() === 'es' ? es : en);

    return (
        <I18nextProvider i18n={i18n}>
            <AppProvider>
                <PortalProvider>
                    <Router>
                        <AppRoutes />
                    </Router>
                </PortalProvider>
            </AppProvider>
        </I18nextProvider>
    );
};

export default App;
