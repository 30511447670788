import React, { useContext, useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import Button from '../../../Button/Button';
import Portal from '../../../Portal/Portal';
import { I18nContext } from 'react-i18next';
import { Col, Row } from 'antd';
import SelectField from '../../../Form/Fields/SelectField';
import { AppContext } from '../../../../context/AppProvider';
import convertToSelectOptions from '../../../../modules/convert-to-select-options/convert-to-select-options';

const MediaSelectForm = props => {
    const {i18n} = useContext(I18nContext);
    const appContext = useContext(AppContext);
    const [media, setMedia] = useState([]);

    const findMedia = () => {
        appContext.services.media
            .search(1, props.filters, true)
            .then(media => setMedia(convertToSelectOptions(media, 'idMedia', 'name')))
            .catch(console.error);
    };

    useEffect(findMedia, []);

    return (
        <Formik enableReinitialize={true}
                initialValues={{
                    idMedia: '',
                }}
                onSubmit={(values, {setSubmitting}) => {
                    props.onSubmit(values);
                    setSubmitting(false);
                }}>
            {props => (
                <Form>
                    <Row gutter={15}>
                        <Col span={12}>
                            <SelectField label={i18n.t('entity.pack.property.media')}
                                         name="idMedia"
                                         placeholder={i18n.t('entity.pack.property.media')}
                                         options={media}
                                         onChange={value => props.setFieldValue('idMedia', value)}
                            />
                        </Col>
                    </Row>
                    <Portal.In target="sidebar-footer">
                        <Button type="primary"
                                disabled={props.isSubmitting || !props.isValid}
                                onClick={props.handleSubmit}>
                            {i18n.t('global.accept')}
                        </Button>
                    </Portal.In>
                </Form>
            )}
        </Formik>
    );
};

MediaSelectForm.defaultProps = {
    filters: {},
    onSubmit: () => {}
};

export default MediaSelectForm;
