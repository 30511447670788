import React from 'react';
import Portal from '../Portal/Portal';
import './site-header.scss';

const SiteHeader = props => {
    return (
        <div className="site-header">
            <Portal.Out className="site-header__breadcrumbs"
                        id="page-breadcrumbs"
            />
            <Portal.Out className="site-header__main"
                        id="page-header"
            />
        </div>
    );
};

export default SiteHeader;
