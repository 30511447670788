import React, { Fragment, useContext, useState } from 'react';
import { Space, Table } from 'antd';
import { I18nContext } from 'react-i18next';
import ConfirmButton from '../../../ConfirmButton/ConfirmButton';
import Button from '../../../Button/Button';
import { PlusOutlined } from '@ant-design/icons';
import Flex from '../../../Flex/Flex';
import Sidebar from '../../../Sidebar/Sidebar';
import LinkTypeSelectForm from '../LinkTypeForm/LinkTypeSelectForm';

const LinkTypeListMini = props => {
    const {i18n} = useContext(I18nContext);
    const [addSidebarIsVisible, setAddSidebarIsVisible] = useState(false);

    const getColumns = i18n => {
        return [
            {
                title: 'Id',
                dataIndex: 'idLinkType',
            },
            {
                title: i18n.t('entity.link_type.property.type'),
                dataIndex: 'type',
            },
            {
                title: '',
                dataIndex: 'actions',
                key: 'actions',
                align: 'right',
                render: (column, row) => (
                    <Space>
                        <ConfirmButton onConfirm={event => props.onRemove(row.idLinkType)} />
                    </Space>
                )
            }
        ]
    };

    return (
        <Fragment>
            <Table columns={getColumns(i18n)}
                   dataSource={props.data}
                   pagination={false}
                   rowKey={row => row.idLinkType}
                   footer={() => (
                       <Flex justifyContent="end">
                           <Button icon={<PlusOutlined />}
                                   onClick={event => setAddSidebarIsVisible(true)}>
                               {i18n.t('global.add')}
                           </Button>
                       </Flex>
                   )}
            />
            <Sidebar visible={addSidebarIsVisible}
                     title={i18n.t('entity.link_type.create_title')}
                     onClose={event => setAddSidebarIsVisible(false)}>
                <LinkTypeSelectForm onSubmit={values => {
                    setAddSidebarIsVisible(false);
                    props.onAdd(values.idLinkType);
                }} />
            </Sidebar>
        </Fragment>
    );
};

LinkTypeListMini.defaultProps = {
    onRemove: () => {},
    onAdd: () => {}
};

export default LinkTypeListMini;
