import SelectField from '../../../Form/Fields/SelectField';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../../context/AppProvider';
import debounce from '../../../../modules/debounce/debounce';

const PackSelectField = ({hideEmpty, ...props}) => {
    const appContext = useContext(AppContext);
    const [packs, setPacks] = useState([]);
    const [query, setQuery] = useState({});
    const [fetching, setFetching] = useState(false);

    const search = () => {
        setFetching(true);
        appContext.services.pack
            .query(query, hideEmpty)
            .then(packs => setPacks(packs || []))
            .catch(console.error)
            .finally(() => setFetching(false));
    };

    useEffect(search, [query]);

    return (
        <SelectField {...props}
                     options={packs}
                     labelInValue={true}
                     loading={fetching}
                     onSearch={debounce(setQuery, 1000)}
        />
    );
};

export default PackSelectField;
