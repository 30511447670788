import * as Yup from 'yup';

const getValidationSchema = i18n => Yup.object({
    name: Yup.string()
        .required(i18n.t('global.required')),
    domain: Yup.string()
        .required(i18n.t('global.required')),
    provider: Yup.mixed().test({
        test: provider => 'value' in provider,
        message: i18n.t('global.required')
    })
});

export default getValidationSchema;
