import React from 'react';
import { InputNumber, Space } from 'antd';
import FieldWrapper from './FieldWrapper';
import { useField } from 'formik';

const Step = props => (
    <InputNumber value={props.value}
                 onChange={props.onChange}
    />
);

const InputNumberRangeField = props => {
    const [field, meta] = useField(props);

    return (
        <FieldWrapper {...props} meta={meta}>
            <Space>
                <Step value={field.value[0]}
                      onChange={from => props.onChange([from, field.value[1]])}
                />
                <Step value={field.value[1]}
                      onChange={to => props.onChange([field.value[0], to])}
                />
            </Space>
        </FieldWrapper>
    );
};

export default InputNumberRangeField;
