import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AppContext } from '../../context/AppProvider';

const PrivateRoute = ({component: Component, ...rest}) => {
    const appContext = useContext(AppContext);

    return (
        <Route {...rest}
               render={props => (
                   appContext.services.base.loggedIn()
                       ? <Component {...props} user={appContext.services.base.decodeToken()}/>
                       : <Redirect to={{pathname: '/login', state: {from: props.location}}}/>
               )}
        />
    );
};

export default PrivateRoute;
