import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import PrivateRoute from '../components/HOC/PrivateRoute';
import LoginPage from '../pages/LoginPage/LoginPage';
import ProvidersListPage from '../pages/ProvidersListPage/ProvidersListPage';
import MediaListPage from '../pages/MediaListPage/MediaListPage';
import MediaSinglePage from '../pages/MediaSinglePage/MediaSinglePage';
import ProviderSinglePage from '../pages/ProviderSinglePage/ProviderSinglePage';
import ClientsListPage from '../pages/ClientsListPage/ClientsListPage';
import ClientSinglePage from '../pages/ClientSinglePage/ClientSinglePage';
import PacksListPage from '../pages/PacksListPage/PacksListPage';
import PackSinglePage from '../pages/PackSinglePage/PackSinglePage';
import TransactionsListPage from '../pages/TransactionsListPage/TransactionsListPage';
import TransactionSinglePage from '../pages/TransactionSinglePage/TransactionSinglePage';
import PriceTypesListPage from '../pages/PriceTypesListPage/PriceTypesListPage';
import LinkTypesListPage from '../pages/LinkTypesListPage/LinkTypesListPage';
import StatusListPage from '../pages/StatusListPage/StatusListPage';
import TopicsListPage from '../pages/TopicsListPage/TopicsListPage';
import UsersListPage from '../pages/UsersListPage/UsersListPage';
import MediaTypesListPage from '../pages/MediaTypesListPage/MediaTypesListPage';
import ClientsBudgetsListPage from "../pages/ClientsBudgetsListPage/ClientsBudgetsListPage";
import ClientsBudgetsSinglePage from "../pages/ClientsBudgetsSinglePage/ClientsBudgetsSinglePage";
export default () => (
    <Switch>
        <Route path="/login" component={LoginPage}/>
        <PrivateRoute path="/" exact component={() => <Redirect to="/media"/>}/>
        <PrivateRoute path="/media" exact component={MediaListPage}/>
        <PrivateRoute path="/media/:id" exact component={MediaSinglePage}/>
        <PrivateRoute path="/providers" exact component={ProvidersListPage}/>
        <PrivateRoute path="/providers/:id" exact component={ProviderSinglePage}/>
        <PrivateRoute path="/clients" exact component={ClientsListPage}/>
        <PrivateRoute path="/clients/:id" exact component={ClientSinglePage}/>
        <PrivateRoute path="/packs" exact component={PacksListPage}/>
        <PrivateRoute path="/packs/:id" exact component={PackSinglePage}/>
        <PrivateRoute path="/clients-budgets" exact component={ClientsBudgetsListPage}/>
        <PrivateRoute path="/clients-budgets/:id" exact component={ClientsBudgetsSinglePage}/>
        <PrivateRoute path="/transactions" exact component={TransactionsListPage}/>
        <PrivateRoute path="/transactions/:id" exact component={TransactionSinglePage}/>
        <PrivateRoute path="/price-types" exact component={PriceTypesListPage}/>
        <PrivateRoute path="/status" exact component={StatusListPage}/>
        <PrivateRoute path="/link-types" exact component={LinkTypesListPage}/>
        <PrivateRoute path="/topics" exact component={TopicsListPage}/>
        <PrivateRoute path="/users" exact component={UsersListPage}/>
        <PrivateRoute path="/media-types" exact component={MediaTypesListPage}/>
    </Switch>
);
