import React, { Fragment, useContext, useState } from 'react';
import { Space, Table } from 'antd';
import { I18nContext } from 'react-i18next';
import { AppContext } from '../../../../context/AppProvider';
import ConfirmButton from '../../../ConfirmButton/ConfirmButton';
import EditButton from '../../../EditButton/EditButton';
import Sidebar from '../../../Sidebar/Sidebar';
import { notify } from '../../../../modules/notify/notify';
import MediaTypeForm from '../MediaTypeForm/MediaTypeForm';

const MediaTypeList = props => {
    const {i18n} = useContext(I18nContext);
    const appContext = useContext(AppContext);
    const [editSidebarIsVisible, setEditSidebarIsVisible] = useState(false);
    const [currentMediaType, setCurrentMediaType] = useState({});

    const removeMediaType = idMediaType => {
        appContext.services.mediaType
            .remove(idMediaType)
            .then(response => {
                notify.success({description: i18n.t('global.item_updated')});
                props.onUpdate();
            });
    };

    const updateMediaType = (values, id) => {
        appContext.services.mediaType
            .update(id, values)
            .then(response => {
                if (!response.success) return;
                setEditSidebarIsVisible(false);
                notify.success({description: i18n.t('global.item_updated')});
                props.onUpdate();
            })
            .catch(console.error);
    };

    const getColumns = i18n => {
        return [
            {
                title: i18n.t('Id'),
                dataIndex: 'idMediaType',
                key: 'idMediaType'
            },
            {
                title: i18n.t('entity.media_type.property.name'),
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: '',
                dataIndex: 'actions',
                key: 'actions',
                align: 'right',
                render: (column, row) => (
                    <Space>
                        <ConfirmButton disabled={row.media?.length}
                                       onConfirm={event => removeMediaType(row.idMediaType)}
                        />
                        <EditButton onClick={event => {
                            setCurrentMediaType(row);
                            setEditSidebarIsVisible(true);
                        }} />
                    </Space>
                )
            },
        ];
    };

    return (
        <Fragment>
            <Table columns={getColumns(i18n)}
                   dataSource={props.data}
                   pagination={false}
                   rowKey={row => row.idMediaType}
            />
            <Sidebar visible={editSidebarIsVisible}
                     title={props.entityTitle}
                     onClose={event => setEditSidebarIsVisible(false)}>
                <MediaTypeForm mediaType={currentMediaType}
                               onSubmit={updateMediaType}
                />
            </Sidebar>
        </Fragment>
    );
};

MediaTypeList.defaultProps = {
    onUpdate: () => {}
};

export default MediaTypeList;
