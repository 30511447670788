import React, { Fragment, useContext, useState } from 'react';
import { Space, Switch, Table, Tooltip } from 'antd';
import { I18nContext } from 'react-i18next';
import { AppContext } from '../../../../context/AppProvider';
import ConfirmButton from '../../../ConfirmButton/ConfirmButton';
import EditButton from '../../../EditButton/EditButton';
import Sidebar from '../../../Sidebar/Sidebar';
import UserForm from '../UserForm/UserForm';
import { notify } from '../../../../modules/notify/notify';
import dayjs from 'dayjs';
import UserRoleTag from '../../../UserRoleTag/UserRoleTag';
import Button from '../../../Button/Button';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';

const UsersList = props => {
    const {i18n} = useContext(I18nContext);
    const appContext = useContext(AppContext);
    const [editSidebarIsVisible, setEditSidebarIsVisible] = useState(false);
    const [currentUser, setCurrentUser] = useState({});

    const removeUser = idUser => {
        appContext.services.user
            .remove(idUser)
            .then(response => {
                notify.success({description: i18n.t('global.item_updated')});
                props.onUpdate();
            });
    };

    const updateUser = (values, id) => {
        appContext.services.user
            .update(id, values)
            .then(response => {
                if (!response.success) return;
                setEditSidebarIsVisible(false);
                notify.success({description: i18n.t('global.item_updated')});
                props.onUpdate();
            })
            .catch(console.error);
    };

    const updateActive = (idUser, active) => {
        appContext.services.user
            .toggleActive(idUser, active)
            .then(response => {
                notify.success({description: i18n.t('global.item_updated')});
                props.onUpdate();
            });
    };

    const promote = (idUser) => {
        appContext.services.user
            .promote(idUser)
            .then(response => {
                notify.success({description: i18n.t('global.item_updated')});
                props.onUpdate();
            });
    };

    const demote = (idUser) => {
        appContext.services.user
            .demote(idUser)
            .then(response => {
                notify.success({description: i18n.t('global.item_updated')});
                props.onUpdate();
            });
    };

    const renderRoleButton = user => {
        return !!user?.roles?.length && user.roles.includes('ROLE_ADMIN')
            ? <Tooltip title={i18n.t('Degradar a USER')}>
                <div>
                    <Button shape="circle"
                            onClick={event => demote(user.idUser)}>
                        <ArrowDownOutlined />
                    </Button>
                </div>
            </Tooltip>
            : <Tooltip title={i18n.t('Promover a ADMIN')}>
                <div>
                    <Button shape="circle"
                            onClick={event => promote(user.idUser)}>
                        <ArrowUpOutlined />
                    </Button>
                </div>
            </Tooltip>;
    };

    const getColumns = i18n => {
        return [
            {
                title: i18n.t('Id'),
                dataIndex: 'idUser',
                key: 'idUser'
            },
            {
                title: i18n.t('entity.user.property.email'),
                dataIndex: 'email',
                key: 'email'
            },
            {
                title: i18n.t('entity.user.property.role'),
                dataIndex: 'roles',
                key: 'roles',
                render: roles => !!roles?.length && roles.map(role => <UserRoleTag key={role} role={role} />)
            },
            {
                title: i18n.t('global.registration_date'),
                dataIndex: 'tmsCreated',
                key: 'tmsCreated',
                render: date => dayjs.unix(date).format('DD/MM/YYYY')
            },
            {
                title: i18n.t('global.active'),
                dataIndex: 'active',
                key: 'active',
                render: (active, row) => (
                    <Switch defaultChecked={active}
                            disabled={props.readOnly}
                            onChange={active => updateActive(row.idUser, active)}
                    />
                )
            },
            {
                title: '',
                dataIndex: 'actions',
                key: 'actions',
                align: 'right',
                render: (column, row) => (
                    <Space>
                        <ConfirmButton onConfirm={event => removeUser(row.idUser)} />
                        {renderRoleButton(row)}
                        <EditButton onClick={event => {
                            setCurrentUser(row);
                            setEditSidebarIsVisible(true);
                        }} />
                    </Space>
                )
            },
        ];
    };

    return (
        <Fragment>
            <Table columns={getColumns(i18n)}
                   dataSource={props.data}
                   pagination={false}
                   rowKey={row => row.idUser}
            />
            <Sidebar visible={editSidebarIsVisible}
                     title={props.entityTitle}
                     onClose={event => setEditSidebarIsVisible(false)}>
                <UserForm user={currentUser}
                          onSubmit={updateUser}
                />
            </Sidebar>
        </Fragment>
    );
};

UsersList.defaultProps = {
    onUpdate: () => {}
};

export default UsersList;
