import React, { Fragment, useContext, useEffect, useState } from 'react';
import { withLayout } from '../../components/HOC/WithLayout';
import Portal from '../../components/Portal/Portal';
import { Button, PageHeader } from 'antd';
import { I18nContext } from 'react-i18next';
import { AppContext } from '../../context/AppProvider';
import Sidebar from '../../components/Sidebar/Sidebar';
import { PlusOutlined } from '@ant-design/icons';
import TopicForm from '../../components/CRUD/topic/TopicForm/TopicForm';
import Skeleton from '../../components/Skeleton/Skeleton';
import { notify } from '../../modules/notify/notify';
import TopicsList from '../../components/CRUD/topic/TopicsList/TopicsList';
import './topics-list-page.scss';

const TopicsListPage = props => {
    const {i18n} = useContext(I18nContext);
    const appContext = useContext(AppContext);
    const [createSidebarIsVisible, setCreateSidebarIsVisible] = useState(false);
    const [topic, setTopic] = useState([]);
    const [loading, setLoading] = useState(true);

    const createStatus = values => {
        appContext.services.topic
            .create(values)
            .then(response => {
                if (!response.success) return;
                notify.success({description: i18n.t('global.item_updated')});
                setCreateSidebarIsVisible(false);
                findList();
            })
            .catch(console.error);
    };

    const findList = () => {
        setLoading(true);
        appContext.services.topic
            .findList(false)
            .then(topic => setTopic(topic))
            .catch(console.error)
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        findList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
            <div className="topic-list-page">
                <Skeleton loading={loading}>
                    <TopicsList data={topic}
                                onUpdate={findList}
                    />
                </Skeleton>
            </div>

            <Portal.In target="page-header">
                <PageHeader title={i18n.t('page.topics.title')}
                            extra={[
                                <Button key="1"
                                        type="primary"
                                        icon={<PlusOutlined />}
                                        onClick={event => setCreateSidebarIsVisible(true)}>
                                    {i18n.t('entity.topic.title')}
                                </Button>
                            ]}
                />
            </Portal.In>

            <Sidebar visible={createSidebarIsVisible}
                     title={i18n.t('entity.topic.create_title')}
                     onClose={event => setCreateSidebarIsVisible(false)}>
                <TopicForm onSubmit={createStatus} />
            </Sidebar>
        </Fragment>
    );
};

export default withLayout(TopicsListPage);
