import React from 'react';
import { Tag } from 'antd';

const StatusTag = ({status}) => {
    return (
        <Tag color={status.color ? status.color : 'geekblue'}>
            {status.description.toUpperCase()}
        </Tag>
    );
};

export default StatusTag;
