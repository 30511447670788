import React, { Fragment, useContext, useState } from 'react';
import { Space, Table } from 'antd';
import { I18nContext } from 'react-i18next';
import ConfirmButton from '../../../ConfirmButton/ConfirmButton';
import Button from '../../../Button/Button';
import { PlusOutlined } from '@ant-design/icons';
import Flex from '../../../Flex/Flex';
import Sidebar from '../../../Sidebar/Sidebar';
import MediaSelectForm from '../MediaForm/MediaSelectForm';

const MediaListMini = props => {
    const {i18n} = useContext(I18nContext);
    const [addSidebarIsVisible, setAddSidebarIsVisible] = useState(false);

    const getColumns = i18n => {
        return [
            {
                title: 'Id',
                dataIndex: 'idMedia',
            },
            {
                title: i18n.t('entity.media.property.name'),
                dataIndex: 'name',
            },
            {
                title: i18n.t('entity.media.property.domain'),
                dataIndex: 'domain',
            },
            {
                title: '',
                dataIndex: 'actions',
                key: 'actions',
                align: 'right',
                render: (column, row) => (
                    <Space>
                        <ConfirmButton onConfirm={event => props.onRemove(row.idMedia)} />
                    </Space>
                )
            }
        ]
    };

    return (
        <Fragment>
            <Table columns={getColumns(i18n)}
                   dataSource={props.data}
                   pagination={false}
                   rowKey={row => row.idMedia}
                   footer={() => (
                       <Flex justifyContent="end">
                           <Button icon={<PlusOutlined />}
                                   onClick={event => setAddSidebarIsVisible(true)}>
                               {i18n.t('global.add')}
                           </Button>
                       </Flex>
                   )}
            />
            <Sidebar visible={addSidebarIsVisible}
                     title={i18n.t('entity.media.add_title')}
                     onClose={event => setAddSidebarIsVisible(false)}>
                <MediaSelectForm filters={{provider: props.idProvider ? {value: props.idProvider} : null}}
                                 onSubmit={values => {
                                     setAddSidebarIsVisible(false);
                                     props.onAdd(values.idMedia);
                                 }}
                />
            </Sidebar>
        </Fragment>
    );
};

MediaListMini.defaultProps = {
    onRemove: () => {},
    onAdd: () => {}
};

export default MediaListMini;
