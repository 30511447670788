import React from 'react';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import './breadcrumbs.scss';

const Breadcrumbs = ({breadcrumbs = []}) => {
    return (
        <Breadcrumb className="breadcrumbs">
            {breadcrumbs.map(breadcrumb => (
                <Breadcrumb.Item key={breadcrumb.path}>
                    <Link to={breadcrumb.path}>{breadcrumb.name}</Link>
                </Breadcrumb.Item>
            ))}
        </Breadcrumb>
    );
};

export default Breadcrumbs;
