import React, { useContext } from 'react';
import { Space, Switch, Table } from 'antd';
import { I18nContext } from 'react-i18next';
import { AppContext } from '../../../../context/AppProvider';
import ConfirmButton from '../../../ConfirmButton/ConfirmButton';
import { FilePdfOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { notify } from '../../../../modules/notify/notify';
import getAgreementFilename from '../../../../modules/get-agreement-filename/get-agreement-filename';

const AgreementsList = props => {
    const {i18n} = useContext(I18nContext);
    const appContext = useContext(AppContext);

    const updateActive = (idAgreement, active) => {
        appContext.services.agreement
            .toggleActive(idAgreement, active)
            .then(response => {
                notify.success({description: i18n.t('global.item_updated')});
                props.onUpdate();
            });
    };

    const removeAgreement = idAgreement => {
        appContext.services.agreement
            .remove(idAgreement)
            .then(response => {
                notify.success({description: i18n.t('global.item_updated')});
                props.onUpdate();
            });
    };

    const getColumns = i18n => {
        return [
            {
                title: i18n.t('Created'),
                dataIndex: 'tmsCreated',
                key: 'tmsCreated',
                render: date => dayjs.unix(date).format('DD/MM/YYYY HH:mm:ss')
            },
            {
                title: i18n.t('Descarga'),
                dataIndex: 'pdfFile',
                key: 'pdfFile',
                render: value => {
                    const filename = getAgreementFilename(value, i18n);

                    return (
                        <a href={`${encodeURI(value)}`} target="_blank" rel="noopener noreferrer">
                            <FilePdfOutlined /> {filename}
                        </a>
                    );
                }
            },
            {
                title: i18n.t('global.active'),
                dataIndex: 'active',
                key: 'active',
                render: (active, row) => (
                    <Switch defaultChecked={active}
                            onChange={active => updateActive(row.idAgreement, active)}
                    />
                )
            },
            {
                title: '',
                dataIndex: 'actions',
                key: 'actions',
                align: 'right',
                render: (column, row) => (
                    <Space>
                        <ConfirmButton onConfirm={event => removeAgreement(row.idAgreement)} />
                    </Space>
                )
            },
        ];
    };

    return (
        <Table columns={getColumns(i18n)}
               dataSource={props.data}
               pagination={false}
               rowKey={row => row.idAgreement}
        />
    );
};

AgreementsList.defaultProps = {
    onUpdate: () => {}
};

export default AgreementsList;
