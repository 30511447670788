import React, { useContext } from 'react';
import { Popconfirm } from 'antd';
import { I18nContext } from 'react-i18next';
import { DeleteOutlined } from '@ant-design/icons';
import Button from '../Button/Button';

const ConfirmButton = props => {
    const {i18n} = useContext(I18nContext);

    return (
        <Popconfirm title={i18n.t('component.confirm_button.message')}
                    placement="topRight"
                    onConfirm={props.onConfirm}
                    onCancel={props.onCancel}
                    okText={i18n.t('global.yes')}
                    cancelText={i18n.t('global.no')}
        >
            <div>
                <Button danger
                        disabled={props.disabled}
                        shape="circle"
                        icon={<DeleteOutlined/>}
                />
            </div>
        </Popconfirm>
    );
};

ConfirmButton.defaultProps = {
    onConfirm: () => {},
    onCancel: () => {}
};

export default ConfirmButton;
