import React, { useContext } from 'react';
import { I18nContext } from 'react-i18next';
import { Form, Formik } from 'formik';
import getValidationSchema from './get-validation-schema';
import InputField from '../../../Form/Fields/InputField';
import SelectField from '../../../Form/Fields/SelectField';
import Button from '../../../Button/Button';
import Portal from '../../../Portal/Portal';
import { Col, Row } from 'antd';

const PriceForm = ({price, priceTypes, ...props}) => {
    const {i18n} = useContext(I18nContext);

    const extractPriceId = price => {
        return 'idPrice' in price ? price.idPrice : null;
    };

    return (
        <Formik validationSchema={getValidationSchema(i18n)}
                initialValues={{
                    mediaPrice: price.mediaPrice || 0,
                    managedPrice: price.managedPrice || 0,
                    pvpPrice: price.pvpPrice || 0,
                    idPriceType: price.type?.idPriceType || ''
                }}
                enableReinitialize={true} onSubmit={(values, {setSubmitting}) => {
                    props.onSubmit(values, extractPriceId(price));
                    setSubmitting(false);
        }}>
            {props => (
                <Form>
                    <Row gutter={15}>
                        <Col span={12}>
                            <InputField label={i18n.t('entity.price.property.media_price')}
                                        name="mediaPrice"
                                        type="number"
                                        placeholder={i18n.t('entity.price.property.media_price')}
                            />
                        </Col>
                        <Col span={12}>
                            <InputField label={i18n.t('entity.price.property.managed_price')}
                                        name="managedPrice"
                                        type="number"
                                        placeholder={i18n.t('entity.price.property.managed_price')}
                            />
                        </Col>
                        <Col span={12}>
                            <InputField label={i18n.t('entity.price.property.pvp_price')}
                                        name="pvpPrice"
                                        type="number"
                                        placeholder={i18n.t('entity.price.property.pvp_price')}
                            />
                        </Col>
                        <Col span={12}>
                            <SelectField label={i18n.t('entity.price_type.title')}
                                         name="idPriceType"
                                         placeholder={i18n.t('entity.price_type.title')}
                                         options={priceTypes}
                                         onChange={value => props.setFieldValue('idPriceType', value)}
                            />
                        </Col>
                    </Row>
                    <Portal.In target="sidebar-footer">
                        <Button type="primary"
                                disabled={props.isSubmitting || !props.isValid}
                                onClick={props.handleSubmit}>
                            {i18n.t('global.accept')}
                        </Button>
                    </Portal.In>
                </Form>
            )}
        </Formik>
    );
};

PriceForm.defaultProps = {
    price: {},
    priceTypes: [],
    onSubmit: () => {}
};

export default PriceForm;
