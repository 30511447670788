import BaseService from './BaseService';

export default class PriceService extends BaseService {
    entityEndpoint = 'price';

    findList() {
        return super.findList.call(this, this.entityEndpoint);
    }

    findAll(page = 1) {
        return super.findAll.call(this, this.entityEndpoint, page);
    }

    search(filters) {
        const endpoint = `${this.entityEndpoint}/search`;

        return this
            .fetch(`${this.API_URL}${endpoint}`, {method: 'GET'}, {...filters})
            .then(response => response || []);
    }

    findById(idPrice) {
        return super.findById.call(this, this.entityEndpoint, idPrice);
    }

    findByMedia(idMedia) {
        const endpoint = `${this.entityEndpoint}/by-media/${idMedia}`;

        return this
            .fetch(`${this.API_URL}${endpoint}`, {method: 'GET'})
            .then(response => response);
    }

    remove(idPrice) {
        return super.remove.call(this, this.entityEndpoint, idPrice);
    }

    toggleActive(idPrice, active) {
        return super.toggleActive.call(this, this.entityEndpoint, idPrice, active);
    }

    create(values) {
        return super.create.call(this, this.entityEndpoint, values);
    }

    update(idPrice, values) {
        return super.update.call(this, this.entityEndpoint, idPrice, values);
    }
}
