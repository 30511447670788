import React, { Fragment, useContext, useState } from 'react';
import { Space, Table } from 'antd';
import { I18nContext } from 'react-i18next';
import { AppContext } from '../../../../context/AppProvider';
import ConfirmButton from '../../../ConfirmButton/ConfirmButton';
import EditButton from '../../../EditButton/EditButton';
import Sidebar from '../../../Sidebar/Sidebar';
import LinkTypeForm from '../LinkTypeForm/LinkTypeForm';
import { notify } from '../../../../modules/notify/notify';
import LinkTypeTag from '../../../LinkTypeTag/LinkTypeTag';

const LinkTypeList = props => {
    const {i18n} = useContext(I18nContext);
    const appContext = useContext(AppContext);
    const [editSidebarIsVisible, setEditSidebarIsVisible] = useState(false);
    const [currentLinkType, setCurrentLinkType] = useState({});

    const removeLinkType = idLinkType => {
        appContext.services.linkType
            .remove(idLinkType)
            .then(response => {
                notify.success({description: i18n.t('global.item_updated')});
                props.onUpdate();
            });
    };

    const updateLinkType = (values, id) => {
        appContext.services.linkType
            .update(id, values)
            .then(response => {
                if (!response.success) return;
                setEditSidebarIsVisible(false);
                notify.success({description: i18n.t('global.item_updated')});
                props.onUpdate();
            })
            .catch(console.error);
    };

    const getColumns = i18n => {
        return [
            {
                title: i18n.t('Id'),
                dataIndex: 'idLinkType',
                key: 'idLinkType'
            },
            {
                title: i18n.t('entity.link_type.property.type'),
                dataIndex: 'type',
                key: 'type',
                render: (column, row) => <LinkTypeTag linkType={row} />
            },
            {
                title: '',
                dataIndex: 'actions',
                key: 'actions',
                align: 'right',
                render: (column, row) => (
                    <Space>
                        <ConfirmButton onConfirm={event => removeLinkType(row.idLinkType)} />
                        <EditButton onClick={event => {
                            setCurrentLinkType(row);
                            setEditSidebarIsVisible(true);
                        }} />
                    </Space>
                )
            },
        ];
    };

    return (
        <Fragment>
            <Table columns={getColumns(i18n)}
                   dataSource={props.data}
                   pagination={false}
                   rowKey={row => row.idLinkType}
            />
            <Sidebar visible={editSidebarIsVisible}
                     title={props.entityTitle}
                     onClose={event => setEditSidebarIsVisible(false)}>
                <LinkTypeForm linkType={currentLinkType}
                              onSubmit={updateLinkType}
                />
            </Sidebar>
        </Fragment>
    );
};

LinkTypeList.defaultProps = {
    onUpdate: () => {}
};

export default LinkTypeList;
