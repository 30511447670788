import BaseService from './BaseService';
import convertToSelectOptions from '../modules/convert-to-select-options/convert-to-select-options';

export default class ProviderService extends BaseService {
    entityEndpoint = 'provider';

    findList(active = true) {
        const endpoint = `${this.entityEndpoint}/list`;

        return this
            .fetch(`${this.API_URL}${endpoint}`, {method: 'GET'}, {active})
            .then(response => convertToSelectOptions(response, 'idProvider', 'name'));
    }

    findAll(page = 1) {
        return super.findAll.call(this, this.entityEndpoint, page);
    }

    search(page = 1, filters = {}, list = undefined) {
        const adaptedFilters = {
            ...filters,
            creationRange: filters?.creationRange ? filters.creationRange.join(',') : undefined,
            packs: filters?.packs?.length ? filters.packs.map(item => item.value).join(',') : undefined,
            media: filters?.media?.length ? filters.media.map(item => item.value).join(',') : undefined
        };
        return super.search.call(this, this.entityEndpoint, page, adaptedFilters, list);
    }

    query(query) {
        return super.query.call(this, this.entityEndpoint, query)
            .then(response => response ? convertToSelectOptions(response, 'idProvider', 'name') : []);
    }

    findById(idProvider) {
        return super.findById.call(this, this.entityEndpoint, idProvider);
    }

    remove(idProvider) {
        return super.remove.call(this, this.entityEndpoint, idProvider);
    }

    toggleActive(idProvider, active) {
        return super.toggleActive.call(this, this.entityEndpoint, idProvider, active);
    }

    create(values) {
        return super.create.call(this, this.entityEndpoint, values);
    }

    update(idProvider, values) {
        return super.update.call(this, this.entityEndpoint, idProvider, values);
    }
}
