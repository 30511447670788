const getAgreementFilename = (fullURL, i18n) => {
    const urlParts = fullURL.split('/');
    const lastPart = urlParts?.length
        ? urlParts[urlParts.length - 1]
        : '';

    const regexp = /(\w*_)(.*)/g;
    const matches = regexp.exec(lastPart);

    return matches && lastPart ? matches[2] : i18n.t('global.download');
};

export default getAgreementFilename;
