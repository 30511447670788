import React, { Fragment, useContext, useEffect, useState } from 'react';
import { withLayout } from '../../components/HOC/WithLayout';
import Portal from '../../components/Portal/Portal';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { I18nContext } from 'react-i18next';
import { AppContext } from '../../context/AppProvider';
import { useParams } from 'react-router-dom';
import { Col, PageHeader, Row, Space, Switch, Typography } from 'antd';
import MediaForm from '../../components/CRUD/media/MediaForm/MediaForm';
import PricesList from '../../components/CRUD/price/PricesList/PricesList';
import './media-single-page.scss';
import Skeleton from '../../components/Skeleton/Skeleton';
import FieldWrapper from '../../components/Form/Fields/FieldWrapper';
import { notify } from '../../modules/notify/notify';
import TransactionsList from '../../components/CRUD/transaction/TransactionsList/TransactionsList';
import PacksList from '../../components/CRUD/pack/PacksList/PacksList';
import TopicsListMini from '../../components/CRUD/topic/TopicsList/TopicsListMini';
import LinkTypeListMini from '../../components/CRUD/linkType/LinkTypeList/LinkTypeListMini';
import CreateTransactionButton from '../../components/CRUD/transaction/CreateTransactionButton/CreateTransactionButton';

const MediaSinglePage = props => {
    const {i18n} = useContext(I18nContext);
    const appContext = useContext(AppContext);
    const [media, setMedia] = useState({});
    const [priceTypes, setPriceTypes] = useState([]);
    const { id: idMedia } = useParams();
    const [loading, setLoading] = useState(true);

    const findById = () => {
        setLoading(true);
        appContext.services.media
            .findById(idMedia)
            .then(media => setMedia(media))
            .catch(console.error)
            .finally(() => setLoading(false));
    };

    const updateMedia = values => {
        appContext.services.media
            .update(idMedia, values)
            .then(response => {
                if (!response.success) return;
                notify.success({description: i18n.t('global.item_updated')});
                findById();
            })
            .catch(error => {
                console.error(error);
                notify.error({message: `${error.name}: ${error.message}`, description: i18n.t('entity.pack.error.unique_constraint_violation_exception')});
            });
    };

    const findPriceTypes = () => {
        appContext.services.priceType
            .findList()
            .then(priceTypes => setPriceTypes(priceTypes));
    };

    const createPrice = (values) => {
        appContext.services.media
            .updatePricing(idMedia, {...values})
            .then(response => {
                if (!response.success) return;
                notify.success({description: i18n.t('global.item_updated')});
                findById();
            })
            .catch(error => {
                notify.error({description: i18n.t('global.request_error')});
                console.error(error);
            });
    };

    const updateActive = (idMedia, active) => {
        appContext.services.media
            .toggleActive(idMedia, active)
            .then(response => {
                notify.success({description: i18n.t('global.item_updated')});
                findById();
            });
    };

    const addTopic = idTopic => {
        appContext.services.media
            .addTopic(idMedia, idTopic)
            .then(response => {
                if (!response.success) return;
                notify.success({description: i18n.t('global.item_updated')});
                findById();
            })
            .catch(console.error);
    };

    const removeTopic = idTopic => {
        appContext.services.media
            .removeTopic(idMedia, idTopic)
            .then(response => {
                if (!response.success) return;
                notify.success({description: i18n.t('global.item_updated')});
                findById();
            })
            .catch(console.error);
    };

    const addLinkType = idLinkType => {
        appContext.services.media
            .addLinkType(idMedia, idLinkType)
            .then(response => {
                if (!response.success) return;
                notify.success({description: i18n.t('global.item_updated')});
                findById();
            })
            .catch(console.error);
    };

    const removeLinkType = idTopic => {
        appContext.services.media
            .removeLinkType(idMedia, idTopic)
            .then(response => {
                if (!response.success) return;
                notify.success({description: i18n.t('global.item_updated')});
                findById();
            })
            .catch(console.error);
    };

    useEffect(() => {
        findById();
        findPriceTypes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
            <div className="media-single-page">
                <Row gutter={[15, 30]}>
                    <Col xs={24}>
                        <Typography.Title level={3}>{i18n.t('global.basic_info')}</Typography.Title>
                        <Skeleton loading={loading}>
                            <MediaForm media={media}
                                       actionsPortalTarget="form-actions"
                                       onSubmit={updateMedia}
                            />
                        </Skeleton>
                    </Col>
                    <Col xs={24}>
                        <Typography.Title level={3}>{i18n.t('entity.link_type.title', {count: media.linkType?.length})}</Typography.Title>
                        <Skeleton loading={loading}>
                            <LinkTypeListMini data={media.linkType}
                                              onAdd={addLinkType}
                                              onRemove={removeLinkType}
                            />
                        </Skeleton>
                    </Col>
                    <Col xs={24}>
                        <Typography.Title level={3}>{i18n.t('entity.topic.title', {count: media.topics?.length})}</Typography.Title>
                        <Skeleton loading={loading}>
                            <TopicsListMini data={media.topics}
                                            onAdd={addTopic}
                                            onRemove={removeTopic}
                            />
                        </Skeleton>
                    </Col>
                    <Col xs={24}>
                        <Typography.Title level={3}>{i18n.t('global.prices')}</Typography.Title>
                    </Col>
                    <Col xs={24}>
                        <Skeleton loading={loading}>
                            <PricesList priceTypes={priceTypes}
                                        data={media.price}
                                        onRemove={findById}
                                        onCreate={values => createPrice(values)}
                                        onEdit={findById}
                            />
                        </Skeleton>
                    </Col>
                    <Col xs={24}>
                        <Typography.Title level={3}>{i18n.t('entity.transaction.title', {count: media.transactions?.length})}</Typography.Title>
                        <Skeleton loading={loading}>
                            <TransactionsList data={media.transactions} readOnly />
                        </Skeleton>
                    </Col>
                    <Col xs={24}>
                        <Typography.Title level={3}>{i18n.t('entity.pack.title', {count: media.packs?.length})}</Typography.Title>
                        <Skeleton loading={loading}>
                            <PacksList data={media.packs} readOnly />
                        </Skeleton>
                    </Col>
                </Row>
            </div>

            <Portal.In target="page-header">
                <PageHeader title={media.domain}
                            extra={[
                                <Space key="1">
                                    <CreateTransactionButton media={media} />
                                    <FieldWrapper label={i18n.t('global.active')}>
                                        <Switch checked={media.active}
                                                loading={loading}
                                                onChange={active => updateActive(media.idMedia, active)}
                                        />
                                    </FieldWrapper>
                                </Space>
                            ]}
                />
            </Portal.In>

            <Portal.In target="page-breadcrumbs">
                <Breadcrumbs breadcrumbs={[
                    {path: '/media', name: i18n.t('page.media.menu')},
                    {path: `/media/${idMedia}`, name: media.domain}
                ]} />
            </Portal.In>
        </Fragment>
    );
};

export default withLayout(MediaSinglePage);
