import React, { Fragment, useContext, useEffect, useState } from 'react';
import { withLayout } from '../../components/HOC/WithLayout';
import Portal from '../../components/Portal/Portal';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { I18nContext } from 'react-i18next';
import { AppContext } from '../../context/AppProvider';
import { useParams } from 'react-router-dom';
import { Col, PageHeader, Row, Switch, Typography, Upload } from 'antd';
import ProviderForm from '../../components/CRUD/provider/ProviderForm/ProviderForm';
import { InboxOutlined } from '@ant-design/icons';
import Button from '../../components/Button/Button';
import AgreementsList from '../../components/CRUD/agreement/AgreementsList/AgreementsList';
import Flex from '../../components/Flex/Flex';
import './provider-single-page.scss';
import Skeleton from '../../components/Skeleton/Skeleton';
import FieldWrapper from '../../components/Form/Fields/FieldWrapper';
import { notify } from '../../modules/notify/notify';
import PacksList from '../../components/CRUD/pack/PacksList/PacksList';
import MediaList from '../../components/CRUD/media/MediaList/MediaList';

const ProviderSinglePage = props => {
    const {i18n} = useContext(I18nContext);
    const appContext = useContext(AppContext);
    const [provider, setProvider] = useState({});
    const [fileList, setFileList] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [loading, setLoading] = useState(true);
    const { id: idProvider } = useParams();

    const findById = () => {
        setLoading(true);
        appContext.services.provider
            .findById(idProvider)
            .then(provider => setProvider(provider))
            .catch(console.error)
            .finally(() => setLoading(false));
    };

    const updateProvider = values => {
        appContext.services.provider
            .update(idProvider, values)
            .then(response => {
                if (!response.success) return;
                notify.success({description: i18n.t('global.item_updated')});
                findById();
            })
            .catch(error => {
                notify.error({description: i18n.t('global.request_error')});
                console.error(error);
            });
    };

    const uploadAgreement = fileList => {
        setUploading( true);
        appContext.services.agreement
            .create(idProvider, fileList[0])
            .then(response => {
                if (!response.success) return;
                notify.success({description: i18n.t('global.item_updated')});
                findById();
            })
            .catch(console.error)
            .finally(() => {
                setUploading( false);
                setFileList( []);
            });
    };

    const updateActive = (idProvider, active) => {
        appContext.services.provider
            .toggleActive(idProvider, active)
            .then(response => {
                notify.success({description: i18n.t('global.item_updated')});
                findById();
            });
    };

    useEffect(findById, []);

    const uploaderProps = {
        onRemove: file => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: file => {
            setFileList([...fileList, file]);

            return false;
        },
        fileList,
        accept: '.pdf'
    };

    return (
        <Fragment>
            <div className="provider-single-page">
                <Row gutter={[15, 60]}>
                    <Col xs={24}>
                        <Typography.Title level={3}>{i18n.t('global.basic_info')}</Typography.Title>
                        <Skeleton loading={loading}>
                            <ProviderForm provider={provider}
                                          actionsPortalTarget="form-actions"
                                          onSubmit={updateProvider}
                            />
                        </Skeleton>
                    </Col>
                    <Col xs={24}>
                        <Row gutter={[15]}>
                            <Col xs={24}>
                                <Typography.Title level={3}>{i18n.t('entity.agreement.title', {count: 0})}</Typography.Title>
                            </Col>
                            <Col xs={24} md={12}>
                                <Skeleton loading={loading}>
                                    <AgreementsList data={provider.agreements}
                                                    onUpdate={findById}
                                    />
                                </Skeleton>
                            </Col>
                            <Col xs={24} md={12}>
                                <div>
                                    <Upload.Dragger {...uploaderProps}>
                                        <p className="ant-upload-drag-icon">
                                            <InboxOutlined />
                                        </p>
                                        <p className="ant-upload-text">{i18n.t('component.uploader.pdf.title')}</p>
                                        <p className="ant-upload-hint">{i18n.t('component.uploader.pdf.subtitle')}</p>
                                    </Upload.Dragger>
                                    <Flex justifyContent="end">
                                        <Button type="primary"
                                                onClick={event => uploadAgreement(fileList)}
                                                disabled={fileList.length === 0}
                                                loading={uploading}
                                                style={{marginTop: 15}}>
                                            {uploading
                                                ? i18n.t('component.uploader.button.uploading')
                                                : i18n.t('component.uploader.button.start_upload')
                                            }
                                        </Button>
                                    </Flex>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24}>
                        <Typography.Title level={3}>{i18n.t('entity.pack.title', {count: provider.packs?.length})}</Typography.Title>
                        <Skeleton loading={loading}>
                            <PacksList data={provider.packs} readOnly />
                        </Skeleton>
                    </Col>
                    <Col xs={24}>
                        <Typography.Title level={3}>{i18n.t('entity.media.title', {count: provider.media?.length})}</Typography.Title>
                        <Skeleton loading={loading}>
                            <MediaList useDefaultColumns={true} data={provider.media} readOnly />
                        </Skeleton>
                    </Col>
                </Row>
            </div>

            <Portal.In target="page-header">
                <PageHeader title={provider.name}
                            extra={[
                                <FieldWrapper key="1"
                                              label={i18n.t('global.active')}>
                                    <Skeleton loading={loading}
                                              paragraph={{rows: 1, width: 50}}
                                              title={false}>
                                        <Switch checked={provider.active}
                                                onChange={active => updateActive(provider.idProvider, active)}
                                        />
                                    </Skeleton>
                                </FieldWrapper>
                            ]}/>
            </Portal.In>

            <Portal.In target="page-breadcrumbs">
                <Breadcrumbs breadcrumbs={[
                    {path: '/providers', name: i18n.t('page.providers.menu')},
                    {path: `/providers/${idProvider}`, name: provider.name}
                ]} />
            </Portal.In>
        </Fragment>
    );
};

export default withLayout(ProviderSinglePage);
