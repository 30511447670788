import React, { useContext } from 'react';
import { Pagination as AntDesignPagination } from 'antd';
import './pagination.scss';
import { I18nContext } from 'react-i18next';

const Pagination = props => {
    const {i18n} = useContext(I18nContext);
    const totalItems = props.totalItems || 0;

    return (
        <div className="pagination">
            <div className="pagination__meta">
                Total {totalItems} {i18n.t('global.item', {count: totalItems})}
            </div>
            <div className="pagination__actions">
                <AntDesignPagination current={props.currentPage}
                                     total={totalItems}
                                     defaultPageSize={props.itemsPerPage}
                                     onChange={props.onChange}
                />
            </div>
        </div>
    );
};

Pagination.defaultProps = {
    onChange: () => {}
};

export default Pagination;
