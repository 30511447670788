import React, { useContext } from 'react';
import { Form, Formik } from 'formik';
import InputField from '../../../Form/Fields/InputField';
import Button from '../../../Button/Button';
import getValidationSchema from './get-validation-schema';
import Portal from '../../../Portal/Portal';
import { I18nContext } from 'react-i18next';
import { Col, Row } from 'antd';
import UnsavedPrompt from '../../../UnsavedPrompt/UnsavedPrompt';
import dayjs from "dayjs";
import ClientSelectField from "../../client/ClientSelectField/ClientSelectField";
import DatePickerField from "../../../Form/Fields/DatePickerField";

const ClientBudgetForm = ({clientBudget, actionsPortalTarget, ...props}) => {
    const {i18n} = useContext(I18nContext);

    console.log(clientBudget?.budget_date);
    //const date = (clientBudget?.budget_date) ? clientBudget.date : dayjs().unix();
    //console.log('date es:', date);
    return (
        <Formik validationSchema={getValidationSchema(i18n)}
                enableReinitialize={true}
                initialValues={{
                    client: clientBudget?.client?.id || null,
                    monthly_budget: clientBudget?.monthly_budget || 0,
                    budget_date: clientBudget?.budget_date || dayjs().unix()
                }}
                onSubmit={(values, {setSubmitting}) => {
                    props.onSubmit(values);
                    setSubmitting(false);
                }}>
            {props => (
                <Form>
                    <UnsavedPrompt when={props.dirty} />
                    <Row gutter={15}>
                        <Col span={24}>
                            <ClientSelectField label={i18n.t('entity.clientBudget.property.client')}
                                               name="client"
                                               allowClear={false}
                                               placeholder={i18n.t('entity.clientBudget.property.client')}
                                               onChange={client => {
                                                   props.setFieldValue('client', client.value)
                                               }}
                            />
                        </Col>
                    </Row>
                    <Row gutter={15}>
                        <Col span={12}>
                            <InputField label={i18n.t('entity.clientBudget.property.monthly_budget')}
                                        name="monthly_budget"
                                        type="number"
                                        placeholder={i18n.t('entity.clientBudget.property.monthly_budget')}
                            />
                        </Col>
                        <Col span={12}>
                            <DatePickerField label={i18n.t('entity.clientBudget.property.budget_date')}
                                             name="budget_date"
                                             placeholder={i18n.t('entity.clientBudget.property.budget_date')}
                                             allowEmpty={false}
                                             allowClear={false}
                                             onChange={value => {
                                                 props.setFieldValue('budget_date', value.unix());
                                             }}
                            />
                        </Col>
                    </Row>
                    <Portal.Out id="form-actions"
                                className="form-actions"
                    />
                    <Portal.In target={actionsPortalTarget}>
                        <Button type="primary"
                                disabled={props.isSubmitting || !props.isValid}
                                onClick={props.handleSubmit}>
                            {i18n.t('global.accept')}
                        </Button>
                    </Portal.In>
                </Form>
            )}
        </Formik>
    );
};

ClientBudgetForm.defaultProps = {
    actionsPortalTarget: 'sidebar-footer',
    client: {},
    onSubmit: () => {}
};

export default ClientBudgetForm;
