import React, { Fragment, useContext, useEffect, useState } from 'react';
import { withLayout } from '../../components/HOC/WithLayout';
import Portal from '../../components/Portal/Portal';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { I18nContext } from 'react-i18next';
import { AppContext } from '../../context/AppProvider';
import { useParams } from 'react-router-dom';
import { PageHeader, Typography } from 'antd';
import './clientsbudget-single-page.scss';
import Skeleton from '../../components/Skeleton/Skeleton';
import { notify } from '../../modules/notify/notify';
import { ACTION } from '../../components/CRUD/constants';
import ClientBudgetForm from "../../components/CRUD/clientbudget/ClientBudgetForm/ClientBudgetForm";

const ClientsBudgetsSinglePage = props => {
    const {i18n} = useContext(I18nContext);
    const appContext = useContext(AppContext);
    const [clientBudget, setClientBudget] = useState({});
    const { id: idClientBudget } = useParams();
    const [loading, setLoading] = useState(true);

    const findById = () => {
        setLoading(true);
        appContext.services.clientsBudgets
            .findById(idClientBudget)
            .then(budget => setClientBudget(budget))
            .catch(console.error)
            .finally(() => setLoading(false));
    };

    const updateClientBudget = values => {
        console.log('update a:', idClientBudget);
        appContext.services.clientsBudgets
            .update(idClientBudget, values)
            .then(response => {
                if (!response.success) return;
                notify.success({description: i18n.t('global.item_updated')});
                findById();
            })
            .catch(error => {
                notify.error({description: i18n.t('global.request_error')});
                console.error(error);
            });
    };

    useEffect(findById, []);

    return (
        <Fragment>
            <div className="clientsbubdgets-single-page">
                <Typography.Title level={3}>{i18n.t('global.basic_info')}</Typography.Title>
                <Skeleton loading={loading}>
                    <ClientBudgetForm clientBudget={clientBudget}
                                     action={ACTION.UPDATE}
                                     actionsPortalTarget="form-actions"
                                     onSubmit={updateClientBudget}
                    />
                </Skeleton>
            </div>

            <Portal.In target="page-header">
                <PageHeader title={idClientBudget}
                />
            </Portal.In>

            <Portal.In target="page-breadcrumbs">
                <Breadcrumbs breadcrumbs={[
                    {path: '/clients-budgets', name: i18n.t('page.clients-budgets.menu')},
                    {path: `/clients-budgets/${idClientBudget}`, name: idClientBudget}
                ]} />
            </Portal.In>
        </Fragment>
    );
};

export default withLayout(ClientsBudgetsSinglePage);
