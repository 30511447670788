import React, { useContext } from 'react';
import { Form, Formik } from 'formik';
import InputField from '../../../Form/Fields/InputField';
import Button from '../../../Button/Button';
import getValidationSchema from './get-validation-schema';
import Portal from '../../../Portal/Portal';
import { I18nContext } from 'react-i18next';
import { Col, Row } from 'antd';
import TextAreaField from '../../../Form/Fields/TextAreaField';
import UnsavedPrompt from '../../../UnsavedPrompt/UnsavedPrompt';

const ProviderForm = ({provider, actionsPortalTarget, ...props}) => {
    const {i18n} = useContext(I18nContext);

    return (
        <Formik validationSchema={getValidationSchema(i18n)}
                enableReinitialize={true}
                initialValues={{
                    name: provider.name || '',
                    email: provider.email || '',
                    phone: provider.phone || '',
                    internalContact: provider.internalContact || '',
                    comments: provider.comments || ''
                }}
                onSubmit={(values, {setSubmitting}) => {
                    props.onSubmit(values);
                    setSubmitting(false);
                }}>
            {props => (
                <Form>
                    <UnsavedPrompt when={props.dirty} />
                    <Row gutter={15}>
                        <Col span={24}>
                            <InputField label={i18n.t('entity.provider.property.name')}
                                        name="name"
                                        type="text"
                                        placeholder={i18n.t('entity.provider.property.name')}
                            />
                        </Col>
                    </Row>
                    <Row gutter={15}>
                        <Col span={12}>
                            <InputField label={i18n.t('entity.provider.property.email')}
                                        name="email"
                                        type="email"
                                        placeholder={i18n.t('entity.provider.property.email')}
                            />
                        </Col>
                        <Col span={12}>
                            <InputField label={i18n.t('entity.provider.property.phone')}
                                        name="phone"
                                        type="text"
                                        placeholder={i18n.t('entity.provider.property.phone')}
                            />
                        </Col>
                        <Col span={12}>
                            <InputField label={i18n.t('entity.provider.property.internal_contact')}
                                        name="internalContact"
                                        type="text"
                                        placeholder={i18n.t('entity.provider.property.internal_contact')}
                            />
                        </Col>
                    </Row>
                    <Row gutter={15}>
                        <Col span={24}>
                            <TextAreaField label={i18n.t('entity.provider.property.comments')}
                                           name="comments"
                                           placeholder={i18n.t('entity.provider.property.comments')}
                            />
                        </Col>
                    </Row>
                    <Portal.Out id="form-actions"
                                className="form-actions"
                    />
                    <Portal.In target={actionsPortalTarget}>
                        <Button type="primary"
                                disabled={props.isSubmitting || !props.isValid}
                                onClick={props.handleSubmit}>
                            {i18n.t('global.accept')}
                        </Button>
                    </Portal.In>
                </Form>
            )}
        </Formik>
    );
};

ProviderForm.defaultProps = {
    actionsPortalTarget: 'sidebar-footer',
    provider: {},
    onSubmit: () => {}
};

export default ProviderForm;
