import React from 'react';
import { Radio } from 'antd';
import FieldWrapper from './FieldWrapper';
import { useField } from 'formik';

const RadioGroupField = props => {
    const [field, meta] = useField(props);

    return (
        <FieldWrapper {...props} meta={meta}>
            <Radio.Group {...field}
                         {...props}
                         className="input-field__control"
                         defaultValue={field.value}
                         onChange={event => props.onChange(event.target.value)}>
                {props.options.map(option => (
                    <Radio key={option.value}
                           value={option.value}>
                        {option.label}
                    </Radio>
                ))}
            </Radio.Group>
        </FieldWrapper>
    );
};

export default RadioGroupField;
