import React, { useContext } from 'react';
import Button from '../Button/Button';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import SlideToggle from 'react-slide-toggle';
import { I18nContext } from 'react-i18next';
import './more-filters-toggle.scss';

const MoreFiltersToggle = props => {
    const {i18n} = useContext(I18nContext);

    return (
        <SlideToggle duration={200}
                     collapsed={!props.open}
                     render={({toggle, setCollapsibleElement, toggleState, isMoving, hasReversed, range, progress}) => (
            <div className="more-filters-toggle">
                <div className="more-filters-toggle__box"
                     ref={setCollapsibleElement}>
                    <div className="more-filters-toggle__box-inner"
                         style={{opacity: Math.max(0.5, progress)}}>
                        {props.children}
                    </div>
                </div>
                <div className="more-filters-toggle__actions">
                    <Button size="small"
                            type="dashed"
                            loading={isMoving}
                            icon={toggleState === 'COLLAPSED' ? <DownOutlined /> : <UpOutlined />}
                            onClick={toggle}>
                        {toggleState === 'COLLAPSED'
                            ? i18n.t('component.more_filters.action.show_more')
                            : i18n.t('component.more_filters.action.show_less')
                        }
                    </Button>
                </div>
            </div>
        )}/>
    );
};

export default MoreFiltersToggle;
