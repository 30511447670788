import React, { useContext } from 'react';
import { Col, Row } from 'antd';
import { I18nContext } from 'react-i18next';
import FiltersBar from '../../../FiltersBar/FiltersBar';
import { Form, Formik } from 'formik';
import InputField from '../../../Form/Fields/InputField';
import Button from '../../../Button/Button';
import MoreFiltersToggle from '../../../MoreFiltersToggle/MoreFiltersToggle';
import RadioGroupField from '../../../Form/Fields/RadioGroupField';
import RangePickerField from '../../../Form/Fields/RangePickerField';

const UserFilters = props => {
    const {i18n} = useContext(I18nContext);
    const hasActiveFilters = !!props.filters && Object.keys(props.filters).length;

    return (
        <FiltersBar showWarning={!!props.filters && Object.keys(props.filters).length}
                    onReset={() => props.onFilter({})}>
            <Formik enableReinitialize
                    initialValues={{
                        query: props.filters?.query || '',
                        active: props.filters?.active || undefined,
                        creationRange: props.filters?.creationRange || []
                    }}
                    onSubmit={(values, {setSubmitting}) => {
                        props.onFilter(values);
                        setSubmitting(false);
                    }}>
                {props => (
                    <Form>
                        <Row gutter={15}>
                            <Col span={22}>
                                <Row gutter={15}>
                                    <Col span={24}>
                                        <InputField name="query"
                                                    type="text"
                                                    size="large"
                                                    placeholder={i18n.t('global.search')}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={2}>
                                <Button type="primary"
                                        size="large"
                                        block
                                        loading={props.isSubmitting}
                                        disabled={props.isSubmitting || !props.isValid}
                                        onClick={props.handleSubmit}>
                                    {i18n.t('global.filter')}
                                </Button>
                            </Col>
                        </Row>
                        <MoreFiltersToggle open={hasActiveFilters}>
                            <Row gutter={15}>
                                <Col span={6}>
                                    <RadioGroupField label={i18n.t('global.active')}
                                                     name="active"
                                                     options={[
                                                         {label: i18n.t('global.yes'), value: 'yes'},
                                                         {label: i18n.t('global.no'), value: 'no'},
                                                         {label: i18n.t('global.all'), value: 'all'}
                                                     ]}
                                                     onChange={value => props.setFieldValue('active', value)}
                                    />
                                </Col>
                                <Col span={6}>
                                    <RangePickerField label={i18n.t('global.created_between')}
                                                      name="creationRange"
                                                      onChange={value => props.setFieldValue('creationRange', value)}
                                    />
                                </Col>
                            </Row>
                        </MoreFiltersToggle>
                    </Form>
                )}
            </Formik>
        </FiltersBar>
    );
}

UserFilters.defaultProps = {
    onFilter: () => {}
};

export default UserFilters;
