import React from 'react';
import { Col, InputNumber, Row, Slider } from 'antd';
import FieldWrapper from './FieldWrapper';
import { useField } from 'formik';

const Step = props => (
    <Row gutter="10">
        <Col flex="auto">
            <Slider min={props.limits[0]}
                    max={props.limits[1]}
                    onChange={props.onChange}
                    value={typeof props.value === 'number' ? props.value : 0}
            />
        </Col>
        <Col flex="40px">
            <InputNumber value={props.value}
                         onChange={props.onChange}
            />
        </Col>
    </Row>
);

const NumberRangeField = props => {
    const [field, meta] = useField(props);

    return (
        <FieldWrapper {...props} meta={meta}>
            <div className="input-field__number-range-container">
                <Step limits={props.limits}
                      value={field.value[0]}
                      onChange={from => props.onChange([from, field.value[1]])}
                />
                <Step limits={props.limits}
                      value={field.value[1]}
                      onChange={to => props.onChange([field.value[0], to])}
                />
            </div>
        </FieldWrapper>
    );
};

NumberRangeField.defaultProps = {
    limits: [0, 100000]
};

export default NumberRangeField;
