import React, { useContext, useEffect, useState, useRef } from 'react';
import { Col, Row } from 'antd';
import { I18nContext } from 'react-i18next';
import FiltersBar from '../../../FiltersBar/FiltersBar';
import { Form, Formik } from 'formik';
import InputField from '../../../Form/Fields/InputField';
import Button from '../../../Button/Button';
import MoreFiltersToggle from '../../../MoreFiltersToggle/MoreFiltersToggle';
import { countries } from '../../../../modules/get-country/countries';
import SelectField from '../../../Form/Fields/SelectField';
import { spanishProvinces } from '../../../../modules/get-province/provinces';
import RangePickerField from '../../../Form/Fields/RangePickerField';
import RadioGroupField from '../../../Form/Fields/RadioGroupField';
import { AppContext } from '../../../../context/AppProvider';
import ProviderSelectField from '../../provider/ProviderSelectField/ProviderSelectField';
import PriceRangeSelectField from '../../price/PriceRangeSelectField/PriceRangeSelectField';

const MediaFilters = props => {
    const {i18n} = useContext(I18nContext);
    const appContext = useContext(AppContext);
    const hasActiveFilters = !!props.filters && Object.keys(props.filters).length;
    const [topics, setTopics] = useState([]);
    const [linkTypes, setLinkTypes] = useState([]);
    const [mediaTypes, setMediaTypes] = useState([]);

    const managedPriceRef = useRef();
    //const mediaPriceRef = useRef();
    const pvpPriceRef = useRef();

    const findMediaTypes = () => {
        appContext.services.mediaType
            .findList()
            .then(mediaTypes => setMediaTypes(mediaTypes || []))
            .catch(console.error);
    };

    const findTopics = () => {
        appContext.services.topic
            .findList()
            .then(topics => setTopics(topics))
            .catch(console.error);
    };

    const findLinkTypes = () => {
        appContext.services.linkType
            .findList()
            .then(linkTypes => setLinkTypes(linkTypes))
            .catch(console.error);
    };

    useEffect(() => {
        findTopics();
        findLinkTypes();
        findMediaTypes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <FiltersBar showWarning={hasActiveFilters}
                    onReset={() => props.onFilter({})}>
            <Formik enableReinitialize
                    initialValues={{
                        query: props.filters?.query || '',
                        active: props.filters?.active || undefined,
                        country: props.filters?.country || '',
                        province: props.filters?.province || '',
                        provider: props.filters?.provider || {},
                        linkTypes: props.filters?.linkTypes || [],
                        mediaType: props.filters?.mediaType || '',
                        prices: props.filters?.prices || [],
                        creationRange: props.filters?.creationRange || [],
                        topics: props.filters?.topics || [],
                        pvpPriceRange: props.filters?.pvpPriceRange || [],
                        managedPriceRange: props.filters?.managedPriceRange || [],
                        mediaPriceRange: props.filters?.mediaPriceRange || []
                    }}
                    onSubmit={(values, {setSubmitting}) => {
                        props.onFilter(values);
                        setSubmitting(false);
                    }}>
                {props => (
                    <Form>
                        <Row gutter={15}>
                            <Col span={22}>
                                <Row gutter={15}>
                                    <Col span={24}>
                                        <InputField name="query"
                                                    type="text"
                                                    size="large"
                                                    placeholder={i18n.t('global.search')}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={2}>
                                <Button type="primary"
                                        size="large"
                                        htmlType="submit"
                                        block
                                        loading={props.isSubmitting}
                                        disabled={props.isSubmitting || !props.isValid}
                                        onClick={props.handleSubmit}>
                                    {i18n.t('global.filter')}
                                </Button>
                            </Col>
                        </Row>
                        <MoreFiltersToggle open={hasActiveFilters}>
                            <Row gutter={15}>
                                <Col span={6}>
                                    <RadioGroupField label={i18n.t('global.active')}
                                                     name="active"
                                                     options={[
                                                         {label: i18n.t('global.yes'), value: 'yes'},
                                                         {label: i18n.t('global.no'), value: 'no'},
                                                         {label: i18n.t('global.all'), value: 'all'}
                                                     ]}
                                                     onChange={value => props.setFieldValue('active', value)}
                                    />
                                </Col>
                                <Col span={4}>
                                    <SelectField label={i18n.t('entity.media.property.country')}
                                                 name="country"
                                                 allowClear
                                                 autoComplete="new-password"
                                                 options={countries}
                                                 placeholder={i18n.t('entity.media.property.country')}
                                                 onChange={value => {
                                                     props.setFieldValue('country', value);
                                                     if (value !== 630) props.setFieldValue('province', 0);
                                                 }}
                                    />
                                </Col>
                                <Col span={4}>
                                    <SelectField label={i18n.t('entity.media.property.province')}
                                                 name="province"
                                                 allowClear
                                                 placeholder={i18n.t('entity.media.property.province')}
                                                 options={props.values['country'] === 630 ? spanishProvinces : [{label: '–', value: 0}]}
                                                 onChange={value => props.setFieldValue('province', value)}
                                    />
                                </Col>
                                <Col span={8}>
                                    <RangePickerField label={i18n.t('global.created_between')}
                                                      name="creationRange"
                                                      onChange={value => props.setFieldValue('creationRange', value)}
                                    />
                                </Col>
                                <Col span={4}>
                                    <SelectField label={i18n.t('entity.media.property.topics')}
                                                 mode="multiple"
                                                 allowClear
                                                 name="topics"
                                                 options={topics}
                                                 placeholder={i18n.t('entity.media.property.topics')}
                                                 onChange={value => props.setFieldValue('topics', value)}
                                    />
                                </Col>
                                <Col span={4}>
                                    <SelectField label={i18n.t('entity.link_type.title_plural')}
                                                 mode="multiple"
                                                 name="linkTypes"
                                                 allowClear
                                                 options={linkTypes}
                                                 placeholder={i18n.t('entity.link_type.title_plural')}
                                                 onChange={value => props.setFieldValue('linkTypes', value)}
                                    />
                                </Col>
                                <Col span={4}>
                                    <ProviderSelectField label={i18n.t('entity.provider.title')}
                                                         name="provider"
                                                         allowClear
                                                         placeholder={i18n.t('entity.provider.title')}
                                                         onChange={value => props.setFieldValue('provider', value)}
                                    />
                                </Col>
                                <Col span={4}>
                                    <SelectField label={i18n.t('entity.media_type.title')}
                                                 name="mediaType"
                                                 allowClear
                                                 options={mediaTypes}
                                                 placeholder={i18n.t('entity.media_type.title')}
                                                 onChange={value => props.setFieldValue('mediaType', value)}
                                    />
                                </Col>
                                <Col span={4} ref={managedPriceRef}>
                                    <PriceRangeSelectField label={i18n.t('entity.price.property.managed_price')}
                                                           filter="managedPrice"
                                                           name="managedPriceRange"
                                                           allowClear
                                                           placeholder={i18n.t('entity.price.property.managed_price')}
                                                           onChange={value => {
                                                               props.setFieldValue('managedPrices', value.ids);
                                                               props.setFieldValue('managedPriceRange', value.range);
                                                           }}
                                    />
                                </Col>
                                <Col span={4} ref={pvpPriceRef} >
                                    <PriceRangeSelectField label={i18n.t('entity.price.property.pvp_price')}
                                                           filter="pvpPrice"
                                                           name="pvpPriceRange"
                                                           allowClear
                                                           placeholder={i18n.t('entity.price.property.pvp_price')}
                                                           onChange={value => {
                                                               props.setFieldValue('pvpPrices', value.ids);
                                                               props.setFieldValue('pvpPriceRange', value.range);
                                                           }}
                                    />
                                </Col>
                            </Row>
                        </MoreFiltersToggle>
                    </Form>
                )}
            </Formik>
        </FiltersBar>
    );
}

MediaFilters.defaultProps = {
    onFilter: () => {}
};

export default MediaFilters;
