import React, { useContext } from 'react';
import { Form, Formik } from 'formik';
import InputField from '../../../Form/Fields/InputField';
import Button from '../../../Button/Button';
import getValidationSchema from './get-validation-schema';
import Portal from '../../../Portal/Portal';
import { I18nContext } from 'react-i18next';
import { Col, Row } from 'antd';
import UnsavedPrompt from '../../../UnsavedPrompt/UnsavedPrompt';

const ClientForm = ({client, actionsPortalTarget, ...props}) => {
    const {i18n} = useContext(I18nContext);

    return (
        <Formik validationSchema={getValidationSchema(i18n)}
                enableReinitialize={true}
                initialValues={{
                    name: client.name || '',
                    budget: client.budget || 0,
                    monthlyGoal: client.monthlyGoal || 0
                }}
                onSubmit={(values, {setSubmitting}) => {
                    props.onSubmit(values);
                    setSubmitting(false);
                }}>
            {props => (
                <Form>
                    <UnsavedPrompt when={props.dirty} />
                    <Row gutter={15}>
                        <Col span={24}>
                            <InputField label={i18n.t('entity.client.property.name')}
                                        name="name"
                                        type="text"
                                        placeholder={i18n.t('entity.client.property.name')}
                            />
                        </Col>
                    </Row>
                    <Row gutter={15}>
                        <Col span={12}>
                            <InputField label={i18n.t('entity.client.property.budget')}
                                        name="budget"
                                        type="number"
                                        placeholder={i18n.t('entity.client.property.budget')}
                            />
                        </Col>
                        <Col span={12}>
                            <InputField label={i18n.t('entity.client.property.monthly_goal')}
                                        name="monthlyGoal"
                                        type="number"
                                        placeholder={i18n.t('entity.client.property.monthly_goal')}
                            />
                        </Col>
                    </Row>
                    <Portal.Out id="form-actions"
                                className="form-actions"
                    />
                    <Portal.In target={actionsPortalTarget}>
                        <Button type="primary"
                                disabled={props.isSubmitting || !props.isValid}
                                onClick={props.handleSubmit}>
                            {i18n.t('global.accept')}
                        </Button>
                    </Portal.In>
                </Form>
            )}
        </Formik>
    );
};

ClientForm.defaultProps = {
    actionsPortalTarget: 'sidebar-footer',
    client: {},
    onSubmit: () => {}
};

export default ClientForm;
