import * as Yup from 'yup';

const getValidationSchema = i18n => Yup.object({
    email: Yup.string()
        .required(i18n.t('global.required')),
    password: Yup.string()
        .required(i18n.t('global.required'))
});

export default getValidationSchema;
