import React, { Fragment, useContext, useEffect, useState } from 'react';
import { withLayout } from '../../components/HOC/WithLayout';
import Portal from '../../components/Portal/Portal';
import {Rate, Button, PageHeader } from 'antd';
import { I18nContext } from 'react-i18next';
import { AppContext } from '../../context/AppProvider';
import Sidebar from '../../components/Sidebar/Sidebar';
import { PlusOutlined, DownloadOutlined } from '@ant-design/icons';
import ClientForm from '../../components/CRUD/client/ClientForm/ClientForm';
import ClientsList from '../../components/CRUD/client/ClientsList/ClientsList';
import Pagination from '../../components/Pagination/Pagination';
import Skeleton from '../../components/Skeleton/Skeleton';
import dayjs from 'dayjs';
import ClientFilters from '../../components/CRUD/client/ClientFilters/ClientFilters';
import json2CSVDownload from '../../modules/json2csv-download/json2csv-download';
import './clients-list-page.scss';
import { notify } from '../../modules/notify/notify';

const ClientsListPage = props => {
    const {i18n} = useContext(I18nContext);
    const appContext = useContext(AppContext);
    const [createSidebarIsVisible, setCreateSidebarIsVisible] = useState(false);
    const [clients, setClients] = useState([]);
    const [loading, setLoading] = useState(true);
    const [downloading, setDownloading] = useState(false);
    const [filters, setFilters] = useState(JSON.parse(localStorage.getItem('clientFilters')));

    const search = (page = 1) => {
        setLoading(true);
        appContext.services.client
            .search(page, filters)
            .then(clients => setClients(clients))
            .catch(console.error)
            .finally(() => setLoading(false));
    };

    const createClient = values => {
        appContext.services.client
            .create(values)
            .then(response => {
                if (!response.success) return;
                notify.success({description: i18n.t('global.item_updated')});
                setCreateSidebarIsVisible(false);
                search();
            })
            .catch(console.error);
    };

    const downloadCSV = filters => {
        setDownloading(true);
        appContext.services.client
            .search(1, filters, true)
            .then(clients => {
                const fields = ['idClient', 'name', 'budget', 'monthlyGoal', 'active', 'tmsCreated', 'tmsUpdated'];
                const rows = clients.map(client => {
                    return {
                        ...client,
                        tmsCreated: dayjs.unix(client.tmsCreated).format('DD/MM/YYYY HH:mm:ss'),
                        tmsUpdated: dayjs.unix(client.tmsUpdated).format('DD/MM/YYYY HH:mm:ss')
                    }
                });

                json2CSVDownload(fields, rows, i18n.t('entity.client.title', {count: clients.length}));
            })
            .catch(console.error)
            .finally(() => setDownloading(false));
    };

    const persistFilters = filters => {
        console.log('filters:', filters);
        localStorage.removeItem('clientFilters');
        if (filters && Object.keys(filters).length) {
            localStorage.setItem('clientFilters', JSON.stringify(filters));
        }
    };

    useEffect(() => {
        persistFilters(filters);
        search(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    return (
        <Fragment>

            <Rate />

            <div className="clients-list-page">
                <ClientFilters filters={filters}
                               onFilter={filters => setFilters(filters)}
                />
                <Skeleton loading={loading}>
                    <ClientsList data={clients?.items}
                                 onUpdate={search}
                    />
                    <Pagination totalItems={clients?.totalItems}
                                currentPage={clients?.currentPage}
                                itemsPerPage={clients?.itemsPerPage}
                                onChange={page => search(page)}
                    />
                </Skeleton>
            </div>

            <Portal.In target="page-header">
                <PageHeader title={i18n.t('page.clients.title')}
                            extra={[
                                <Button key="1"
                                        loading={downloading}
                                        icon={<DownloadOutlined />}
                                        disabled={!clients?.totalItems}
                                        onClick={event => downloadCSV(filters)}>
                                    CSV
                                </Button>,
                                <Button key="2"
                                        type="primary"
                                        icon={<PlusOutlined />}
                                        onClick={event => setCreateSidebarIsVisible(true)}>
                                    {i18n.t('entity.client.title')}
                                </Button>
                            ]}
                />
            </Portal.In>
            <Sidebar visible={createSidebarIsVisible}
                     title={i18n.t('entity.client.create_title')}
                     onClose={event => setCreateSidebarIsVisible(false)}>
                <ClientForm onSubmit={createClient} />
            </Sidebar>
        </Fragment>
    );
};

export default withLayout(ClientsListPage);
