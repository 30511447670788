import BaseService from './BaseService';
import convertToSelectOptions from '../modules/convert-to-select-options/convert-to-select-options';

export default class ClientService extends BaseService {
    entityEndpoint = 'client';

    findList(active = true) {
        const endpoint = `${this.entityEndpoint}/list`;

        return this
            .fetch(`${this.API_URL}${endpoint}`, {method: 'GET'}, {active})
            .then(response => convertToSelectOptions(response, 'idClient', 'name'));
    }

    search(page = 1, filters = {}, list = undefined) {
        const adaptedFilters = {
            ...filters,
            creationRange: filters?.creationRange ? filters.creationRange.join(',') : undefined,
            budget: filters?.budget ? filters.budget.join(',') : undefined,
            monthlyGoal: filters?.monthlyGoal ? filters.monthlyGoal.join(',') : undefined
        };
        return super.search.call(this, this.entityEndpoint, page, adaptedFilters, list);
    }

    query(query = {}) {
        return super.query.call(this, this.entityEndpoint, query)
            .then(response => { console.log(response); return response ? convertToSelectOptions(response, 'idClient', 'name') : []});
    }

    findAll(page = 1) {
        return super.findAll.call(this, this.entityEndpoint, page);
    }

    findById(idClient) {
        return super.findById.call(this, this.entityEndpoint, idClient);
    }

    remove(idClient) {
        return super.remove.call(this, this.entityEndpoint, idClient);
    }

    toggleActive(idClient, active) {
        return super.toggleActive.call(this, this.entityEndpoint, idClient, active);
    }

    create(values) {
        return super.create.call(this, this.entityEndpoint, values);
    }

    update(idClient, values) {
        return super.update.call(this, this.entityEndpoint, idClient, values);
    }
}
