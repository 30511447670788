import React, { Fragment, useContext, useEffect, useState } from 'react';
import { withLayout } from '../../components/HOC/WithLayout';
import Portal from '../../components/Portal/Portal';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { I18nContext } from 'react-i18next';
import { AppContext } from '../../context/AppProvider';
import { useParams } from 'react-router-dom';
import { Col, PageHeader, Row, Switch, Typography } from 'antd';
import ClientForm from '../../components/CRUD/client/ClientForm/ClientForm';
import Skeleton from '../../components/Skeleton/Skeleton';
import FieldWrapper from '../../components/Form/Fields/FieldWrapper';
import { notify } from '../../modules/notify/notify';
import TransactionsList from '../../components/CRUD/transaction/TransactionsList/TransactionsList';
import './client-single-page.scss';

const ClientSinglePage = props => {
    const {i18n} = useContext(I18nContext);
    const appContext = useContext(AppContext);
    const [client, setClient] = useState({});
    const { id: idClient } = useParams();
    const [loading, setLoading] = useState(true);

    const findById = () => {
        setLoading(true);
        appContext.services.client
            .findById(idClient)
            .then(client => setClient(client))
            .catch(console.error)
            .finally(() => setLoading(false));
    };

    const updateClient = values => {
        appContext.services.client
            .update(idClient, values)
            .then(response => {
                if (!response.success) return;
                notify.success({description: i18n.t('global.item_updated')});
                findById();
            })
            .catch(error => {
                notify.error({description: i18n.t('global.request_error')});
                console.error(error);
            });
    };

    const updateActive = (idClient, active) => {
        appContext.services.client
            .toggleActive(idClient, active)
            .then(response => {
                notify.success({description: i18n.t('global.item_updated')});
                findById();
            });
    };

    useEffect(findById, []);

    return (
        <Fragment>
            <div className="client-single-page">
                <Row gutter={[15, 30]}>
                    <Col xs={24}>
                        <Typography.Title level={3}>{i18n.t('global.basic_info')}</Typography.Title>
                        <Skeleton loading={loading}>
                            <ClientForm client={client}
                                        actionsPortalTarget="form-actions"
                                        onSubmit={updateClient}
                            />
                        </Skeleton>
                    </Col>
                    <Col xs={24}>
                        <Typography.Title level={3}>{i18n.t('entity.transaction.title', {count: client.transactions?.length})}</Typography.Title>
                        <Skeleton loading={loading}>
                            <TransactionsList data={client.transactions} readOnly />
                        </Skeleton>
                    </Col>
                </Row>
            </div>

            <Portal.In target="page-header">
                <PageHeader title={client.name}
                            extra={[
                                <FieldWrapper key="1"
                                              label={i18n.t('global.active')}>
                                    <Skeleton loading={loading}
                                              paragraph={{rows: 1, width: 50}}
                                              title={false}>
                                        <Switch checked={client.active}
                                                onChange={active => updateActive(client.idClient, active)}
                                        />
                                    </Skeleton>
                                </FieldWrapper>
                            ]}
                />
            </Portal.In>

            <Portal.In target="page-breadcrumbs">
                <Breadcrumbs breadcrumbs={[
                    {path: '/clients', name: i18n.t('page.clients.menu')},
                    {path: `/clients/${idClient}`, name: client.name}
                ]} />
            </Portal.In>
        </Fragment>
    );
};

export default withLayout(ClientSinglePage);
