import React, { useContext } from 'react';
import { Space, Switch, Table } from 'antd';
import { I18nContext } from 'react-i18next';
import { AppContext } from '../../../../context/AppProvider';
import ConfirmButton from '../../../ConfirmButton/ConfirmButton';
import EditButton from '../../../EditButton/EditButton';
import { notify } from '../../../../modules/notify/notify';
import dayjs from 'dayjs';
import CreateClientBudgetButton from "../../clientbudget/CreateClientBudgetButton/CreateClientBudgetButton";

const ClientsList = props => {
    const {i18n} = useContext(I18nContext);
    const appContext = useContext(AppContext);

    const updateActive = (idClient, active) => {
        appContext.services.client
            .toggleActive(idClient, active)
            .then(response => {
                notify.success({description: i18n.t('global.item_updated')});
                props.onUpdate();
            });
    };

    const removeClient = idClient => {
        appContext.services.client
            .remove(idClient)
            .then(response => {
                notify.success({description: i18n.t('global.item_updated')});
                props.onUpdate();
            });
    };

    const getColumns = i18n => {
        return [
            {
                title: i18n.t('entity.client.property.name'),
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: i18n.t('entity.client.property.budget'),
                dataIndex: 'budget',
                key: 'budget'
            },
            {
                title: i18n.t('entity.client.property.monthly_goal'),
                dataIndex: 'monthlyGoal',
                key: 'monthlyGoal'
            },
            {
                title: i18n.t('entity.client.property.available_budget'),
                dataIndex: 'availableBudget',
                key: 'availableBudget'
            },
            {
                title: i18n.t('global.registration_date'),
                dataIndex: 'tmsCreated',
                key: 'tmsCreated',
                render: date => dayjs.unix(date).format('DD/MM/YYYY')
            },
            {
                title: i18n.t('global.active'),
                dataIndex: 'active',
                key: 'active',
                render: (active, row) => (
                    <Switch defaultChecked={active}
                            onChange={active => updateActive(row.idClient, active)}
                    />
                )
            },
            {
                title: '',
                dataIndex: 'actions',
                key: 'actions',
                align: 'right',
                render: (column, row) => (
                    <Space>
                        <ConfirmButton disabled={row.transactions?.length}
                                       onConfirm={event => removeClient(row.idClient)}
                        />
                        <EditButton to={`/clients/${row.idClient}`} />
                        <CreateClientBudgetButton client={row}/>
                    </Space>
                )
            },
        ];
    };

    return (
        <Table columns={getColumns(i18n)}
               dataSource={props.data}
               pagination={false}
               rowKey={row => row.idClient}
        />
    );
};

ClientsList.defaultProps = {
    onUpdate: () => {}
};

export default ClientsList;
