import BaseService from './BaseService';
import convertToSelectOptions from '../modules/convert-to-select-options/convert-to-select-options';

export default class ClientsBudgetsService extends BaseService {
    entityEndpoint = 'clients-budgets';

    search(page = 1, filters = {}, list = undefined) {
        const adaptedFilters = {
            ...filters,
        };

        return super.search.call(this, this.entityEndpoint, page, adaptedFilters, list);
    }

    query(query) {
        return super.query.call(this, this.entityEndpoint, query)
            .then(response => response ? convertToSelectOptions(response, 'idTransaction', 'urlOrigin') : []);
    }

    findAll(page = 1) {
        return super.findAll.call(this, this.entityEndpoint, page);
    }

    findById(idClientsBudgets) {
        return super.findById.call(this, this.entityEndpoint, idClientsBudgets);
    }

    remove(idClientsBudgets, packAction) {
        const endpoint = `${this.entityEndpoint}/${idClientsBudgets}`;
        const params = {};

        return this
            .fetch(`${this.API_URL}${endpoint}`, {method: 'DELETE'}, params)
            .then(response => response);
    }


    create(values) {
        console.log('values son:', values);
        return super.create.call(this, this.entityEndpoint, values);
    }

    update(idClientBudget, values) {

        console.log('idClient:', idClientBudget, 'values :', values, 'paso a super');
        return super.update.call(this, this.entityEndpoint, idClientBudget, values);
    }
}
