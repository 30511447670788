export const countries = [
    {label: 'Otro', value: 0},
    {label: 'Argentina', value: 10},
    {label: 'Afganistán', value: 20},
    {label: 'Albania', value: 30},
    {label: 'Alemania', value: 40},
    {label: 'Andorra', value: 50},
    {label: 'Angola', value: 60},
    {label: 'Anguilla', value: 70},
    {label: 'Antártida Argentina', value: 80},
    {label: 'Antigua y Barbuda', value: 90},
    {label: 'Antillas Holandesas', value: 100},
    {label: 'Arabia Saudita', value: 110},
    {label: 'Argelia', value: 120},
    {label: 'Armenia', value: 130},
    {label: 'Aruba', value: 140},
    {label: 'Australia', value: 150},
    {label: 'Austria', value: 160},
    {label: 'Azerbaiján', value: 170},
    {label: 'Bahamas', value: 180},
    {label: 'Bahrain', value: 190},
    {label: 'Bangladesh', value: 200},
    {label: 'Barbados', value: 210},
    {label: 'Bélgica', value: 220},
    {label: 'Belice', value: 230},
    {label: 'Benin', value: 240},
    {label: 'Bhutan', value: 250},
    {label: 'Bielorusia', value: 260},
    {label: 'Bolivia', value: 270},
    {label: 'Bosnia Herzegovina', value: 280},
    {label: 'Botswana', value: 290},
    {label: 'Brasil', value: 300},
    {label: 'Brunei', value: 310},
    {label: 'Bulgaria', value: 320},
    {label: 'Burkina Faso', value: 330},
    {label: 'Burundi', value: 340},
    {label: 'Cabo Verde', value: 350},
    {label: 'Camboya', value: 360},
    {label: 'Camerún', value: 370},
    {label: 'Canadá', value: 380},
    {label: 'Chad', value: 390},
    {label: 'Chile', value: 400},
    {label: 'China', value: 410},
    {label: 'Chipre', value: 420},
    {label: 'Colombia', value: 430},
    {label: 'Comoros', value: 440},
    {label: 'Congo', value: 450},
    {label: 'Corea del Norte', value: 460},
    {label: 'Corea del Sur', value: 470},
    {label: 'Costa de Marfil', value: 480},
    {label: 'Costa Rica', value: 490},
    {label: 'Croacia', value: 500},
    {label: 'Cuba', value: 510},
    {label: 'Darussalam', value: 520},
    {label: 'Dinamarca', value: 530},
    {label: 'Djibouti', value: 540},
    {label: 'Dominica', value: 550},
    {label: 'Ecuador', value: 560},
    {label: 'Egipto', value: 570},
    {label: 'El Salvador', value: 580},
    {label: 'Em. Arabes Un.', value: 590},
    {label: 'Eritrea', value: 600},
    {label: 'Eslovaquia', value: 610},
    {label: 'Eslovenia', value: 620},
    {label: 'España', value: 630},
    {label: 'Estados Unidos', value: 640},
    {label: 'Estonia', value: 650},
    {label: 'Etiopía', value: 660},
    {label: 'Fiji', value: 670},
    {label: 'Filipinas', value: 680},
    {label: 'Finlandia', value: 690},
    {label: 'Francia', value: 700},
    {label: 'Gabón', value: 710},
    {label: 'Gambia', value: 720},
    {label: 'Georgia', value: 730},
    {label: 'Ghana', value: 740},
    {label: 'Gibraltar', value: 750},
    {label: 'Grecia', value: 760},
    {label: 'Grenada', value: 770},
    {label: 'Groenlandia', value: 780},
    {label: 'Guadalupe', value: 790},
    {label: 'Guam', value: 800},
    {label: 'Guatemala', value: 810},
    {label: 'Guayana Francesa', value: 820},
    {label: 'Guinea', value: 830},
    {label: 'Guinea Ecuatorial', value: 840},
    {label: 'Guinea-Bissau', value: 850},
    {label: 'Guyana', value: 860},
    {label: 'Haití', value: 870},
    {label: 'Holanda', value: 880},
    {label: 'Honduras', value: 890},
    {label: 'Hong Kong', value: 900},
    {label: 'Hungría', value: 910},
    {label: 'India', value: 920},
    {label: 'Indonesia', value: 930},
    {label: 'Irak', value: 940},
    {label: 'Irán', value: 950},
    {label: 'Irlanda', value: 960},
    {label: 'Islandia', value: 970},
    {label: 'Islas Cayman', value: 980},
    {label: 'Islas Cook', value: 990},
    {label: 'Islas Faroe', value: 1000},
    {label: 'Islas Marianas del Norte', value: 1010},
    {label: 'Islas Marshall', value: 1020},
    {label: 'Islas Solomon', value: 1030},
    {label: 'Islas Turcas y Caicos', value: 1040},
    {label: 'Islas Vírgenes', value: 1050},
    {label: 'Islas Wallis y Futuna', value: 1060},
    {label: 'Israel', value: 1070},
    {label: 'Italia', value: 1080},
    {label: 'Jamaica', value: 1090},
    {label: 'Japón', value: 1100},
    {label: 'Jordania', value: 1110},
    {label: 'Kazajstán', value: 1120},
    {label: 'Kenya', value: 1130},
    {label: 'Kirguistán', value: 1140},
    {label: 'Kiribati', value: 1150},
    {label: 'Kuwait', value: 1160},
    {label: 'Laos', value: 1170},
    {label: 'Lesotho', value: 1180},
    {label: 'Letonia', value: 1190},
    {label: 'Líbano', value: 1200},
    {label: 'Liberia', value: 1210},
    {label: 'Libia', value: 1220},
    {label: 'Liechtenstein', value: 1230},
    {label: 'Lituania', value: 1240},
    {label: 'Luxemburgo', value: 1250},
    {label: 'Macao', value: 1260},
    {label: 'Macedonia', value: 1270},
    {label: 'Madagascar', value: 1280},
    {label: 'Malasia', value: 1290},
    {label: 'Malawi', value: 1300},
    {label: 'Mali', value: 1310},
    {label: 'Malta', value: 1320},
    {label: 'Marruecos', value: 1330},
    {label: 'Martinica', value: 1340},
    {label: 'Mauricio', value: 1350},
    {label: 'Mauritania', value: 1360},
    {label: 'Mayotte', value: 1370},
    {label: 'México', value: 1380},
    {label: 'Micronesia', value: 1390},
    {label: 'Moldova', value: 1400},
    {label: 'Mónaco', value: 1410},
    {label: 'Mongolia', value: 1420},
    {label: 'Montserrat', value: 1430},
    {label: 'Mozambique', value: 1440},
    {label: 'Myanmar', value: 1450},
    {label: 'Namibia', value: 1460},
    {label: 'Nauru', value: 1470},
    {label: 'Nepal', value: 1480},
    {label: 'Nicaragua', value: 1490},
    {label: 'Níger', value: 1500},
    {label: 'Nigeria', value: 1510},
    {label: 'Noruega', value: 1520},
    {label: 'Nueva Caledonia', value: 1530},
    {label: 'Nueva Zelandia', value: 1540},
    {label: 'Omán', value: 1550},
    {label: 'Pakistán', value: 1570},
    {label: 'Panamá', value: 1580},
    {label: 'Papua Nueva Guinea', value: 1590},
    {label: 'Paraguay', value: 1600},
    {label: 'Perú', value: 1610},
    {label: 'Pitcairn', value: 1620},
    {label: 'Polinesia Francesa', value: 1630},
    {label: 'Polonia', value: 1640},
    {label: 'Portugal', value: 1650},
    {label: 'Puerto Rico', value: 1660},
    {label: 'Qatar', value: 1670},
    {label: 'RD Congo', value: 1680},
    {label: 'Reino Unido', value: 1690},
    {label: 'República Centroafricana', value: 1700},
    {label: 'República Checa', value: 1710},
    {label: 'República Dominicana', value: 1720},
    {label: 'Reunión', value: 1730},
    {label: 'Rumania', value: 1740},
    {label: 'Rusia', value: 1750},
    {label: 'Rwanda', value: 1760},
    {label: 'Sahara Occidental', value: 1770},
    {label: 'Saint Pierre y Miquelon', value: 1780},
    {label: 'Samoa', value: 1790},
    {label: 'Samoa Americana', value: 1800},
    {label: 'San Cristóbal y Nevis', value: 1810},
    {label: 'San Marino', value: 1820},
    {label: 'Santa Elena', value: 1830},
    {label: 'Santa Lucía', value: 1840},
    {label: 'Sao Tomé y Príncipe', value: 1850},
    {label: 'Senegal', value: 1860},
    {label: 'Serbia y Montenegro', value: 1870},
    {label: 'Seychelles', value: 1880},
    {label: 'Sierra Leona', value: 1890},
    {label: 'Singapur', value: 1900},
    {label: 'Siria', value: 1910},
    {label: 'Somalia', value: 1920},
    {label: 'Sri Lanka', value: 1930},
    {label: 'Sudáfrica', value: 1940},
    {label: 'Sudán', value: 1950},
    {label: 'Suecia', value: 1960},
    {label: 'Suiza', value: 1970},
    {label: 'Suriname', value: 1980},
    {label: 'Swazilandia', value: 1990},
    {label: 'Taiwán', value: 2000},
    {label: 'Uruguay', value: 2010},
];
