import React from 'react';
import { useField } from 'formik';
import { Select } from 'antd';
import FieldWrapper from './FieldWrapper';

const SelectField = ({options, ...props}) => {
    const [field, meta] = useField(props);

    return (
        <FieldWrapper {...props} meta={meta} append={props.append}>
            <Select {...field}
                    {...props}
                    showSearch
                    className="input-field__control"
                    filterOption={(input, option) => option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
                    optionFilterProp="label"
            >
                {options.map(option => (
                    <Select.Option key={option.value}
                                   value={option.value}>
                        {option.label}
                    </Select.Option>
                ))}
            </Select>
        </FieldWrapper>
    );
};

export default SelectField;
