import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import Button from '../../../Button/Button';
import Portal from '../../../Portal/Portal';
import { I18nContext } from 'react-i18next';
import { Col, Row } from 'antd';
import SelectField from '../../../Form/Fields/SelectField';
import { AppContext } from '../../../../context/AppProvider';

const LinkTypeSelectForm = props => {
    const {i18n} = useContext(I18nContext);
    const appContext = useContext(AppContext);
    const [linkTypes, setLinkTypes] = useState([]);

    const findLinkTypes = () => {
        appContext.services.linkType
            .findList()
            .then(linkTypes => setLinkTypes(linkTypes))
            .catch(console.error);
    };

    useEffect(findLinkTypes, []);

    return (
        <Fragment>
            <Formik enableReinitialize={true}
                    initialValues={{
                        idLinkType: '',
                    }}
                    onSubmit={(values, {setSubmitting}) => {
                        props.onSubmit(values);
                        setSubmitting(false);
                    }}>
                {props => (
                    <Form>
                        <Row gutter={15}>
                            <Col span={12}>
                                <SelectField label={i18n.t('entity.link_type.property.type')}
                                             name="idLinkType"
                                             placeholder={i18n.t('entity.link_type.property.type')}
                                             options={linkTypes}
                                             onChange={value => props.setFieldValue('idLinkType', value)}
                                />
                            </Col>
                        </Row>
                        <Portal.In target="sidebar-footer">
                            <Button type="primary"
                                    disabled={props.isSubmitting || !props.isValid}
                                    onClick={props.handleSubmit}>
                                {i18n.t('global.accept')}
                            </Button>
                        </Portal.In>
                    </Form>
                )}
            </Formik>
        </Fragment>
    );
};

LinkTypeSelectForm.defaultProps = {
    onSubmit: () => {}
};

export default LinkTypeSelectForm;
