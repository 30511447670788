import decode from 'jwt-decode';
//import queryString from 'query-string';

export default class BaseService {
    constructor() {
        this.API_URL = process.env.REACT_APP_API_URL;
    }

    serializeQueryParameters(query) {
        const params = new URLSearchParams();

        for (const key in query) {
            if (query.hasOwnProperty(key) && query[key] && typeof query[key] != 'undefined' ) {
                params.append(key, query[key]);
            }
        }

        return params.toString();
    }

    fetch(path, options, params = {}, headers = {'Accept': 'application/json', 'Content-Type': 'application/json'}, responseType = 'json') {

        console.log('params:', params);
        const url = new URL(path);
        if (params && Object.keys(params).length) {
            console.log('paso if y params som:', params);
            url.search = this.serializeQueryParameters(params);

        }

        console.log(url);
        if (this.loggedIn()) {
            headers['Authorization'] = `Bearer ${this.getToken()}`;
        }

        return fetch(url.href, {
            headers,
            ...options,
            credentials: 'include' // Establece credentials directamente en el objeto de configuración
        })
            .then(this.checkStatus)
            .then(response =>
                response.status !== 204
                    ? responseType === 'blob'
                        ? response.blob()
                        : response.json()
                    : null
            );
    }

    findAll(entityEndpoint, page = 1) {
        return this
            .fetch(`${this.API_URL}${entityEndpoint}`, {method: 'GET'}, {page})
            .then(response => response);
    }

    findList(entityEndpoint) {
        return this
            .fetch(`${this.API_URL}${entityEndpoint}/list`, {method: 'GET'})
            .then(response => response);
    }

    search(entityEndpoint, page = 1, filters = {}, list = undefined) {
        const endpoint = `${entityEndpoint}/search`;

        return this
            .fetch(`${this.API_URL}${endpoint}`, {method: 'GET'}, {page, ...filters, list})
            .then(response => response);
    }

    query(entityEndpoint, query) {
        const endpoint = `${entityEndpoint}/query`;

        const params = {};
        if (query)
            params.query = this.serializeQueryParameters(query);

        return this
            .fetch(`${this.API_URL}${endpoint}`, {method: 'GET'}, params)
            .then(response => response);
    }

    findById(entityEndpoint, id) {
        const endpoint = `${entityEndpoint}/${id}`;

        return this
            .fetch(`${this.API_URL}${endpoint}`, {method: 'GET'})
            .then(response => response);
    }

    remove(entityEndpoint, id) {
        const endpoint = `${entityEndpoint}/${id}`;

        return this
            .fetch(`${this.API_URL}${endpoint}`, {method: 'DELETE'})
            .then(response => response);
    }

    toggleActive(entityEndpoint, idItem, active) {
        const endpoint = `${entityEndpoint}/${idItem}/active`;
        const data = new URLSearchParams();
        data.append('active', active);

        return this
            .fetch(`${this.API_URL}${endpoint}`,
                {
                    method: 'PUT',
                    body: data
                },
                null,
                {'Content-Type': 'application/x-www-form-urlencoded'}
            )
            .then(response => response);
    }

    create(entityEndpoint, values) {
        const endpoint = `${entityEndpoint}/create`;
        const data = new URLSearchParams();
        Object.entries(values).map(([key, value]) => data.append(key, value.toString()));

        return this
            .fetch(`${this.API_URL}${endpoint}`,
                {
                    method: 'POST',
                    body: data
                },
                null,
                {'Content-Type': 'application/x-www-form-urlencoded'}
            )
            .then(response => response);
    }

    update(entityEndpoint, idItem, values) {
        const endpoint = `${entityEndpoint}/${idItem}`;
        const data = new URLSearchParams();
        Object.entries(values).map(([key, value]) => data.append(key, value.toString()));

        return this
            .fetch(`${this.API_URL}${endpoint}`,
                {
                    method: 'PUT',
                    body: data
                },
                {},
                {'Content-Type': 'application/x-www-form-urlencoded'}
            )
            .then(response => response);
    }

    loggedIn() {
        const token = this.getToken();
        const expired = this.isTokenExpired(token)
        console.log(token, expired);
        return !!token && !expired;
    }

    isTokenExpired(token) {
        try {
            const decoded = decode(token);
            return decoded.exp < Date.now() / 1000;
        } catch (err) {
            return false;
        }
    }

    setToken(idToken) {
        localStorage.setItem('id_token', idToken);
    }

    getToken() {
        return localStorage.getItem('id_token');
    }

    logout() {
        localStorage.removeItem('id_token');
    }

    decodeToken() {
        return decode(this.getToken());
    }

    checkStatus(response) {
        if (response.status >= 200 && response.status < 300) {
            return response;
        } if (response.status === 409) {
            const error = new Error();
            error.name = 'Error 409'
            error.message = 'HTTP_CONFLICT';
            throw error;
        } else {
            const error = new Error(response.statusText);
            error.response = response;
            throw error;
        }
    }
}
