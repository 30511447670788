import React, { Fragment, useContext, useState } from 'react';
import { Space, Table } from 'antd';
import { I18nContext } from 'react-i18next';
import { AppContext } from '../../../../context/AppProvider';
import ConfirmButton from '../../../ConfirmButton/ConfirmButton';
import EditButton from '../../../EditButton/EditButton';
import Sidebar from '../../../Sidebar/Sidebar';
import TopicForm from '../TopicForm/TopicForm';
import { notify } from '../../../../modules/notify/notify';

const TopicsList = props => {
    const {i18n} = useContext(I18nContext);
    const appContext = useContext(AppContext);
    const [editSidebarIsVisible, setEditSidebarIsVisible] = useState(false);
    const [currentTopic, setCurrentStatus] = useState({});

    const removeTopic = idTopic => {
        appContext.services.topic
            .remove(idTopic)
            .then(response => {
                notify.success({name: i18n.t('global.item_updated')});
                props.onUpdate();
            });
    };

    const updateTopic = (values, id) => {
        appContext.services.topic
            .update(id, values)
            .then(response => {
                if (!response.success) return;
                setEditSidebarIsVisible(false);
                notify.success({name: i18n.t('global.item_updated')});
                props.onUpdate();
            })
            .catch(console.error);
    };

    const getColumns = i18n => {
        return [
            {
                title: i18n.t('Id'),
                dataIndex: 'idTopic',
                key: 'idTopic'
            },
            {
                title: i18n.t('entity.topic.property.name'),
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: '',
                dataIndex: 'actions',
                key: 'actions',
                align: 'right',
                render: (column, row) => (
                    <Space>
                        <ConfirmButton onConfirm={event => removeTopic(row.idTopic)} />
                        <EditButton onClick={event => {
                            setCurrentStatus(row);
                            setEditSidebarIsVisible(true);
                        }} />
                    </Space>
                )
            },
        ];
    };

    return (
        <Fragment>
            <Table columns={getColumns(i18n)}
                   dataSource={props.data}
                   pagination={false}
                   rowKey={row => row.idTopic}
            />
            <Sidebar visible={editSidebarIsVisible}
                     title={props.entityTitle}
                     onClose={event => setEditSidebarIsVisible(false)}>
                <TopicForm topic={currentTopic}
                           onSubmit={updateTopic}
                />
            </Sidebar>
        </Fragment>
    );
};

TopicsList.defaultProps = {
    onUpdate: () => {}
};

export default TopicsList;
