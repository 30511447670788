import React from "react";
import { Button as AntDesignButton } from "antd";
import { Link } from "react-router-dom";

const Button = ({ to, ...props }) => {
  const renderContent = () => {
    return !!to
        ? <Link to={to}>{props.children}</Link>
        : props.children;
  };

  return (
    <AntDesignButton {...props}>
      {renderContent()}
    </AntDesignButton>
  );
};

Button.defaultProps = {
  onClick: () => {}
};

export default Button;
