import React, {useContext, useEffect, useState} from 'react';
import { Table } from 'antd';
import { arrayMoveImmutable } from 'array-move';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';
import { Checkbox } from 'antd';
import MediaService from "../../../../services/MediaService";
import { I18nContext } from 'react-i18next';

const Service = new MediaService();

const SortableItem = SortableElement((props) => {return (<tr {...props} />) });
const SortableBody = SortableContainer((props) => <tbody {...props} />);

const CustomMediaColumns = (props) => {
    const { i18n } = useContext(I18nContext);

    const [dataSource, setDataSource] = useState([]);

    const DragHandle = SortableHandle(() => (
        <MenuOutlined
            style={{
                cursor: 'grab',
                color: '#999',
            }}
        />
    ));

    const columns = [
        {
            title: 'Ordenar',
            dataIndex: 'sort',
            width: 30,
            className: 'drag-visible',
            render: () => <DragHandle />,
        },
        {
            title: 'Columna',
            dataIndex: 'name',
            className: 'drag-visible',
        },
        {
            title: 'Visible',
            dataIndex: 'visible',
            render: (_, record) => (
                <Checkbox
                    checked={record.visible}
                    onChange={() => {
                        const newData = dataSource.map((item) =>
                            item.key === record.key ? { ...item, visible: !item.visible } : item
                        );
                        saveData(newData);
                    }}
                />
            ),
        },
    ];

    useEffect(() => {
        // Intenta obtener el contenido de la tabla desde localStorage
        const columns = props.columns;

        setDataSource(columns);


    }, [i18n, props.columns]);

    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) {
            const newData = arrayMoveImmutable(dataSource.slice(), oldIndex, newIndex).filter(
                (el) => !!el
            );

            saveData(newData);
        }
    };

    const saveData = (newData) => {
        const sortedNewData = newData.map((c, idx) => {
            c.weight = idx;
            return c;
        });

        setDataSource(sortedNewData);
        // Guarda el contenido de la tabla en localStorage
        Service.saveListColumns(sortedNewData);

        props.onColumnsUpdate();
        //localStorage.setItem('mediaColumnsData', JSON.stringify(newData));
    };

    const DraggableContainer = (props) => (
        <SortableBody
            useDragHandle
            disableAutoscroll
            updateBeforeSortStart={(node) => { node.node.style.opacity = 0.5; node.node.style.zIndex=9999; }}
            helperClass="draggable-item"
            onSortEnd={onSortEnd}
            {...props}
        />
    );

    const DraggableBodyRow = ({ className, style, ...restProps }) => {
        // function findIndex base on Table rowKey props and should always be a right array index
        const index = dataSource.findIndex((x) => x.index === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    };
    return (
        <Table
            pagination={false}
            dataSource={dataSource}
            columns={columns}
            rowKey="index"
            components={{
                body: {
                    wrapper: DraggableContainer,
                    row: DraggableBodyRow,
                },
            }}
        />
    );
};

export default CustomMediaColumns;
