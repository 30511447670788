import React, { useContext } from 'react';
import { Col, Row } from 'antd';
import { I18nContext } from 'react-i18next';
import FiltersBar from '../../../FiltersBar/FiltersBar';
import { Form, Formik } from 'formik';
import Button from '../../../Button/Button';
import MoreFiltersToggle from '../../../MoreFiltersToggle/MoreFiltersToggle';
import RangePickerField from '../../../Form/Fields/RangePickerField';
import ClientSelectField from '../../client/ClientSelectField/ClientSelectField';
import dayjs from "dayjs";

const ClientBudgetFilters = props => {
    const {i18n} = useContext(I18nContext);
    const hasActiveFilters = !!props.filters && Object.keys(props.filters).length;

    return (
        <FiltersBar showWarning={!!props.filters && Object.keys(props.filters).length}
                    onReset={() => props.onFilter({})}>
            <Formik enableReinitialize
                    initialValues={{
                        budget_date: props.filters?.budget_date ?? dayjs().format("YYYY-MM-DD"),
                        client: props.filters?.client || {},
                        monthly_budget: props.filters?.monthly_budget || {},
                    }}
                    onSubmit={(values, {setSubmitting}) => {
                        props.onFilter(values);
                        setSubmitting(false);
                    }}>
                {props => (
                    <Form>
                        <Row gutter={15}>
                            <Col span={2}>
                                <Button type="primary"
                                        size="large"
                                        htmlType="submit"
                                        block
                                        loading={props.isSubmitting}
                                        disabled={props.isSubmitting || !props.isValid}
                                        onClick={props.handleSubmit}>
                                    {i18n.t('global.filter')}
                                </Button>
                            </Col>
                        </Row>
                        <MoreFiltersToggle open={hasActiveFilters}>
                            <Row gutter={15}>
                                <Col span={4}>
                                    <ClientSelectField label={i18n.t('entity.client.title')}
                                                       name="client"
                                                       allowClear
                                                       placeholder={i18n.t('entity.client.title')}
                                                       onChange={value => props.setFieldValue('client', value)}
                                    />
                                </Col>
                                <Col span={6}>
                                    <RangePickerField label={i18n.t('global.created_between')}
                                                      name="budget_date"
                                                      onChange={value => props.setFieldValue('budget_date', value)}
                                    />
                                </Col>
                            </Row>
                        </MoreFiltersToggle>
                    </Form>
                )}
            </Formik>
        </FiltersBar>
    );
}

ClientBudgetFilters.defaultProps = {
    onFilter: () => {}
};

export default ClientBudgetFilters;
