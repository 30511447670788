import React, { useContext } from 'react';
import { Space, Switch, Table, Tooltip } from 'antd';
import { I18nContext } from 'react-i18next';
import { AppContext } from '../../../../context/AppProvider';
import ConfirmButton from '../../../ConfirmButton/ConfirmButton';
import EditButton from '../../../EditButton/EditButton';
import NumberFormat from '../../../NumberFormat/NumberFormat';
import dayjs from 'dayjs';
import { notify } from '../../../../modules/notify/notify';
import { ExclamationCircleFilled, WarningFilled } from '@ant-design/icons';

const PacksList = props => {
    const {i18n} = useContext(I18nContext);
    const appContext = useContext(AppContext);

    const updateActive = (idPack, active) => {
        appContext.services.pack
            .toggleActive(idPack, active)
            .then(response => {
                notify.success({description: i18n.t('global.item_updated')});
                props.onUpdate();
            });
    };

    const removePack = idPack => {
        appContext.services.pack
            .remove(idPack)
            .then(response => {
                notify.success({description: i18n.t('global.item_updated')});
                props.onUpdate();
            });
    };

    const getColumns = i18n => {
        return [
            {
                title: i18n.t('entity.pack.property.expiration_date'),
                dataIndex: 'expirationDate',
                key: 'expirationDate',
                render: (date, row) => {
                    const now = dayjs();
                    const expirationDate = dayjs.unix(row.expirationDate);
                    const difference = now.diff(expirationDate, 'day');

                    const icon = difference >= 0
                        ? <Tooltip title={i18n.t('component.packs_list.message.is_expired')}>
                            <ExclamationCircleFilled style={{color: '#f60334' }} />
                        </Tooltip>
                        : difference >= -7
                            ? <Tooltip title={i18n.t('component.packs_list.message.will_expire_soon')}>
                                <WarningFilled style={{color: '#ffd500' }} />
                            </Tooltip>
                            : '';

                    return (
                        <Space>
                            {dayjs.unix(date).format('DD/MM/YYYY')}
                            {icon}
                        </Space>
                    );
                }
            },
            {
                title: i18n.t('entity.pack.property.name'),
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: i18n.t('entity.pack.property.price'),
                dataIndex: 'price',
                key: 'price',
                render: price => <NumberFormat value={price} />
            },
            {
                title: i18n.t('entity.pack.property.total_units'),
                dataIndex: 'totalUnits',
                key: 'totalUnits'
            },
            {
                title: i18n.t('entity.pack.property.pending_units'),
                dataIndex: 'pendingUnits',
                key: 'pendingUnis'
            },
            {
                title: i18n.t('global.registration_date'),
                dataIndex: 'tmsCreated',
                key: 'tmsCreated',
                render: date => dayjs.unix(date).format('DD/MM/YYYY')
            },
            {
                title: i18n.t('global.active'),
                dataIndex: 'active',
                key: 'active',
                render: (active, row) => (
                    <Switch defaultChecked={active}
                            disabled={props.readOnly}
                            onChange={active => updateActive(row.idPack, active)}
                    />
                )
            },
            {
                title: '',
                dataIndex: 'actions',
                key: 'actions',
                align: 'right',
                render: (column, row) => (
                    <Space>
                        {!props.readOnly &&
                        <ConfirmButton disabled={row.transactions?.length}
                                       onConfirm={event => removePack(row.idPack)}
                        />}
                        <EditButton to={`/packs/${row.idPack}`} />
                    </Space>
                )
            },
        ];
    };

    return (
        <Table columns={getColumns(i18n)}
               dataSource={props.data}
               pagination={false}
               rowKey={row => row.idPack}
        />
    );
};

PacksList.defaultProps = {
    onUpdate: () => {}
};

export default PacksList;
