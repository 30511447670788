import React, {useContext, useEffect, useState} from 'react';
import {Space, Switch, Table} from 'antd';
import {I18nContext} from 'react-i18next';
import LinkTypeTag from '../../../LinkTypeTag/LinkTypeTag';
import {AppContext} from '../../../../context/AppProvider';
import ConfirmButton from '../../../ConfirmButton/ConfirmButton';
import EditButton from '../../../EditButton/EditButton';
import getProvinceName from '../../../../modules/get-province/get-province-name';
import getCountryName from '../../../../modules/get-country/get-country-name';
import {notify} from '../../../../modules/notify/notify';
import dayjs from 'dayjs';
import CreateTransactionButton from '../../transaction/CreateTransactionButton/CreateTransactionButton';
import {BarsOutlined} from "@ant-design/icons";

const MediaList = props => {
    const columns = props?.columns ?? []
    const useDefaultColumns = props?.useDefaultColumns ?? false;
    const {i18n} = useContext(I18nContext);
    const appContext = useContext(AppContext);
    const [customColumns, setCustomColumns] = useState(columns);
    const [dataSource, setDataSource] = useState(props.data);
    const [loading, setLoading] = useState(true);

    useEffect( () => {

        const mediaData = props?.data ?? [];
        const parsedData = mediaData.map(row => {
            row.provinceName = getProvinceName(row.province, row.country);
            row.countryName = getCountryName(row.country);
            //console.log(row);
            return row;
        });

        setDataSource(parsedData);

        const removeMedia = idMedia => {
            appContext.services.media
                .remove(idMedia)
                .then(response => {
                    notify.success({description: i18n.t('global.item_updated')});
                    props.onUpdate();
                });
        };

        const updateActive = (idMedia, active) => {
            appContext.services.media
                .toggleActive(idMedia, active)
                .then(response => {
                    notify.success({description: i18n.t('global.item_updated')});
                    props.onUpdate();
                });
        };

        const showCustomizeColumns = () => {
            props.toggleCustomColumnsSidebarVisibility(true);
        };

        const columns = [
            {
                name: i18n.t('entity.media.property.name'),
                title: i18n.t('entity.media.property.name'),
                dataIndex: 'name',
                render: (column, row) => (
                    <Space>
                        {row.name}
                    </Space>
                ),
                key: 'name'
            },
            {
                name: i18n.t('entity.media.property.domain'),
                title: i18n.t('entity.media.property.domain'),
                dataIndex: 'domain',
                key: 'domain'
            },
            {
                name: i18n.t('entity.media.property.media_type'),
                title: i18n.t('entity.media.property.media_type'),
                dataIndex: 'type',
                key: 'type',
                render: type => type && type.name
            },
            {
                title: i18n.t('entity.media.property.link_type_plural'),
                dataIndex: 'linkType',
                key: 'linkType',
                render: linkTypes => (
                    linkTypes?.length
                        ? linkTypes.map(linkType => (
                            <LinkTypeTag key={linkType.idLinkType}
                                         linkType={linkType}
                            />
                        ))
                        : ''
                )
            },
            {
                title: i18n.t('entity.media.property.country'),
                dataIndex: 'country',
                key: 'country',
                render: (text, record, idx) => {
                    return (<span>{record.countryName}</span>);
                }
                //render: (idCountry, row) => row.countryName,
            },
            {
                title: i18n.t('entity.media.property.pvp_price'),
                visible: true,
                dataIndex: 'price',
                key: 'pvp_price',
                render: (column, row) => {
                    if (row.price && row.price.length > 0) {
                        const pvpPrice = row?.price[0]?.pvpPrice ?? 0.00;
                        return <span>{pvpPrice} €</span>;
                    }
                    return null;
                }
            },
            {
                title: i18n.t('entity.media.property.media_price'),
                visible: true,
                dataIndex: 'price',
                key: 'media_price',
                render: (column, row) => {

                    if (row.price && row.price.length > 0) {
                        const mediaPrice = row?.price[0]?.mediaPrice ?? 0.00;
                        return <span>{mediaPrice} €</span>;
                    }
                    return null;
                }
            },
            {
                title: i18n.t('entity.media.property.managed_price'),
                visible: true,
                dataIndex: 'price',
                key: 'managed_price',
                render: (column, row) => {

                    if (row.price && row.price.length > 0) {
                        const managedPrice = row?.price[0]?.managedPrice ?? 0.00;
                        return <span>{managedPrice} €</span>;
                    }
                    return null;
                }
            },
            {
                title: i18n.t('entity.media.property.province'),
                dataIndex: 'provinceName',
                key: 'province',
                render: (idProvince, row) => row.provinceName,
            },
            {
                title: i18n.t('global.registration_date'),
                dataIndex: 'tmsCreated',
                key: 'tmsCreated',
                render: date => dayjs.unix(date).format('DD/MM/YYYY')
            },
            {
                title: i18n.t('entity.provider.title'),
                dataIndex: 'provider',
                key: 'provider',
                render: provider => {
                    if (!provider) return (<p></p>);
                    console.log('provider ees: ', provider);
                    return (<p><span>{provider.name}</span><br/><span>{provider.email}</span></p>);
                },
            },
            {
                title: i18n.t('page.topics.title'),
                dataIndex: 'topics',
                key: 'topics',
                render: (topics) => {
                    if (topics ?? [].length > 0) {
                        const topicsData = (topics ?? []).map(topic => topic.name).join(', ');
                        return <span>{topicsData}</span>;
                    }
                    return null;
                },
            },
            {
                title: i18n.t('global.active'),
                dataIndex: 'active',
                key: 'active',
                render: (active, row) => (
                    <Switch defaultChecked={active}
                            disabled={props.readOnly}
                            onChange={active => updateActive(row.idMedia, active)}
                    />
                )
            },
        ];

        let result = columns.sort((a, b) => a.weight - b.weight);

        if ( !useDefaultColumns ) {
            result = result.map((column, idx) => {


                if (column.key === 'actions') {
                    column.weight = columns.length + 1;
                    column.visible = true;
                    return column;
                }

                const customColumnIndex = props.columns.findIndex((cc) => cc.key === column.key);

                if (customColumnIndex !== -1) {
                    column.visible = props.columns[customColumnIndex].visible;
                    column.weight = props.columns[customColumnIndex].weight;
                    column.exportField = props.columns[customColumnIndex].exportField;
                }


                return column;
            }).filter(column => { return column.visible });
        }

        result.push({
            title: <BarsOutlined onClick={() => showCustomizeColumns()}/>,
            dataIndex: 'actions',
            key: 'actions',
            align: 'right',
            visible: true,
            weight: result.length + 1,
            render: (column, row) => (
                <Space>
                    {!props.readOnly &&
                    <ConfirmButton disabled={row.transactions?.length}
                                   onConfirm={event => removeMedia(row.idMedia)}
                    />}
                    <CreateTransactionButton media={row} />
                    <EditButton to={`/media/${row.idMedia}`} />
                </Space>
            )
        });

        setCustomColumns(result);
        setLoading(false);

    }, [appContext.services.media, i18n, props, props.columns, props.readOnly, useDefaultColumns])


    return (
        <Table loading={loading} columns={customColumns}
               dataSource={dataSource}
               pagination={false}
               rowKey={row => row.idMedia}
               expandable={{
                   expandedRowRender: row => <div>{row.comments}</div>,
                   rowExpandable: row => 'comments' in row && row.comments,
               }}
        />
    );
};

MediaList.defaultProps = {
    onUpdate: () => {},
    columns: [],
};

export default MediaList;
