import BaseService from './BaseService';
import convertToSelectOptions from '../modules/convert-to-select-options/convert-to-select-options';

export default class PriceTypeService extends BaseService {
    entityEndpoint = 'price-type';

    findList(convert = true) {
        const endpoint = `${this.entityEndpoint}/list`;

        return this
            .fetch(`${this.API_URL}${endpoint}`, {method: 'GET'})
            .then(response => convert ? convertToSelectOptions(response, 'idPriceType', 'description') : response);
    }

    findAll(page = 1) {
        return super.findAll.call(this, this.entityEndpoint, page);
    }

    findById(idPriceType) {
        return super.findById.call(this, this.entityEndpoint, idPriceType);
    }

    remove(idPriceType) {
        return super.remove.call(this, this.entityEndpoint, idPriceType);
    }

    create(values) {
        return super.create.call(this, this.entityEndpoint, values);
    }

    update(idPriceType, values) {
        return super.update.call(this, this.entityEndpoint, idPriceType, values);
    }
}
