import { notification } from 'antd';
import i18n from '../../i18n/i18n';

const abstractNotification = ({message, description, type, placement = 'topRight'}) => {
    notification[type]({
        message,
        description,
        placement
    });
};

export const notify = {
    success: ({...args}) => abstractNotification({
        type: 'success',
        message: args.message || i18n.t('notification.success.title'),
        ...args
    }),
    info: ({...args}) => abstractNotification({
        type: 'info',
        message: args.message || i18n.t('notification.info.title'),
        ...args
    }),
    warning: ({...args}) => abstractNotification({
        type: 'warning',
        message: args.message || i18n.t('notification.warning.title'),
        ...args
    }),
    error: ({...args}) => abstractNotification({
        type: 'error',
        message: args.message || i18n.t('notification.error.title'),
        ...args
    })
};
