import React, { Fragment, useContext, useEffect, useState } from 'react';
import { withLayout } from '../../components/HOC/WithLayout';
import Portal from '../../components/Portal/Portal';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { I18nContext } from 'react-i18next';
import { AppContext } from '../../context/AppProvider';
import { useParams } from 'react-router-dom';
import { Col, PageHeader, Row, Switch, Typography } from 'antd';
import PackForm from '../../components/CRUD/pack/PackForm/PackForm';
import MediaListMini from '../../components/CRUD/media/MediaList/MediaListMini';
import Skeleton from '../../components/Skeleton/Skeleton';
import FieldWrapper from '../../components/Form/Fields/FieldWrapper';
import { notify } from '../../modules/notify/notify';
import './pack-single-page.scss';
import TransactionsList from '../../components/CRUD/transaction/TransactionsList/TransactionsList';

const PackSinglePage = props => {
    const {i18n} = useContext(I18nContext);
    const appContext = useContext(AppContext);
    const [pack, setPack] = useState({});
    const { id: idPack } = useParams();
    const [loading, setLoading] = useState(true);

    const findById = () => {
        setLoading(true);
        appContext.services.pack
            .findById(idPack)
            .then(pack => setPack(pack))
            .catch(console.error)
            .finally(() => setLoading(false));
    };

    const updatePack = values => {
        appContext.services.pack
            .update(idPack, values)
            .then(response => {
                if (!response.success) return;
                notify.success({description: i18n.t('global.item_updated')});
                findById();
            })
            .catch(error => {
                notify.error({description: i18n.t('global.request_error')});
                console.error(error);
            });
    };

    const addMedia = idMedia => {
        appContext.services.pack
            .addMedia(idPack, idMedia)
            .then(response => {
                if (!response.success) return;
                notify.success({description: i18n.t('global.item_updated')});
                findById();
            })
            .catch(console.error);
    };

    const removeMedia = idMedia => {
        appContext.services.pack
            .removeMedia(idPack, idMedia)
            .then(response => {
                if (!response.success) return;
                notify.success({description: i18n.t('global.item_updated')});
                findById();
            })
            .catch(console.error);
    };

    const updateActive = (idPack, active) => {
        appContext.services.pack
            .toggleActive(idPack, active)
            .then(response => {
                notify.success({description: i18n.t('global.item_updated')});
                findById();
            });
    };

    useEffect(findById, []);

    return (
        <Fragment>
            <div className="pack-single-page">
                <Row gutter={[15, 30]}>
                    <Col xs={24}>
                        <Typography.Title level={3}>{i18n.t('global.basic_info')}</Typography.Title>
                        <Skeleton loading={loading}>
                            <PackForm pack={pack}
                                      actionsPortalTarget="form-actions"
                                      onSubmit={updatePack}
                            />
                        </Skeleton>
                    </Col>
                    <Col xs={24}>
                        <Typography.Title level={3}>{i18n.t('entity.media.title_plural')}</Typography.Title>
                        <Skeleton loading={loading}>
                            <MediaListMini data={pack.media}
                                           idProvider={pack?.provider?.idProvider}
                                           onRemove={removeMedia}
                                           onAdd={addMedia}
                            />
                        </Skeleton>
                    </Col>
                    <Col xs={24}>
                        <Typography.Title level={3}>{i18n.t('entity.transaction.title', {count: pack.transactions?.length})}</Typography.Title>
                        <Skeleton loading={loading}>
                            <TransactionsList data={pack.transactions} readOnly />
                        </Skeleton>
                    </Col>
                </Row>
            </div>

            <Portal.In target="page-header">
                <PageHeader title={idPack}
                            extra={[
                                <FieldWrapper key="1"
                                              label={i18n.t('global.active')}>
                                    <Skeleton loading={loading}
                                              paragraph={{rows: 1, width: 50}}
                                              title={false}>
                                        <Switch checked={pack.active}
                                                onChange={active => updateActive(pack.idPack, active)}
                                        />
                                    </Skeleton>
                                </FieldWrapper>
                            ]}
                />
            </Portal.In>

            <Portal.In target="page-breadcrumbs">
                <Breadcrumbs breadcrumbs={[
                    {path: '/packs', name: i18n.t('page.packs.menu')},
                    {path: `/packs/${idPack}`, name: idPack}
                ]} />
            </Portal.In>
        </Fragment>
    );
};

export default withLayout(PackSinglePage);
