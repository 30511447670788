import React, { Fragment, useContext, useState } from 'react';
import { Space, Table } from 'antd';
import { I18nContext } from 'react-i18next';
import { AppContext } from '../../../../context/AppProvider';
import ConfirmButton from '../../../ConfirmButton/ConfirmButton';
import EditButton from '../../../EditButton/EditButton';

import { notify } from '../../../../modules/notify/notify';
import dayjs from 'dayjs';
import Sidebar from "../../../Sidebar/Sidebar";
import ClientBudgetForm from "../ClientBudgetForm/ClientBudgetForm";

const ClientBudgetList = props => {
    const {i18n} = useContext(I18nContext);
    const appContext = useContext(AppContext);
    const [currentClientBudget, setCurrentClientBudget] = useState({});
    const [editSidebarIsVisible, setEditSidebarIsVisible] = useState(false);


    const removeClientBudget = (idClientBudget) => {
        appContext.services.clientsBudgets
            .remove(idClientBudget)
            .then(response => {
                notify.success({description: i18n.t('global.item_updated')});
                props.onUpdate();
            });
    };

    const updateClientBudget = (values) => {

        appContext.services.clientsBudgets
            .update(currentClientBudget.id, values)
            .then(response => {
                if (!response.success) return;
                setEditSidebarIsVisible(false);
                notify.success({description: i18n.t('global.item_updated')});
                props.onUpdate();
            })
            .catch(console.error);
    };

    const getColumns = i18n => {
        return [
            {
                title: i18n.t('entity.clientBudget.property.client'),
                dataIndex: 'client',
                key: 'client',
                render: (client, row) => (
                    <Fragment>
                        {row?.client?.name &&
                        <div>
                            <small>{i18n.t('entity.client.title')}</small>
                            &nbsp;
                            {row.client.name}
                        </div>}
                    </Fragment>
                )
            },
            {
                title: i18n.t('entity.clientBudget.property.budget_date'),
                dataIndex: 'budget_date',
                key: 'budget_date',
                render: date => {
                    return dayjs.unix(date).format('YY-MM')
                }
            },
            {
                title: i18n.t('entity.clientBudget.property.monthly_budget'),
                dataIndex: 'monthly_budget',
                key: 'monthly_budget',
            },
            {
                title: '',
                dataIndex: 'actions',
                key: 'actions',
                align: 'right',
                render: (column, row) => {
                    return (<Space>
                        {!props.readOnly &&
                        <ConfirmButton disabled={row?.length}
                                       onConfirm={event => removeClientBudget(row.id)}
                        />}
                        <EditButton onClick={event => {
                            setCurrentClientBudget(row);
                            setEditSidebarIsVisible(true);
                        }} />
                    </Space>)
                }
            },
        ];
    };

    return (
        <Fragment>
            <Table columns={getColumns(i18n)}
                   dataSource={props.data}
                   pagination={false}
                   rowKey={row => row.id}
            />
            <Sidebar visible={editSidebarIsVisible}
                     onClose={event => setEditSidebarIsVisible(false)}>
                <ClientBudgetForm clientBudget={currentClientBudget}
                               onSubmit={updateClientBudget}
                />
            </Sidebar>
        </Fragment>

    );
};

ClientBudgetList.defaultProps = {
    onUpdate: () => {}
};

export default ClientBudgetList;
