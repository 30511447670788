import React from 'react';
import { EditOutlined } from '@ant-design/icons';
import Button from '../Button/Button';

const EditButton = props => {
    return (
        <Button {...props}
                shape="circle"
                onClick={props.onClick}
        >
            <EditOutlined />
        </Button>
    );
};

EditButton.defaultProps = {
    onClick: () => {}
};

export default EditButton;
