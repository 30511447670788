import React, { Fragment, useContext, useState } from 'react';
import Button from '../../../Button/Button';
import { ShoppingCartOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { I18nContext } from 'react-i18next';
import { notify } from '../../../../modules/notify/notify';
import { AppContext } from '../../../../context/AppProvider';
import TransactionForm from '../TransactionForm/TransactionForm';
import Sidebar from '../../../Sidebar/Sidebar';
import { ACTION, PACK_ACTION } from '../../constants';

const CreateTransactionButton = props => {
    const {i18n} = useContext(I18nContext);
    const appContext = useContext(AppContext);
    const [createSidebarIsVisible, setCreateSidebarIsVisible] = useState(false);
    const [currentTransaction, setCurrentTransaction] = useState({});

    const createTransaction = values => {
        appContext.services.transaction
            .create(values, PACK_ACTION.DEDUCT)
            .then(response => {
                if (!response.success) return;
                notify.success({description: i18n.t('global.item_updated')});
                setCreateSidebarIsVisible(false);
                props.onCreate();
            })
            .catch(console.error);
    };

    return (
        <Fragment>
            <Tooltip title={i18n.t('entity.transaction.create_title')}>
                <div>
                    <Button icon={<ShoppingCartOutlined />}
                            shape="circle"
                            onClick={event => {
                                setCurrentTransaction({
                                    media: props.media
                                });
                                setCreateSidebarIsVisible(true);
                            }}
                    />
                </div>
            </Tooltip>

            <Sidebar visible={createSidebarIsVisible}
                     title={i18n.t('entity.transaction.create_title')}
                     onClose={event => setCreateSidebarIsVisible(false)}>
                <TransactionForm transaction={currentTransaction}
                                 action={ACTION.CREATE}
                                 onSubmit={createTransaction}
                />
            </Sidebar>
        </Fragment>
    );
};

CreateTransactionButton.defaultProps = {
    onCreate: () => {}
};

export default CreateTransactionButton;
