import React, { useContext } from 'react';
import { Prompt } from 'react-router-dom';
import { I18nContext } from 'react-i18next';

const UnsavedPrompt = props => {
    const {i18n} = useContext(I18nContext);

    return (
        <Prompt when={props.when}
                message={props.message || i18n.t('component.unsaved_prompt.message.default')}
        />
    );
}

UnsavedPrompt.defaultProps = {
    when: true
};

export default UnsavedPrompt;
