import dayjs from 'dayjs';

const packPostProcessor = pack => {
    return {
        ...pack,
        expirationDate: dayjs.unix(pack.expirationDate).format('DD-MM-YYYY')
    }
};

export default packPostProcessor;
