import BaseService from './BaseService';
import convertToSelectOptions from '../modules/convert-to-select-options/convert-to-select-options';
import packPostProcessor from '../modules/post-processor/pack-post-processor';

export default class PackService extends BaseService {
    entityEndpoint = 'pack';

    findList(active = true) {
        const endpoint = `${this.entityEndpoint}/list`;

        return this
            .fetch(`${this.API_URL}${endpoint}`, {method: 'GET'}, {active})
            .then(response => convertToSelectOptions(response, 'idPack', 'name'));
    }

    search(page = 1, filters = {}, list = undefined) {
        const adaptedFilters = {
            ...filters,
            price: filters?.price ? filters.price.join(',') : undefined,
            totalUnits: filters?.totalUnits ? filters.totalUnits.join(',') : undefined,
            pendingUnits: filters?.pendingUnits ? filters.pendingUnits.join(',') : undefined,
            creationRange: filters?.creationRange ? filters.creationRange.join(',') : undefined,
            expirationRange: filters?.expirationRange ? filters.expirationRange.join(',') : undefined,
            provider: filters?.provider ? filters.provider.value : undefined
        };
        return super.search.call(this, this.entityEndpoint, page, adaptedFilters, list);
    }

    query(query, hideEmpty) {
        const endpoint = `${this.entityEndpoint}/query`;

        return this
            .fetch(`${this.API_URL}${endpoint}`, {method: 'GET'}, query)
            .then(response => response ? convertToSelectOptions(response, 'idPack', 'name') : []);
    }

    findAll(page = 1) {
        return super.findAll.call(this, this.entityEndpoint, page);
    }

    findById(idPack) {
        return super.findById.call(this, this.entityEndpoint, idPack);
    }

    remove(idPack) {
        return super.remove.call(this, this.entityEndpoint, idPack);
    }

    toggleActive(idPack, active) {
        return super.toggleActive.call(this, this.entityEndpoint, idPack, active);
    }

    create(values) {
        return super.create.call(this, this.entityEndpoint, packPostProcessor(values));
    }

    update(idPack, values) {
        return super.update.call(this, this.entityEndpoint, idPack, packPostProcessor(values));
    }

    addMedia(idPack, idMedia) {
        const endpoint = `${this.entityEndpoint}/${idPack}/add-media/${idMedia}`;

        return this
            .fetch(`${this.API_URL}${endpoint}`, {method: 'PUT'})
            .then(response => response);
    }

    removeMedia(idPack, idMedia) {
        const endpoint = `${this.entityEndpoint}/${idPack}/remove-media/${idMedia}`;

        return this
            .fetch(`${this.API_URL}${endpoint}`, {method: 'PUT'})
            .then(response => response);
    }
}
