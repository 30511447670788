import { Parser } from 'json2csv';
import { saveAs } from 'file-saver';

const json2CSVDownload = (fields = [], rows = [], name = 'list') => {
    const opts = { fields };

    try {
        const parser = new Parser(opts);
        const csv = parser.parse(rows);
        const blob = new Blob([csv], {type: "text/plain;charset=utf-8"});
        saveAs(blob, `${name}_${Date.now()}.csv`);
    } catch (err) {
        console.error(err);
    }
};

export default json2CSVDownload;
