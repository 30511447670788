import React, { Fragment, useContext } from 'react';
import { Space, Switch, Table } from 'antd';
import { I18nContext } from 'react-i18next';
import { AppContext } from '../../../../context/AppProvider';
import ConfirmButton from '../../../ConfirmButton/ConfirmButton';
import EditButton from '../../../EditButton/EditButton';
import NumberFormat from '../../../NumberFormat/NumberFormat';
import StatusTag from '../../../StatusTag/StatusTag';
import { notify } from '../../../../modules/notify/notify';
import dayjs from 'dayjs';
import { PACK_ACTION } from '../../constants';

const TransactionsList = props => {
    const {i18n} = useContext(I18nContext);
    const appContext = useContext(AppContext);

    const updateActive = (idTransaction, active) => {
        appContext.services.transaction
            .toggleActive(idTransaction, active)
            .then(response => {
                notify.success({description: i18n.t('global.item_updated')});
                props.onUpdate();
            });
    };

    const removeTransaction = (idTransaction, packAction) => {
        appContext.services.transaction
            .remove(idTransaction, packAction)
            .then(response => {
                notify.success({description: i18n.t('global.item_updated')});
                props.onUpdate();
            });
    };

    const getColumns = i18n => {
        return [
            {
                title: i18n.t('global.url'),
                dataIndex: 'urlOrigin',
                key: 'urlOrigin',
                render: (urlOrigin, row) => (
                    <Fragment>
                        {row?.client?.name &&
                        <div>
                            <small>{i18n.t('entity.client.title')}</small>
                            &nbsp;
                            {row.client.name}
                        </div>}
                        <div>
                            <small>{i18n.t('entity.transaction.property.url_origin')}</small>
                            &nbsp;
                            <a href={row.urlOrigin} target="_blank" rel="noopener noreferrer">{row.urlOrigin}</a>
                        </div>
                        <div>
                            <small>{i18n.t('entity.transaction.property.url_destination')}</small>
                            &nbsp;
                            <a href={row.urlDestination} target="_blank" rel="noopener noreferrer">{row.urlDestination}</a>
                        </div>
                        <div>
                            <small>{i18n.t('entity.transaction.property.anchor')}</small>
                            &nbsp;
                            {row.anchor}
                        </div>
                    </Fragment>
                )
            },
            {
                title: i18n.t('global.prices'),
                dataIndex: 'price',
                key: 'price',
                render: (column, row) => (
                    <Fragment>
                        <div>
                            <small>{i18n.t('entity.price.property.media_price')}</small>
                            &nbsp;
                            <NumberFormat value={row.mediaPrice} />
                        </div>
                        <div>
                            <small>{i18n.t('entity.price.property.pvp_price')}</small>
                            &nbsp;
                            <NumberFormat value={row.pvpPrice} />
                        </div>
                        <div>
                            <small>{i18n.t('entity.price.property.managed_price')}</small>
                            &nbsp;
                            <NumberFormat value={row.managedPrice} /></div>
                        <div>
                            <small>{i18n.t('entity.price.property.type')}</small>
                            &nbsp;
                            {row.priceType}
                        </div>
                    </Fragment>
                )
            },
            {
                title: i18n.t('entity.transaction.property.status'),
                dataIndex: 'status',
                key: 'status',
                render: status => status && <StatusTag status={status} />
            },
            {
                title: i18n.t('entity.transaction.property.shared'),
                dataIndex: 'shared',
                key: 'shared',
                render: isShared => !!isShared ? i18n.t('global.yes') : i18n.t('global.no')
            },
            {
                title: i18n.t('global.registration_date'),
                dataIndex: 'tmsCreated',
                key: 'tmsCreated',
                render: date => dayjs.unix(date).format('DD/MM/YYYY')
            },
            {
                title: i18n.t('global.publication_date'),
                dataIndex: 'publicationDate',
                key: 'publicationDate',
                render: date => date && dayjs.unix(date).format('DD/MM/YYYY')
            },
            {
                title: i18n.t('global.active'),
                dataIndex: 'active',
                key: 'active',
                render: (active, row) => (
                    <Switch defaultChecked={active}
                            disabled={props.readOnly}
                            onChange={active => updateActive(row.idTransaction, active)}
                    />
                )
            },
            {
                title: '',
                dataIndex: 'actions',
                key: 'actions',
                align: 'right',
                render: (column, row) => {
                    const packAction = row.status?.isPending ? PACK_ACTION.ADD : null;
                    return (
                        <Space>
                            {!props.readOnly &&
                            <ConfirmButton
                                           onConfirm={event => removeTransaction(row.idTransaction, packAction)} />}
                            <EditButton to={`/transactions/${row.idTransaction}`} />
                        </Space>
                    );
                }
            },
        ];
    };

    return (
        <Table columns={getColumns(i18n)}
               dataSource={props.data}
               pagination={false}
               rowKey={row => row.idTransaction}
               expandable={{
                   expandedRowRender: row => <div>{row.comments}</div>,
                   rowExpandable: row => 'comments' in row && row.comments,
               }}
        />
    );
};

TransactionsList.defaultProps = {
    onUpdate: () => {}
};

export default TransactionsList;
