import React from 'react';
import FieldWrapper from './FieldWrapper';
import { useField } from 'formik';
import DatePicker from '../../DatePicker/DatePicker';
import locale from 'antd/es/date-picker/locale/es_ES';
import dayjs from 'dayjs';
import getLanguage from '../../../modules/get-language/get-language';

const RangePickerField = props => {
    const [field, meta] = useField(props);

    const getLocale = () => {
        return getLanguage() === 'es'
            ? locale
            : undefined;
    };

    return (
        <FieldWrapper {...props} meta={meta}>
            <DatePicker.RangePicker {...field}
                                    {...props}
                                    className="input-field__control"
                                    format={[props.format, props.format]}
                                    value={field?.value.length ? [dayjs.unix(field.value[0]), dayjs.unix(field.value[1])] : null}
                                    locale={getLocale()}
                                    allowEmpty={false}
                                    onChange={value => {
                                        const outputValue = value ? [value[0].unix(), value[1].unix()] : [];
                                        props.onChange(outputValue);
                                    }}
            />
        </FieldWrapper>
    );
};

RangePickerField.defaultProps = {
    format: 'DD-MM-YYYY'
};

export default RangePickerField;
