import BaseService from './BaseService';
import convertToSelectOptions from '../modules/convert-to-select-options/convert-to-select-options';

export default class LinkTypeService extends BaseService {
    entityEndpoint = 'link-type';

    findList(convert = true) {
        const endpoint = `${this.entityEndpoint}/list`;

        return this
            .fetch(`${this.API_URL}${endpoint}`, {method: 'GET'})
            .then(response => convert ? convertToSelectOptions(response, 'idLinkType', 'type') : response);
    }

    findAll(page = 1) {
        return super.findAll.call(this, this.entityEndpoint, page);
    }

    findById(idLinkType) {
        return super.findById.call(this, this.entityEndpoint, idLinkType);
    }

    remove(idLinkType) {
        return super.remove.call(this, this.entityEndpoint, idLinkType);
    }

    create(values) {
        return super.create.call(this, this.entityEndpoint, values);
    }

    update(idLinkType, values) {
        return super.update.call(this, this.entityEndpoint, idLinkType, values);
    }
}
