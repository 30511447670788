import SelectField from '../../../Form/Fields/SelectField';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../../context/AppProvider';
import debounce from '../../../../modules/debounce/debounce';

const MediaSelectField = props => {
    const appContext = useContext(AppContext);
    const [media, setMedia] = useState([]);
    const [query, setQuery] = useState({});
    const [fetching, setFetching] = useState(false);

    const search = () => {
        setFetching(true);
        appContext.services.media
            .query(query)
            .then(media => setMedia(media || []))
            .catch(console.error)
            .finally(() => setFetching(false));
    };

    useEffect(search, [query]);

    return (
        <SelectField {...props}
                     options={media}
                     labelInValue={true}
                     loading={fetching}
                     onSearch={debounce(setQuery, 1000)}
        />
    );
};

export default MediaSelectField;
