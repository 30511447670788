import React, { Fragment, useContext, useEffect, useState } from 'react';
import { withLayout } from '../../components/HOC/WithLayout';
import Portal from '../../components/Portal/Portal';
import { Button, PageHeader } from 'antd';
import { I18nContext } from 'react-i18next';
import { AppContext } from '../../context/AppProvider';
import Sidebar from '../../components/Sidebar/Sidebar';
import { PlusOutlined } from '@ant-design/icons';
import PriceTypeList from '../../components/CRUD/priceType/PriceTypeList/PriceTypeList';
import PriceTypeForm from '../../components/CRUD/priceType/PriceTypeForm/PriceTypeForm';
import Skeleton from '../../components/Skeleton/Skeleton';
import { notify } from '../../modules/notify/notify';
import './price-types-list-page.scss';

const PriceTypesListPage = props => {
    const {i18n} = useContext(I18nContext);
    const appContext = useContext(AppContext);
    const [createSidebarIsVisible, setCreateSidebarIsVisible] = useState(false);
    const [priceTypes, setPriceTypes] = useState([]);
    const [loading, setLoading] = useState(true);

    const createPriceType = values => {
        appContext.services.priceType
            .create(values)
            .then(response => {
                if (!response.success) return;
                notify.success({description: i18n.t('global.item_updated')});
                setCreateSidebarIsVisible(false);
                findList();
            })
            .catch(console.error);
    };

    const findList = () => {
        setLoading(true);
        appContext.services.priceType
            .findList(false)
            .then(priceTypes => setPriceTypes(priceTypes))
            .catch(console.error)
            .finally(() => setLoading(false));

    };

    useEffect(() => {
        findList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
            <div className="price-types-list-page">
                <Skeleton loading={loading}>
                    <PriceTypeList data={priceTypes}
                                   onUpdate={findList}
                    />
                </Skeleton>
            </div>

            <Portal.In target="page-header">
                <PageHeader title={i18n.t('page.price_types.title')}
                            extra={[
                                <Button key="1"
                                        type="primary"
                                        icon={<PlusOutlined />}
                                        onClick={event => setCreateSidebarIsVisible(true)}>
                                    {i18n.t('entity.price_type.title')}
                                </Button>
                            ]}
                />
            </Portal.In>

            <Sidebar visible={createSidebarIsVisible}
                     title={i18n.t('entity.price_type.create_title')}
                     onClose={event => setCreateSidebarIsVisible(false)}>
                <PriceTypeForm onSubmit={createPriceType} />
            </Sidebar>
        </Fragment>
    );
};

export default withLayout(PriceTypesListPage);
