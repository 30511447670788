export const spanishProvinces = [
    {label: 'Otra', value: 0},
    {label: "Valencia", value: 46},
    {label: "Alicante", value: 3},
    {label: "Castellón", value: 12},
    {label: "Almería", value: 4},
    {label: "Cádiz", value: 11},
    {label: "Córdoba", value: 14},
    {label: "Granada", value: 18},
    {label: "Huelva", value: 21},
    {label: "Jaén", value: 23},
    {label: "Málaga", value: 29},
    {label: "Sevilla", value: 41},
    {label: "Huesca", value: 22},
    {label: "Teruel", value: 44},
    {label: "Zaragoza", value: 50},
    {label: "Asturias", value: 33},
    {label: "Islas Baleares", value: 7},
    {label: "Las Palmas", value: 35},
    {label: "Santa Cruz de Tenerife", value: 38},
    {label: "Cantabria", value: 39},
    {label: "Ávila", value: 5},
    {label: "Burgos", value: 9},
    {label: "León", value: 24},
    {label: "Palencia", value: 34},
    {label: "Salamanca", value: 37},
    {label: "Segovia", value: 40},
    {label: "Soria", value: 42},
    {label: "Valladolid", value: 47},
    {label: "Zamora", value: 49},
    {label: "Albacete", value: 2},
    {label: "Ciudad Real", value: 13},
    {label: "Cuenca", value: 16},
    {label: "Guadalajara", value: 19},
    {label: "Toledo", value: 45},
    {label: "Barcelona", value: 8},
    {label: "Girona", value: 17},
    {label: "Lleida", value: 25},
    {label: "Tarragona", value: 43},
    {label: "Badajoz", value: 6},
    {label: "Cáceres", value: 10},
    {label: "A Coruña", value: 15},
    {label: "Lugo", value: 27},
    {label: "Ourense", value: 32},
    {label: "Pontevedra", value: 36},
    {label: "Madrid", value: 28},
    {label: "Murcia", value: 30},
    {label: "Navarra", value: 31},
    {label: "Araba/Álava", value: 1},
    {label: "Bizkaia", value: 48},
    {label: "Gipuzkoa", value: 20},
    {label: "La Rioja", value: 26},
    {label: "Ceuta", value: 51},
    {label: "Melilla", value: 52},
];
