import React, { useContext } from 'react';
import { Space, Switch, Table } from 'antd';
import { I18nContext } from 'react-i18next';
import { AppContext } from '../../../../context/AppProvider';
import ConfirmButton from '../../../ConfirmButton/ConfirmButton';
import EditButton from '../../../EditButton/EditButton';
import dayjs from 'dayjs';
import { notify } from '../../../../modules/notify/notify';

const ProvidersList = props => {
    const {i18n} = useContext(I18nContext);
    const appContext = useContext(AppContext);

    const updateActive = (idProvider, active) => {
        appContext.services.provider
            .toggleActive(idProvider, active)
            .then(response => {
                notify.success({description: i18n.t('global.item_updated')});
                props.onUpdate();
            });
    };

    const removeProvider = idProvider => {
        appContext.services.provider
            .remove(idProvider)
            .then(response => {
                notify.success({description: i18n.t('global.item_updated')});
                props.onUpdate();
            });
    };

    const getColumns = i18n => {
        return [
            {
                title: i18n.t('entity.provider.property.name'),
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: i18n.t('entity.provider.property.email'),
                dataIndex: 'email',
                key: 'email',
                render: value => <a href={`mailto:${value}`}>{value}</a>
            },
            {
                title: i18n.t('entity.provider.property.phone'),
                dataIndex: 'phone',
                key: 'phone',
                render: value => <a href={`tel:${value}`}>{value}</a>
            },
            {
                title: i18n.t('entity.provider.property.internal_contact'),
                dataIndex: 'internalContact',
                key: 'internalContact'
            },
            {
                title: i18n.t('global.registration_date'),
                dataIndex: 'tmsCreated',
                key: 'tmsCreated',
                render: date => dayjs.unix(date).format('DD/MM/YYYY')
            },
            {
                title: i18n.t('global.active'),
                dataIndex: 'active',
                key: 'active',
                render: (active, row) => (
                    <Switch defaultChecked={active}
                            onChange={active => updateActive(row.idProvider, active)}
                    />
                )
            },
            {
                title: '',
                dataIndex: 'actions',
                key: 'actions',
                align: 'right',
                render: (column, row) => (
                    <Space>
                        <ConfirmButton onConfirm={event => removeProvider(row.idProvider)} />
                        <EditButton to={`/providers/${row.idProvider}`} />
                    </Space>
                )
            },
        ];
    };

    return (
        <Table columns={getColumns(i18n)}
               dataSource={props.data}
               pagination={false}
               rowKey={row => row.idProvider}
               expandable={{
                   expandedRowRender: row => <div>{row.comments}</div>,
                   rowExpandable: row => 'comments' in row && row.comments,
               }}
        />
    );
};

ProvidersList.defaultProps = {
    onUpdate: () => {}
};

export default ProvidersList;
