import React, { Fragment, useContext, useEffect, useState } from 'react';
import { withLayout } from '../../components/HOC/WithLayout';
import Portal from '../../components/Portal/Portal';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { I18nContext } from 'react-i18next';
import { AppContext } from '../../context/AppProvider';
import { useParams } from 'react-router-dom';
import { PageHeader, Switch, Typography } from 'antd';
import TransactionForm from '../../components/CRUD/transaction/TransactionForm/TransactionForm';
import './transaction-single-page.scss';
import Skeleton from '../../components/Skeleton/Skeleton';
import FieldWrapper from '../../components/Form/Fields/FieldWrapper';
import { notify } from '../../modules/notify/notify';
import { ACTION } from '../../components/CRUD/constants';

const TransactionSinglePage = props => {
    const {i18n} = useContext(I18nContext);
    const appContext = useContext(AppContext);
    const [transaction, setTransaction] = useState({});
    const { id: idTransaction } = useParams();
    const [loading, setLoading] = useState(true);

    const findById = () => {
        console.log('entro', idTransaction);
        setLoading(true);
        appContext.services.transaction
            .findById(idTransaction)
            .then(transaction => setTransaction(transaction))
            .catch(console.error)
            .finally(() => setLoading(false));
    };

    const updateTransaction = values => {
        appContext.services.transaction
            .update(idTransaction, values)
            .then(response => {
                if (!response.success) return;
                notify.success({description: i18n.t('global.item_updated')});
                findById();
            })
            .catch(error => {
                notify.error({description: i18n.t('global.request_error')});
                console.error(error);
            });
    };

    const updateActive = (idTransaction, active) => {
        appContext.services.transaction
            .toggleActive(idTransaction, active)
            .then(response => {
                notify.success({description: i18n.t('global.item_updated')});
                findById();
            });
    };

    useEffect(findById, []);

    return (
        <Fragment>
            <div className="transaction-single-page">
                <Typography.Title level={3}>{i18n.t('global.basic_info')}</Typography.Title>
                <Skeleton loading={loading}>
                    <TransactionForm transaction={transaction}
                                     action={ACTION.UPDATE}
                                     actionsPortalTarget="form-actions"
                                     onSubmit={updateTransaction}
                    />
                </Skeleton>
            </div>

            <Portal.In target="page-header">
                <PageHeader title={idTransaction}
                            extra={[
                                <FieldWrapper key="1"
                                              label={i18n.t('global.active')}>
                                    <Skeleton loading={loading}
                                              paragraph={{rows: 1, width: 50}}
                                              title={false}>
                                        <Switch checked={transaction.active}
                                                onChange={active => updateActive(transaction.idTransaction, active)}
                                        />
                                    </Skeleton>
                                </FieldWrapper>
                            ]}
                />
            </Portal.In>

            <Portal.In target="page-breadcrumbs">
                <Breadcrumbs breadcrumbs={[
                    {path: '/transactions', name: i18n.t('page.transactions.menu')},
                    {path: `/transactions/${idTransaction}`, name: transaction.idTransaction}
                ]} />
            </Portal.In>
        </Fragment>
    );
};

export default withLayout(TransactionSinglePage);
