import React from 'react';
import { Switch } from 'antd';
import FieldWrapper from './FieldWrapper';
import { useField } from 'formik';

const SwitchField = props => {
    const [field, meta] = useField(props);

    return (
        <FieldWrapper {...props} meta={meta}>
            <Switch {...field} {...props}
                    checked={field.value}
            />
        </FieldWrapper>
    );
};
export default SwitchField;
