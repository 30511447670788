import React, { Fragment, useContext, useState } from 'react';
import { Space, Table } from 'antd';
import { I18nContext } from 'react-i18next';
import EditButton from '../../../EditButton/EditButton';
import ConfirmButton from '../../../ConfirmButton/ConfirmButton';
import Button from '../../../Button/Button';
import NumberFormat from '../../../NumberFormat/NumberFormat';
import { PlusOutlined } from '@ant-design/icons';
import Flex from '../../../Flex/Flex';
import Sidebar from '../../../Sidebar/Sidebar';
import PriceForm from '../PriceForm/PriceForm';
import { notify } from '../../../../modules/notify/notify';
import { AppContext } from '../../../../context/AppProvider';
import { ACTION } from '../../constants';

const PricesList = props => {
    const appContext = useContext(AppContext);
    const {i18n} = useContext(I18nContext);
    const [createSidebarIsVisible, setCreateSidebarIsVisible] = useState(false);
    const [editSidebarIsVisible, setEditSidebarIsVisible] = useState(false);
    const [currentPrice, setCurrentPrice] = useState({});

    const removePrice = id => {
        appContext.services.price
            .remove(id)
            .then(response => {
                if (!response.success) return;
                notify.success({description: i18n.t('global.item_updated')});
                props.onRemove();
            })
            .catch(console.error);
    };

    const updatePrice = (values, id) => {
        appContext.services.price
            .update(id, values)
            .then(response => {
                if (!response.success) return;
                setEditSidebarIsVisible(false);
                notify.success({description: i18n.t('global.item_updated')});
                props.onEdit();
            })
            .catch(console.error);
    };

    const getColumns = i18n => {
        return [
            {
                title: i18n.t('entity.price.property.pvp_price'),
                dataIndex: 'pvpPrice',
                key: 'pvpPrice',
                render: price => <NumberFormat value={price} />
            },
            {
                title: i18n.t('entity.price.property.media_price'),
                dataIndex: 'mediaPrice',
                key: 'mediaPrice',
                render: price => <NumberFormat value={price} />
            },
            {
                title: i18n.t('entity.price.property.managed_price'),
                dataIndex: 'managedPrice',
                key: 'managedPrice',
                render: price => <NumberFormat value={price} />
            },
            {
                title: i18n.t('global.type'),
                dataIndex: 'type',
                key: 'type',
                render: type => type?.description
            },
            {
                title: '',
                dataIndex: 'actions',
                key: 'actions',
                align: 'right',
                render: (item, row) => (
                    <Fragment>
                        <Space>
                            <ConfirmButton onConfirm={event => removePrice(row.idPrice)} />
                            <EditButton onClick={event => {
                                setCurrentPrice(row);
                                setEditSidebarIsVisible(true);
                            }} />
                        </Space>
                    </Fragment>
                )
            },
        ]
    };

    return (
        <Fragment>
            <Table columns={getColumns(i18n)}
                   style={{marginBottom: 30}}
                   dataSource={props.data}
                   pagination={false}
                   rowKey={row => row.idPrice}
                   footer={() => (
                       <Flex justifyContent="end">
                           <Button icon={<PlusOutlined />}
                                   onClick={event => setCreateSidebarIsVisible(true)}>
                               {i18n.t('global.new')}
                           </Button>
                       </Flex>
                   )}
            />
            <Sidebar visible={createSidebarIsVisible}
                     title={i18n.t('entity.price.title')}
                     onClose={event => setCreateSidebarIsVisible(false)}>
                <PriceForm priceTypes={props.priceTypes}
                           onSubmit={values => {
                               setCreateSidebarIsVisible(false);
                               props.onCreate(values);
                           }}
                />
            </Sidebar>
            <Sidebar visible={editSidebarIsVisible}
                     title={i18n.t('entity.price.title')}
                     onClose={event => setEditSidebarIsVisible(false)}>
                <PriceForm price={currentPrice}
                           action={ACTION.UPDATE}
                           priceTypes={props.priceTypes}
                           onSubmit={updatePrice}
                />
            </Sidebar>
        </Fragment>
    );
};

PricesList.defaultProps = {
    onRemove: () => {},
    onCreate: () => {},
    onEdit: () => {}
};

export default PricesList;
