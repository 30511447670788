import React, { Fragment, useContext, useState } from 'react';
import { Space, Table } from 'antd';
import { I18nContext } from 'react-i18next';
import { AppContext } from '../../../../context/AppProvider';
import ConfirmButton from '../../../ConfirmButton/ConfirmButton';
import EditButton from '../../../EditButton/EditButton';
import Sidebar from '../../../Sidebar/Sidebar';
import PriceTypeForm from '../PriceTypeForm/PriceTypeForm';
import { notify } from '../../../../modules/notify/notify';

const PriceTypeList = props => {
    const {i18n} = useContext(I18nContext);
    const appContext = useContext(AppContext);
    const [editSidebarIsVisible, setEditSidebarIsVisible] = useState(false);
    const [currentPriceType, setCurrentPriceType] = useState({});

    const removePriceType = idPriceType => {
        appContext.services.priceType
            .remove(idPriceType)
            .then(response => {
                notify.success({description: i18n.t('global.item_updated')});
                props.onUpdate();
            });
    };

    const updatePriceType = (values, id) => {
        appContext.services.priceType
            .update(id, values)
            .then(response => {
                if (!response.success) return;
                setEditSidebarIsVisible(false);
                notify.success({description: i18n.t('global.item_updated')});
                props.onUpdate();
            })
            .catch(console.error);
    };

    const getColumns = i18n => {
        return [
            {
                title: i18n.t('Id'),
                dataIndex: 'idPriceType',
                key: 'idPriceType'
            },
            {
                title: i18n.t('entity.status.property.description'),
                dataIndex: 'description',
                key: 'description'
            },
            {
                title: '',
                dataIndex: 'actions',
                key: 'actions',
                align: 'right',
                render: (column, row) => (
                    <Space>
                        <ConfirmButton disabled={row.price?.length}
                                       onConfirm={event => removePriceType(row.idPriceType)}
                        />
                        <EditButton onClick={event => {
                            setCurrentPriceType(row);
                            setEditSidebarIsVisible(true);
                        }} />
                    </Space>
                )
            },
        ];
    };

    return (
        <Fragment>
            <Table columns={getColumns(i18n)}
                   dataSource={props.data}
                   pagination={false}
                   rowKey={row => row.idPriceType}
            />
            <Sidebar visible={editSidebarIsVisible}
                     title={props.entityTitle}
                     onClose={event => setEditSidebarIsVisible(false)}>
                <PriceTypeForm priceType={currentPriceType}
                               onSubmit={updatePriceType}
                />
            </Sidebar>
        </Fragment>
    );
};

PriceTypeList.defaultProps = {
    onUpdate: () => {}
};

export default PriceTypeList;
