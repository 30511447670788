import React, { Fragment, useContext } from 'react';
import { AppContext } from '../../../../context/AppProvider';
import debounce from '../../../../modules/debounce/debounce';
import InputNumberRangeField from '../../../Form/Fields/InputNumberRangeField';

const PriceRangeSelectField = props => {
    const appContext = useContext(AppContext);

    const findByRange = range => {
        props.onChange({
            range,
            ids: []
        });

        const [priceFrom, priceTo] = range || [];

        if (!priceFrom && !priceTo) return;

        let filters = {};
        switch(props.filter) {
            case 'managedPrice': filters = {managedPriceFrom: priceFrom, managedPriceTo: priceTo}; break;
            case 'mediaPrice': filters = {mediaPriceFrom: priceFrom, mediaPriceTo: priceTo}; break;
            case 'pvpPrice': filters = {pvpPriceFrom: priceFrom, pvpPriceTo: priceTo}; break;
            default: filters = {}; break;
        }

        appContext.services.price
            .search(filters)
            .then(prices => {
                props.onChange({
                    range,
                    ids: prices.map(price => price.idPrice) || []
                })
            })
            .catch(console.error);
    };

    return (
        <Fragment>
            <InputNumberRangeField {...props}
                                   onChange={debounce(findByRange, 500)}
            />
        </Fragment>
    );
};

export default PriceRangeSelectField;
