import BaseService from './BaseService';
import convertToSelectOptions from '../modules/convert-to-select-options/convert-to-select-options';

export default class StatusService extends BaseService {
    entityEndpoint = 'status';

    findList(convert = true) {
        const endpoint = `${this.entityEndpoint}/list`;

        return this
            .fetch(`${this.API_URL}${endpoint}`, {method: 'GET'})
            .then(response => convert ? convertToSelectOptions(response, 'idStatus', 'description') : response);
    }

    findAll(page = 1) {
        return super.findAll.call(this, this.entityEndpoint, page);
    }

    findById(idStatus) {
        return super.findById.call(this, this.entityEndpoint, idStatus);
    }

    remove(idStatus) {
        return super.remove.call(this, this.entityEndpoint, idStatus);
    }

    create(values) {
        return super.create.call(this, this.entityEndpoint, values);
    }

    update(idStatus, values) {
        return super.update.call(this, this.entityEndpoint, idStatus, values);
    }
}
