import * as Yup from 'yup';

const getValidationSchema = i18n => Yup.object({
    client: Yup.string()
        .required(i18n.t('global.required')),
    monthly_budget: Yup.number()
        .required(i18n.t('global.required')),
    budget_date: Yup.date()
        .required(i18n.t('global.required'))
});

export default getValidationSchema;
