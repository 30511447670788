import BaseService from './BaseService';

export default class AgreementService extends BaseService {
    entityEndpoint = 'agreement';

    findAll(page = 1) {
        return super.findAll.call(this, this.entityEndpoint, page);
    }

    findById(idAgreement) {
        return super.findById.call(this, this.entityEndpoint, idAgreement);
    }

    remove(idAgreement) {
        return super.remove.call(this, this.entityEndpoint, idAgreement);
    }

    toggleActive(idAgreement, active) {
        return super.toggleActive.call(this, this.entityEndpoint, idAgreement, active);
    }

    create(idProvider, pdfFile) {
        const endpoint = `${this.entityEndpoint}/create`;

        const uploadData = new FormData();
        uploadData.append('pdfFile', pdfFile);
        uploadData.append('idProvider', idProvider);

        return this.fetch(`${this.API_URL}${endpoint}`,
            {
                method: 'POST',
                body: uploadData
            },
            null,
            {}
        ).then(response => response);
    }

    update(idAgreement, values) {
        return super.update.call(this, this.entityEndpoint, idAgreement, values);
    }
}
