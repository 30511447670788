import React from 'react';
import { Tag } from 'antd';

const UserRoleTag = ({role}) => {
    const roles = {
        ROLE_ADMIN: 'geekblue',
        ROLE_USER: 'green'
    };

    return (
        <Tag color={(role in roles) ? roles[role] : 'magenta'}>
            {role.toUpperCase()}
        </Tag>
    );
};

export default UserRoleTag;
