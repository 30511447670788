import React, { Component } from 'react';
import UserService from '../services/UserService';
import BaseService from '../services/BaseService';
import MediaService from '../services/MediaService';
import LinkTypeService from '../services/LinkTypeService';
import ProviderService from '../services/ProviderService';
import PriceTypeService from '../services/PriceTypeService';
import AgreementService from '../services/AgreementService';
import ClientService from '../services/ClientService';
import PackService from '../services/PackService';
import TransactionService from '../services/TransactionService';
import StatusService from '../services/StatusService';
import TopicService from '../services/TopicService';
import MediaTypeService from '../services/MediaTypeService';
import PriceService from '../services/PriceService';
import ClientsBudgetsService from "../services/ClientsBudgetsService";

export const AppContext = React.createContext({});

export default class AppProvider extends Component {
    state = {
        services: {
            base: new BaseService(),
            user: new UserService(),
            provider: new ProviderService(),
            linkType: new LinkTypeService(),
            clientsBudgets: new ClientsBudgetsService(),
            priceType: new PriceTypeService(),
            media: new MediaService(),
            price: new PriceService(),
            agreement: new AgreementService(),
            client: new ClientService(),
            pack: new PackService(),
            transaction: new TransactionService(),
            status: new StatusService(),
            topic: new TopicService(),
            mediaType: new MediaTypeService()
        }
    };

    render() {
        return (
            <AppContext.Provider value={{...this.state}}>
                {this.props.children}
            </AppContext.Provider>
        );
    }
}
