import React from 'react';
import { Tag } from 'antd';

const LinkTypeTag = ({linkType}) => (
    <Tag color={linkType.color ? linkType.color : 'geekblue'}>
        {linkType.type.toUpperCase()}
    </Tag>
);

export default LinkTypeTag;
