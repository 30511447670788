import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import Button from '../../../Button/Button';
import Portal from '../../../Portal/Portal';
import { I18nContext } from 'react-i18next';
import { Col, Row } from 'antd';
import SelectField from '../../../Form/Fields/SelectField';
import { AppContext } from '../../../../context/AppProvider';
import { PlusOutlined } from '@ant-design/icons';
import { notify } from '../../../../modules/notify/notify';
import TopicForm from './TopicForm';
import ChildrenSidebar from '../../../Sidebar/ChildrenSidebar';

const TopicSelectForm = props => {
    const {i18n} = useContext(I18nContext);
    const appContext = useContext(AppContext);
    const [topics, setTopics] = useState([]);
    const [createTopicSidebarIsVisible, setCreateTopicSidebarIsVisible] = useState(false);

    const findTopics = () => {
        appContext.services.topic
            .findList()
            .then(topics => setTopics(topics))
            .catch(console.error);
    };

    const createTopic = values => {
        appContext.services.topic
            .create(values)
            .then(response => {
                if (!response.success) return;
                notify.success({description: i18n.t('global.item_updated')});
                setCreateTopicSidebarIsVisible(false);
                findTopics();
            })
            .catch(console.error);
    };

    useEffect(findTopics, []);

    return (
        <Fragment>
            <Formik enableReinitialize={true}
                    initialValues={{
                        idTopic: '',
                    }}
                    onSubmit={(values, {setSubmitting}) => {
                        props.onSubmit(values);
                        setSubmitting(false);
                    }}>
                {props => (
                    <Form>
                        <Row gutter={15}>
                            <Col span={12}>
                                <SelectField label={i18n.t('entity.topic.property.name')}
                                             name="idTopic"
                                             placeholder={i18n.t('entity.topic.property.name')}
                                             options={topics}
                                             append={<Button shape="circle"
                                                             size="small"
                                                             icon={<PlusOutlined />}
                                                             onClick={event => setCreateTopicSidebarIsVisible(true)}
                                             />}
                                             onChange={value => props.setFieldValue('idTopic', value)}
                                />
                            </Col>
                        </Row>
                        <Portal.In target="sidebar-footer">
                            <Button type="primary"
                                    disabled={props.isSubmitting || !props.isValid}
                                    onClick={props.handleSubmit}>
                                {i18n.t('global.accept')}
                            </Button>
                        </Portal.In>
                    </Form>
                )}
            </Formik>
            <ChildrenSidebar visible={createTopicSidebarIsVisible}
                             title={i18n.t('entity.topic.create_title')}
                             onClose={event => setCreateTopicSidebarIsVisible(false)}>
                <TopicForm actionsPortalTarget="children-sidebar-footer"
                           onSubmit={createTopic}
                />
            </ChildrenSidebar>
        </Fragment>
    );
};

TopicSelectForm.defaultProps = {
    onSubmit: () => {}
};

export default TopicSelectForm;
