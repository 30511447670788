import React, { Fragment, useContext } from 'react';
import { I18nContext } from 'react-i18next';
import Button from '../Button/Button';
import { Alert } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import './filters-bar.scss';

const FiltersBar = props => {
    const {i18n} = useContext(I18nContext);

    return (
        <section className="filters-bar">
            {!!props.showWarning &&
            <header className="filters-bar__warning">
                <Alert type="info"
                       message={
                           <Fragment>
                               {i18n.t('component.filters_bar.warning.active_filters')}
                               <Button type="link"
                                       icon={<DeleteOutlined />}
                                       onClick={event => props.onReset()}>
                                   {i18n.t('global.remove')}
                               </Button>
                           </Fragment>
                       }
                />
            </header>}
            <div className="filters-bar__content">
                {props.children}
            </div>
        </section>
    );
};

FiltersBar.defaultProps = {
    onReset: () => {}
};

export default FiltersBar;
