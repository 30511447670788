const convertToSelectOptions = (collection = [], valueProperty = 'id', labelProperty = 'label') => {
    return collection.reduce((accumulator, item) => {
        return [
            ...accumulator,
            {
                label: (item[labelProperty])?.toString() || '',
                value: item[valueProperty]
            }
        ];
    }, []);
};

export default convertToSelectOptions;
