import React, { useContext } from 'react';
import { Form, Formik } from 'formik';
import InputField from '../../../Form/Fields/InputField';
import Button from '../../../Button/Button';
import getValidationSchema from './get-validation-schema';
import Portal from '../../../Portal/Portal';
import { I18nContext } from 'react-i18next';
import { Col, Row } from 'antd';

const TopicForm = ({topic, actionsPortalTarget, ...props}) => {
    const {i18n} = useContext(I18nContext);

    const extractStatusId = topic => {
        return 'idTopic' in topic ? topic.idTopic : null;
    };

    return (
        <Formik validationSchema={getValidationSchema(i18n)}
                enableReinitialize={true}
                initialValues={{
                    name: topic.name || '',
                }}
                onSubmit={(values, {setSubmitting}) => {
                    props.onSubmit(values, extractStatusId(topic));
                    setSubmitting(false);
                }}>
            {props => (
                <Form>
                    <Row gutter={15}>
                        <Col span={12}>
                            <InputField label={i18n.t('entity.topic.property.name')}
                                        name="name"
                                        type="text"
                                        placeholder={i18n.t('entity.topic.property.name')}
                            />
                        </Col>
                    </Row>
                    <Portal.In target={actionsPortalTarget}>
                        <Button type="primary"
                                disabled={props.isSubmitting || !props.isValid}
                                onClick={props.handleSubmit}>
                            {i18n.t('global.accept')}
                        </Button>
                    </Portal.In>
                </Form>
            )}
        </Formik>
    );
};

TopicForm.defaultProps = {
    actionsPortalTarget: 'sidebar-footer',
    topic: {},
    onSubmit: () => {}
};

export default TopicForm;
