import React, { useContext } from 'react';
import { Form, Formik } from 'formik';
import InputField from '../../../Form/Fields/InputField';
import Button from '../../../Button/Button';
import getValidationSchema from './get-validation-schema';
import Portal from '../../../Portal/Portal';
import { I18nContext } from 'react-i18next';
import { Col, Row } from 'antd';

const LinkTypeForm = ({linkType, ...props}) => {
    const {i18n} = useContext(I18nContext);

    const extractLinkTypeId = linkType => {
        return 'idLinkType' in linkType ? linkType.idLinkType : null;
    };

    return (
        <Formik validationSchema={getValidationSchema(i18n)}
                enableReinitialize={true}
                initialValues={{
                    type: linkType.type || '',
                    color: linkType.color || ''
                }}
                onSubmit={(values, {setSubmitting}) => {
                    props.onSubmit(values, extractLinkTypeId(linkType));
                    setSubmitting(false);
                }}>
            {props => (
                <Form>
                    <Row gutter={15}>
                        <Col span={12}>
                            <InputField label={i18n.t('entity.link_type.property.type')}
                                        name="type"
                                        type="text"
                                        placeholder={i18n.t('entity.link_type.property.type')}
                            />
                        </Col>
                        <Col span={12}>
                            <InputField label={i18n.t('entity.link_type.property.color')}
                                        name="color"
                                        type="color"
                                        placeholder={i18n.t('entity.link_type.property.color')}
                            />
                        </Col>
                    </Row>
                    <Portal.In target="sidebar-footer">
                        <Button type="primary"
                                disabled={props.isSubmitting || !props.isValid}
                                onClick={props.handleSubmit}>
                            {i18n.t('global.accept')}
                        </Button>
                    </Portal.In>
                </Form>
            )}
        </Formik>
    );
};

LinkTypeForm.defaultProps = {
    linkType: {},
    onSubmit: () => {}
};

export default LinkTypeForm;
