import React from 'react';
import LoginForm from '../../components/Form/Login/LoginForm';
import { Col, Row } from 'antd';
import './login-page.scss';

const LoginPage = props => {

    const redirect = () => {
        // TODO: Pedir a AppProvider que pida todos los lists

        console.log(props);
        const from = props.location.state
            ? props.location.state.from.pathname
            : '/';
        props.history.push(from);
    };

    return (
        <Row className="login-page">
            <Col xs={{ span: 22, offset: 1 }}
                 md={{ span: 12, offset: 6 }}
                 xl={{ span: 6, offset: 9 }}>
                <LoginForm onSuccess={redirect} />
            </Col>
        </Row>
    );
};

export default LoginPage;
