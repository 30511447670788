import React, { useContext, useState } from 'react';
import { Formik, Form } from 'formik';
import getValidationSchema from './get-validation-schema';
import InputField from '../Fields/InputField';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import logo from '../../../assets/images/logo_agenciaseso.svg';
import { I18nContext } from 'react-i18next';
import { AppContext } from '../../../context/AppProvider';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import Button from '../../Button/Button';
import './login-form.scss';

const LoginForm = props => {
    const [error, setError] = useState(false);
    const appContext = useContext(AppContext);
    const {i18n} = useContext(I18nContext);

    const login = formData => {
        setError(false);

        appContext.services.user
            .login(formData)
            .then(props.onSuccess)
            .catch(error => setError(true));
    };

    return (
        <Formik validationSchema={getValidationSchema(i18n)}
                initialValues={{
                    username: '',
                    password: ''
                }}
                onSubmit={(values, {setSubmitting}) => {
                    login(values);
                    setSubmitting(false);
                }}>
            {props => (
                <Form className="login-form">
                    <header className="login-form__header">
                        <img src={logo} alt="Agencia SEO" />
                    </header>
                    <fieldset>
                        {error &&
                        <ErrorMessage text={i18n.t('component.login_form.generic_error')} />}
                        <InputField label="E-mail"
                                    name="username"
                                    type="email"
                                    size="large"
                                    prefix={<UserOutlined />}
                                    placeholder="E-mail"
                        />
                        <InputField label={i18n.t('global.password')}
                                    name="password"
                                    type="password"
                                    size="large"
                                    prefix={<LockOutlined />}
                                    placeholder={i18n.t('global.password')}
                        />
                    </fieldset>
                    <footer className="login-form__footer">
                        <Button type="primary"
                                size="large"
                                htmlType="submit"
                                disabled={props.isSubmitting || !props.isValid}>
                            {i18n.t('global.log_in')}
                        </Button>
                    </footer>
                </Form>
            )}
        </Formik>
    );
};
export default LoginForm;

LoginForm.defaultProps = {
    onSubmit: () => {}
};
