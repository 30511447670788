import React, { Component } from 'react';
import MainNavigation from '../MainNavigation/MainNavigation';
import { Col, Row } from 'antd';
import SiteHeader from '../SiteHeader/SiteHeader';
import './layout.scss';

export default class Layout extends Component {
    render() {
        return (
            <Row className="layout">
                <Col xs={24} md={6} xl={4}>
                    <aside className="layout__main-navigation">
                        <MainNavigation {...this.props} />
                    </aside>
                </Col>
                <Col className="layout__big-section"
                     xs={24} md={18} xl={20}>
                    <header className="layout__site-header">
                        <SiteHeader />
                    </header>
                    <section className="layout__page-body">
                        {this.props.children}
                    </section>
                </Col>
            </Row>
        );
    }
}
