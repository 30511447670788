import * as Yup from 'yup';

const getValidationSchema = (action, isCompleted, i18n) => {
    const TransactionCompletionValidations = isCompleted
        ? {
            urlOrigin: Yup.string()
                .required(i18n.t('global.required')),
            urlDestination: Yup.string()
                .required(i18n.t('global.required')),
            anchor: Yup.string()
                .required(i18n.t('global.required'))
        }
        : {};

    return Yup.object({
        ...TransactionCompletionValidations,
        client: Yup.mixed().test({
            test: provider => 'value' in provider,
            message: i18n.t('global.required')
        }),
        media: Yup.mixed().test({
            test: provider => 'value' in provider,
            message: i18n.t('global.required')
        })
    });
};

export default getValidationSchema;
