import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import InputField from '../../../Form/Fields/InputField';
import Button from '../../../Button/Button';
import getValidationSchema from './get-validation-schema';
import Portal from '../../../Portal/Portal';
import { I18nContext } from 'react-i18next';
import { Col, Row } from 'antd';
import TextAreaField from '../../../Form/Fields/TextAreaField';
import SelectField from '../../../Form/Fields/SelectField';
import { countries } from '../../../../modules/get-country/countries';
import { spanishProvinces } from '../../../../modules/get-province/provinces';
import { AppContext } from '../../../../context/AppProvider';
import ProviderForm from '../../provider/ProviderForm/ProviderForm';
import { notify } from '../../../../modules/notify/notify';
import { PlusOutlined } from '@ant-design/icons';
import DeepChildrenSidebar from '../../../Sidebar/DeepChildrenSidebar';
import ProviderSelectField from '../../provider/ProviderSelectField/ProviderSelectField';
import UnsavedPrompt from '../../../UnsavedPrompt/UnsavedPrompt';

const MediaForm = ({media, actionsPortalTarget, ...props}) => {
    const {i18n} = useContext(I18nContext);
    const appContext = useContext(AppContext);
    const [mediaTypes, setMediaTypes] = useState([]);
    const [createProviderSidebarIsVisible, setCreateProviderSidebarIsVisible] = useState(false);

    const findMediaTypes = () => {
        appContext.services.mediaType
            .findList()
            .then(mediaTypes => setMediaTypes(mediaTypes || []))
            .catch(console.error);
    };

    const createProvider = values => {
        appContext.services.provider
            .create(values)
            .then(response => {
                if (!response.success) return;
                notify.success({description: i18n.t('global.item_updated')});
                setCreateProviderSidebarIsVisible(false);
            })
            .catch(console.error);
    };

    useEffect(() => {
        findMediaTypes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
            <Formik validationSchema={getValidationSchema(i18n)}
                    enableReinitialize={true}
                    initialValues={{
                        name: media.name || '',
                        domain: media.domain || '',
                        country: media.country || 630,
                        province: media.province || 0,
                        comments: media.comments || '',
                        provider: media.provider?.idProvider ? {label: media.provider.name, value: media.provider.idProvider} : {},
                        idMediaType: media.type?.idMediaType || ''
                    }}
                    onSubmit={(values, {setSubmitting}) => {
                        const {provider, ...rest} = values;
                        const adaptedValues = {
                            ...rest,
                            idProvider: provider.value
                        }
                        props.onSubmit(adaptedValues);
                        setSubmitting(false);
                    }}>
                {props => (
                    <Form>
                        <UnsavedPrompt when={props.dirty} />
                        <Row gutter={15}>
                            <Col span={12}>
                                <InputField label={i18n.t('entity.media.property.name')}
                                            name="name"
                                            placeholder={i18n.t('entity.media.property.name')}
                                />
                            </Col>
                            <Col span={12}>
                                <InputField label={i18n.t('entity.media.property.domain')}
                                            name="domain"
                                            placeholder={i18n.t('entity.media.property.domain')}
                                />
                            </Col>
                        </Row>
                        <Row gutter={15}>
                            <Col span={12}>
                                <SelectField label={i18n.t('entity.media.property.country')}
                                             name="country"
                                             options={countries}
                                             placeholder={i18n.t('entity.media.property.country')}
                                             onChange={value => {
                                                 props.setFieldValue('country', value);
                                                 if (value !== 630) props.setFieldValue('province', 0);
                                             }}
                                />
                            </Col>
                            <Col span={12}>
                                <SelectField label={i18n.t('entity.media.property.province')}
                                             name="province"
                                             placeholder={i18n.t('entity.media.property.province')}
                                             options={props.values['country'] === 630 ? spanishProvinces : [{label: '–', value: 0}]}
                                             onChange={value => props.setFieldValue('province', value)}
                                />
                            </Col>
                        </Row>
                        <Row gutter={15}>
                            <Col span={12}>
                                <ProviderSelectField label={i18n.t('entity.media.property.provider')}
                                                     name="provider"
                                                     allowClear={false}
                                                     placeholder={i18n.t('entity.media.property.provider')}
                                                     append={<Button shape="circle"
                                                                     size="small"
                                                                     icon={<PlusOutlined />}
                                                                     onClick={event => setCreateProviderSidebarIsVisible(true)}
                                                     />}
                                                     onChange={provider => {
                                                         props.setFieldValue('provider', provider)
                                                     }}
                                />
                            </Col>
                            <Col span={12}>
                                <SelectField label={i18n.t('entity.media.property.media_type')}
                                             name="idMediaType"
                                             placeholder={i18n.t('entity.media.property.media_type')}
                                             options={mediaTypes}
                                             onChange={value => props.setFieldValue('idMediaType', value)}
                                />
                            </Col>
                        </Row>
                        <Row gutter={15}>
                            <Col span={24}>
                                <TextAreaField label={i18n.t('entity.media.property.comments')}
                                               name="comments"
                                               placeholder={i18n.t('entity.media.property.comments')}
                                />
                            </Col>
                        </Row>
                        <Portal.Out id="form-actions"
                                    className="form-actions"
                        />
                        <Portal.In target={actionsPortalTarget}>
                            <Button type="primary"
                                    disabled={props.isSubmitting || !props.isValid}
                                    onClick={props.handleSubmit}>
                                {i18n.t('global.accept')}
                            </Button>
                        </Portal.In>
                    </Form>
                )}
            </Formik>
            <DeepChildrenSidebar visible={createProviderSidebarIsVisible}
                                 title={i18n.t('entity.provider.create_title')}
                                 onClose={event => setCreateProviderSidebarIsVisible(false)}>
                <ProviderForm actionsPortalTarget="deep-children-sidebar-footer"
                              onSubmit={createProvider}
                />
            </DeepChildrenSidebar>
        </Fragment>
    );
};

MediaForm.defaultProps = {
    actionsPortalTarget: 'sidebar-footer',
    media: {},
    onSubmit: () => {}
};

export default MediaForm;
