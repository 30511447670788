import React, { Fragment, useContext, useEffect, useState } from 'react';
import { withLayout } from '../../components/HOC/WithLayout';
import Portal from '../../components/Portal/Portal';
import { Button, PageHeader } from 'antd';
import { I18nContext } from 'react-i18next';
import { AppContext } from '../../context/AppProvider';
import Sidebar from '../../components/Sidebar/Sidebar';
import { PlusOutlined } from '@ant-design/icons';
import './clientsbudgets-list-page.scss';
import Skeleton from '../../components/Skeleton/Skeleton';
import Pagination from '../../components/Pagination/Pagination';
import { notify } from '../../modules/notify/notify';
import { ACTION } from '../../components/CRUD/constants';
import ClientBudgetFilters from "../../components/CRUD/clientbudget/ClientBudgetFilters/ClientBudgetFilters";
import ClientBudgetList from "../../components/CRUD/clientbudget/ClientBudgetList/ClientBudgetList";
import ClientBudgetForm from "../../components/CRUD/clientbudget/ClientBudgetForm/ClientBudgetForm";

const ClientsBudgetsListPage = props => {
    const {i18n} = useContext(I18nContext);
    const appContext = useContext(AppContext);
    const [createSidebarIsVisible, setCreateSidebarIsVisible] = useState(false);
    const [clientsBudgets, setClientsBudgets] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filters, setFilters] = useState(JSON.parse(localStorage.getItem('clientsBudgetsFilters')));

    const search = (page = 1) => {
        setLoading(true);
        appContext.services.clientsBudgets
            .search(page, filters)
            .then(budgets => setClientsBudgets(budgets))
            .catch(console.error)
            .finally(() => setLoading(false));
    };

    const createClientBudget = values => {
        appContext.services.clientsBudgets
            .create(values)
            .then(response => {
                if (!response.success) return;
                notify.success({description: i18n.t('global.item_updated')});
                setCreateSidebarIsVisible(false);
                search();
            })
            .catch(console.error);
    };

    const persistFilters = filters => {
        localStorage.removeItem('clientsBudgetsFilters');
        if (filters && Object.keys(filters).length) {
            localStorage.setItem('clientsBudgetsFilters', JSON.stringify(filters));
        }
    };

    useEffect(() => {
        persistFilters(filters);
        search(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    return (
        <Fragment>
            <div className="clients-budgets-list-page">
                <ClientBudgetFilters filters={filters}
                                    onFilter={filters => setFilters(filters)}
                />
                <Skeleton loading={loading}>
                    <ClientBudgetList data={clientsBudgets?.items}
                                      onUpdate={search}
                    />
                    <Pagination totalItems={clientsBudgets?.totalItems}
                                currentPage={clientsBudgets?.currentPage}
                                itemsPerPage={clientsBudgets?.itemsPerPage}
                                onChange={page => search(page)}
                    />
                </Skeleton>
            </div>

            <Portal.In target="page-header">
                <PageHeader title={i18n.t('page.clients_budgets.title')}
                            extra={[
                                <Button key="2"
                                        type="primary"
                                        icon={<PlusOutlined />}
                                        onClick={event => setCreateSidebarIsVisible(true)}>
                                    {i18n.t('entity.clientBudget.title')}
                                </Button>
                            ]}
                />
            </Portal.In>

            <Sidebar visible={createSidebarIsVisible}
                     title={i18n.t('entity.clientBudget.create_title')}
                     onClose={event => setCreateSidebarIsVisible(false)}>
                <ClientBudgetForm action={ACTION.CREATE}
                                 onSubmit={createClientBudget}
                />
            </Sidebar>
        </Fragment>
    );
};

export default withLayout(ClientsBudgetsListPage);
