import dayjs from 'dayjs';

const transactionPostProcessor = transaction => {
    return {
        ...transaction,
        publicationDate: dayjs.unix(transaction.publicationDate).format('DD-MM-YYYY')
    }
};

export default transactionPostProcessor;
