import React, { Fragment, useContext, useEffect, useState } from 'react';
import { withLayout } from '../../components/HOC/WithLayout';
import Portal from '../../components/Portal/Portal';
import { Button, PageHeader } from 'antd';
import { I18nContext } from 'react-i18next';
import { AppContext } from '../../context/AppProvider';
import Sidebar from '../../components/Sidebar/Sidebar';
import { DownloadOutlined, PlusOutlined } from '@ant-design/icons';
import PacksList from '../../components/CRUD/pack/PacksList/PacksList';
import PackForm from '../../components/CRUD/pack/PackForm/PackForm';
import dayjs from 'dayjs';
import json2CSVDownload from '../../modules/json2csv-download/json2csv-download';
import Skeleton from '../../components/Skeleton/Skeleton';
import Pagination from '../../components/Pagination/Pagination';
import PackFilters from '../../components/CRUD/pack/PackFilters/PackFilters';
import './packs-list-page.scss';
import { notify } from '../../modules/notify/notify';
import { ACTION } from '../../components/CRUD/constants';

const PacksListPage = props => {
    const {i18n} = useContext(I18nContext);
    const appContext = useContext(AppContext);
    const [createSidebarIsVisible, setCreateSidebarIsVisible] = useState(false);
    const [packs, setPacks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [downloading, setDownloading] = useState(false);
    const [filters, setFilters] = useState(JSON.parse(localStorage.getItem('packFilters')));

    const search = (page = 1) => {
        setLoading(true);
        appContext.services.pack
            .search(page, filters)
            .then(packs => setPacks(packs))
            .catch(console.error)
            .finally(() => setLoading(false));
    };

    const createPack = values => {
        appContext.services.pack
            .create(values)
            .then(response => {
                if (!response.success) return;
                notify.success({description: i18n.t('global.item_updated')});
                setCreateSidebarIsVisible(false);
                search();
            })
            .catch(console.error);
    };

    const downloadCSV = filters => {
        setDownloading(true);
        appContext.services.pack
            .search(1, filters, true)
            .then(packs => {
                const fields = ['idPack', 'name', 'price', 'totalUnits', 'pendingUnits', 'expirationDate', 'active'];
                const rows = packs.map(pack => {
                    return {
                        ...pack,
                        expirationDate: dayjs.unix(pack.expirationDate).format('DD/MM/YYYY HH:mm:ss'),
                    }
                });

                json2CSVDownload(fields, rows, i18n.t('entity.pack.title', {count: packs.length}));
            })
            .catch(console.error)
            .finally(() => setDownloading(false));
    };

    const persistFilters = filters => {
        localStorage.removeItem('packFilters');
        if (filters && Object.keys(filters).length) {
            localStorage.setItem('packFilters', JSON.stringify(filters));
        }
    };

    useEffect(() => {
        persistFilters(filters);
        search(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    return (
        <Fragment>
            <div className="packs-list-page">
                <PackFilters filters={filters}
                               onFilter={filters => setFilters(filters)}
                />
                <Skeleton loading={loading}>
                    <PacksList data={packs?.items} onUpdate={search} />
                    <Pagination totalItems={packs?.totalItems}
                                currentPage={packs?.currentPage}
                                itemsPerPage={packs?.itemsPerPage}
                                onChange={page => search(page)}
                    />
                </Skeleton>
            </div>

            <Portal.In target="page-header">
                <PageHeader title={i18n.t('page.packs.title')}
                            extra={[
                                <Button key="1"
                                        loading={downloading}
                                        icon={<DownloadOutlined />}
                                        disabled={!packs?.totalItems}
                                        onClick={event => downloadCSV(filters)}>
                                    CSV
                                </Button>,
                                <Button key="2"
                                        type="primary"
                                        icon={<PlusOutlined />}
                                        onClick={event => setCreateSidebarIsVisible(true)}>
                                    {i18n.t('entity.pack.title')}
                                </Button>
                            ]}
                />
            </Portal.In>

            <Sidebar visible={createSidebarIsVisible}
                     title={i18n.t('entity.pack.create_title')}
                     onClose={event => setCreateSidebarIsVisible(false)}>
                <PackForm action={ACTION.CREATE}
                          onSubmit={createPack}
                />
            </Sidebar>
        </Fragment>
    );
};

export default withLayout(PacksListPage);
