import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import InputField from '../../../Form/Fields/InputField';
import Button from '../../../Button/Button';
import getValidationSchema from './get-validation-schema';
import Portal from '../../../Portal/Portal';
import { I18nContext } from 'react-i18next';
import { Col, Row, Select } from 'antd';
import SelectField from '../../../Form/Fields/SelectField';
import { AppContext } from '../../../../context/AppProvider';
import TextAreaField from '../../../Form/Fields/TextAreaField';
import SwitchField from '../../../Form/Fields/SwitchField';
import { PlusOutlined } from '@ant-design/icons';
import ChildrenSidebar from '../../../Sidebar/ChildrenSidebar';
import { notify } from '../../../../modules/notify/notify';
import PackForm from '../../pack/PackForm/PackForm';
import ClientForm from '../../client/ClientForm/ClientForm';
import MediaForm from '../../media/MediaForm/MediaForm';
import dayjs from 'dayjs';
import DatePickerField from '../../../Form/Fields/DatePickerField';
import PackSelectField from '../../pack/PackSelectField/PackSelectField';
import MediaSelectField from '../../media/MediaSelectField/MediaSelectField';
import ClientSelectField from '../../client/ClientSelectField/ClientSelectField';
import { ACTION } from '../../constants';
import convertToSelectOptions from '../../../../modules/convert-to-select-options/convert-to-select-options';
import FieldWrapper from '../../../Form/Fields/FieldWrapper';
import NumberFormat from '../../../NumberFormat/NumberFormat';
import UnsavedPrompt from '../../../UnsavedPrompt/UnsavedPrompt';

const TransactionForm = ({action, transaction, actionsPortalTarget, ...props}) => {
    const {i18n} = useContext(I18nContext);
    const appContext = useContext(AppContext);
    const [status, setStatus] = useState([]);
    const [isCompleted, setIsCompleted] = useState(transaction.status?.isCompleted);
    const [createPackSidebarIsVisible, setCreatePackSidebarIsVisible] = useState(false);
    const [createMediaSidebarIsVisible, setCreateMediaSidebarIsVisible] = useState(false);
    const [createClientSidebarIsVisible, setCreateClientSidebarIsVisible] = useState(false);
    const [mediaPrices, setMediaPrices] = useState([]);

    const findStatus = () => {
        appContext.services.status
            .findList(false)
            .then(status => setStatus(status));
    };

    const findPricesByMedia = () => {
        const idMedia = transaction?.media?.idMedia;
        if (!idMedia) return;
        appContext.services.price
            .findByMedia(idMedia)
            .then(mediaPrices => setMediaPrices(mediaPrices))
            .catch(console.error);
    };

    const createMedia = values => {
        appContext.services.media
            .create(values)
            .then(response => {
                if (!response.success) return;
                notify.success({description: i18n.t('global.item_updated')});
                setCreateMediaSidebarIsVisible(false);
            })
            .catch(error => {
                console.error(error);
                notify.error({message: `${error.name}: ${error.message}`, description: i18n.t('entity.pack.error.unique_constraint_violation_exception')});
            });
    };

    const createPack = values => {
        appContext.services.pack
            .create(values)
            .then(response => {
                if (!response.success) return;
                notify.success({description: i18n.t('global.item_updated')});
                setCreatePackSidebarIsVisible(false);
            })
            .catch(console.error);
    };

    const createClient = values => {
        appContext.services.client
            .create(values)
            .then(response => {
                if (!response.success) return;
                notify.success({description: i18n.t('global.item_updated')});
                setCreateClientSidebarIsVisible(false);
            })
            .catch(console.error);
    };

    const getPendingIdStatus = status => {
        return status.find(status => status.isPending)?.idStatus || '';
    };

    const getCompletedIdStatus = status => {
        return status.find(status => status.isCompleted)?.idStatus || '';
    };

    useEffect(() => {
        findStatus();
        findPricesByMedia();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
            <Formik validationSchema={getValidationSchema(action, isCompleted, i18n)}
                    enableReinitialize={true}
                    initialValues={{
                        urlOrigin: transaction.urlOrigin || '',
                        urlDestination: transaction.urlDestination || '',
                        anchor: transaction.anchor || '',
                        comments: transaction.comments || '',
                        mediaPrice: transaction.mediaPrice || 0,
                        managedPrice: transaction.managedPrice || 0,
                        priceType: transaction.priceType || '',
                        shared: !!transaction.shared,
                        client: transaction.client?.idClient ? {label: transaction.client.name, value: transaction.client.idClient} : {},
                        media: transaction.media?.idMedia ? {label: transaction.media.name, value: transaction.media.idMedia} : {},
                        pack: transaction.pack?.idPack ? {label: transaction.pack.name, value: transaction.pack.idPack} : {},
                        idStatus: transaction.status?.idStatus || getPendingIdStatus(status),
                        publicationDate: transaction.publicationDate || dayjs().unix(),
                    }}
                    onSubmit={(values, {setSubmitting}) => {
                        if (values.idStatus === getCompletedIdStatus(status) && !window.confirm(i18n.t('entity.transaction.message.completion_confirm'))) {
                            setSubmitting(false);
                            return;
                        }

                        const {client, pack, media, ...rest} = values;
                        const adaptedValues = {
                            ...rest,
                            idClient: client.value,
                            idPack: pack?.value || '',
                            idMedia: media.value
                        }
                        props.onSubmit(adaptedValues);
                        setSubmitting(false);
                    }}>
                {props => (
                    <Form>
                        <UnsavedPrompt when={props.dirty} />
                        <Row gutter={15}>
                            <Col span={12}>
                                <InputField label={i18n.t('entity.transaction.property.url_origin')}
                                            name="urlOrigin"
                                            placeholder={i18n.t('entity.transaction.property.url_origin')}
                                />
                            </Col>
                            <Col span={12}>
                                <InputField label={i18n.t('entity.transaction.property.url_destination')}
                                            name="urlDestination"
                                            placeholder={i18n.t('entity.transaction.property.url_destination')}
                                />
                            </Col>
                            <Col span={12}>
                                <InputField label={i18n.t('entity.transaction.property.anchor')}
                                            name="anchor"
                                            placeholder={i18n.t('entity.transaction.property.anchor')}
                                />
                            </Col>
                            <Col span={12}>
                                <DatePickerField label={i18n.t('entity.transaction.property.publication_date')}
                                                 name="publicationDate"
                                                 placeholder={i18n.t('entity.transaction.property.publication_date')}
                                                 allowEmpty={false}
                                                 allowClear={false}
                                                 onChange={value => {
                                                     props.setFieldValue('publicationDate', value.unix());
                                                 }}
                                />
                            </Col>
                            <Col span={12}>
                                <SwitchField label={i18n.t('entity.transaction.property.shared')}
                                             name="shared"
                                             onChange={value => props.setFieldValue('shared', value)}
                                />
                            </Col>
                        </Row>
                        <Row gutter={15}>
                            <Col span={12}>
                                <ClientSelectField label={i18n.t('entity.transaction.property.client')}
                                                   name="client"
                                                   allowClear={false}
                                                   placeholder={i18n.t('entity.transaction.property.client')}
                                                   append={<Button shape="circle"
                                                                   size="small"
                                                                   icon={<PlusOutlined/>}
                                                                   onClick={event => setCreateClientSidebarIsVisible(true)}
                                                   />}
                                                   onChange={provider => {
                                                       props.setFieldValue('client', provider)
                                                   }}
                                />
                            </Col>
                            <Col span={12}>
                                <MediaSelectField label={i18n.t('entity.transaction.property.media')}
                                                  name="media"
                                                  allowClear={false}
                                                  placeholder={i18n.t('entity.transaction.property.media')}
                                                  append={<Button shape="circle"
                                                                  size="small"
                                                                  icon={<PlusOutlined/>}
                                                                  onClick={event => setCreateMediaSidebarIsVisible(true)}
                                                  />}
                                                  onChange={media => props.setFieldValue('media', media)}
                                />
                            </Col>
                            <Col span={12}>
                                <SelectField label={i18n.t('entity.transaction.property.status')}
                                             name="idStatus"
                                             autoComplete="new-password"
                                             placeholder={i18n.t('entity.transaction.property.status')}
                                             options={convertToSelectOptions(status, 'idStatus', 'description')}
                                             disabled={action === ACTION.CREATE}
                                             onChange={value => {
                                                 props.setFieldValue('idStatus', value);
                                                 setIsCompleted(status.find(status => status.idStatus === value)?.isCompleted)
                                             }}
                                />
                            </Col>
                            <Col span={12}>
                                <PackSelectField label={i18n.t('entity.transaction.property.pack')}
                                                 name="pack"
                                                 hideEmpty
                                                 allowClear={false}
                                                 placeholder={i18n.t('entity.transaction.property.pack')}
                                                 append={<Button shape="circle"
                                                                 size="small"
                                                                 icon={<PlusOutlined />}
                                                                 onClick={event => setCreatePackSidebarIsVisible(true)}
                                                 />}
                                                 onChange={pack => {
                                                     props.setFieldValue('pack', pack)
                                                 }}
                                />
                            </Col>
                        </Row>
                        {action === ACTION.UPDATE &&
                        <Row gutter={15}>
                            <Col span={8}>
                                <FieldWrapper label={i18n.t('entity.transaction.property.media_prices')}>
                                    <Select className="input-field__control"
                                            onChange={idPrice => {
                                                const mediaPrice = mediaPrices.find(mediaPrice => mediaPrice.idPrice === idPrice);
                                                props.setFieldValue('pvpPrice', mediaPrice.pvpPrice);
                                                props.setFieldValue('mediaPrice', mediaPrice.mediaPrice);
                                                props.setFieldValue('managedPrice', mediaPrice.managedPrice);
                                                props.setFieldValue('priceType', mediaPrice.type.description);
                                            }}>
                                        {mediaPrices?.map(mediaPrice => (
                                            <Select.Option key={mediaPrice.idPrice}
                                                           value={mediaPrice.idPrice}>
                                                <div>
                                                    <small>{i18n.t('entity.price.property.mediaPrice')}</small>
                                                    &nbsp;
                                                    <NumberFormat value={mediaPrice.mediaPrice}/>
                                                </div>
                                                <div>
                                                    <small>{i18n.t('entity.price.property.managed_price')}</small>
                                                    &nbsp;
                                                    <NumberFormat value={mediaPrice.managedPrice}/></div>
                                                <div>
                                                    <small>{i18n.t('entity.price.property.pvp_price')}</small>
                                                    &nbsp;
                                                    <NumberFormat value={mediaPrice.pvpPrice}/>
                                                </div>
                                                <div>
                                                    <small>{i18n.t('entity.price.property.type')}</small>
                                                    &nbsp;
                                                    {mediaPrice.type.description}
                                                </div>
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </FieldWrapper>
                            </Col>
                            <Col span={4}>
                                <InputField label={i18n.t('entity.transaction.property.media_price')}
                                            disabled
                                            name="mediaPrice"
                                            type="number"
                                            placeholder={i18n.t('entity.transaction.property.media_price')}
                                />
                            </Col>
                            <Col span={4}>
                                <InputField label={i18n.t('entity.transaction.property.managed_price')}
                                            disabled
                                            name="managedPrice"
                                            type="number"
                                            placeholder={i18n.t('entity.transaction.property.managed_price')}
                                />
                            </Col>
                            <Col span={4}>
                                <InputField label={i18n.t('entity.transaction.property.pvp_price')}
                                            disabled
                                            name="pvpPrice"
                                            type="number"
                                            placeholder={i18n.t('entity.transaction.property.pvp_price')}
                                />
                            </Col>
                            <Col span={8}>
                                <InputField label={i18n.t('entity.transaction.property.price_type')}
                                            disabled
                                            name="priceType"
                                            placeholder={i18n.t('entity.transaction.property.price_type')}
                                />
                            </Col>
                        </Row>}
                        <Row gutter={15}>
                            <Col span={24}>
                                <TextAreaField label={i18n.t('entity.transaction.property.comments')}
                                               name="comments"
                                               placeholder={i18n.t('entity.transaction.property.comments')}
                                />
                            </Col>

                        </Row>
                        <Portal.Out id="form-actions"
                                    className="form-actions"
                        />
                        <Portal.In target={actionsPortalTarget}>
                            <Button type="primary"
                                    disabled={props.isSubmitting || !props.isValid }
                                    onClick={props.handleSubmit}>
                                {i18n.t('global.accept')}
                            </Button>
                        </Portal.In>
                    </Form>
                )}
            </Formik>
            <ChildrenSidebar visible={createPackSidebarIsVisible}
                             title={i18n.t('entity.pack.create_title')}
                             onClose={event => setCreatePackSidebarIsVisible(false)}>
                <PackForm actionsPortalTarget="children-sidebar-footer"
                          onSubmit={createPack}
                />
            </ChildrenSidebar>
            <ChildrenSidebar visible={createClientSidebarIsVisible}
                             title={i18n.t('entity.client.create_title')}
                             onClose={event => setCreateClientSidebarIsVisible(false)}>
                <ClientForm actionsPortalTarget="children-sidebar-footer"
                            onSubmit={createClient}
                />
            </ChildrenSidebar>
            <ChildrenSidebar visible={createMediaSidebarIsVisible}
                             title={i18n.t('entity.media.create_title')}
                             onClose={event => setCreateMediaSidebarIsVisible(false)}>
                <MediaForm actionsPortalTarget="children-sidebar-footer"
                           onSubmit={createMedia}
                />
            </ChildrenSidebar>
        </Fragment>
    );
};

TransactionForm.defaultProps = {
    actionsPortalTarget: 'sidebar-footer',
    action: ACTION.UPDATE,
    transaction: {},
    onSubmit: () => {}
};

export default TransactionForm;
