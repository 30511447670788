import React, { Fragment, useContext, useState } from 'react';
import { Form, Formik } from 'formik';
import InputField from '../../../Form/Fields/InputField';
import Button from '../../../Button/Button';
import getValidationSchema from './get-validation-schema';
import Portal from '../../../Portal/Portal';
import { I18nContext } from 'react-i18next';
import { Col, Row } from 'antd';
import { AppContext } from '../../../../context/AppProvider';
import DatePickerField from '../../../Form/Fields/DatePickerField';
import dayjs from 'dayjs';
import { PlusOutlined } from '@ant-design/icons';
import ProviderForm from '../../provider/ProviderForm/ProviderForm';
import { notify } from '../../../../modules/notify/notify';
import DeepChildrenSidebar from '../../../Sidebar/DeepChildrenSidebar';
import { ACTION } from '../../constants';
import ProviderSelectField from '../../provider/ProviderSelectField/ProviderSelectField';
import UnsavedPrompt from '../../../UnsavedPrompt/UnsavedPrompt';

const PackForm = ({pack, actionsPortalTarget, action, ...props}) => {
    const {i18n} = useContext(I18nContext);
    const appContext = useContext(AppContext);
    const [createProviderSidebarIsVisible, setCreateProviderSidebarIsVisible] = useState(false);

    const createProvider = values => {
        appContext.services.provider
            .create(values)
            .then(response => {
                if (!response.success) return;
                notify.success({description: i18n.t('global.item_updated')});
                setCreateProviderSidebarIsVisible(false);
            })
            .catch(console.error);
    };

    return (
        <Formik validationSchema={getValidationSchema(i18n)}
                enableReinitialize={true}
                initialValues={{
                    name: pack.name || '',
                    price: pack.price || 0,
                    totalUnits: pack.totalUnits || 0,
                    pendingUnits: pack.pendingUnits || 0,
                    expirationDate: pack.expirationDate || dayjs().unix(),
                    provider: pack.provider?.idProvider ? {label: pack.provider.name, value: pack.provider.idProvider} : {},
                }}
                onSubmit={(values, {setSubmitting}) => {
                    const {provider, ...rest} = values;
                    const adaptedValues = {
                        ...rest,
                        idProvider: provider.value
                    }
                    props.onSubmit(adaptedValues);
                    setSubmitting(false);
                }}>
            {props => (
                <Fragment>
                    <Form>
                        <UnsavedPrompt when={props.dirty} />
                        <Row gutter={15}>
                            <Col span={12}>
                                <InputField label={i18n.t('entity.pack.property.name')}
                                            name="name"
                                            placeholder={i18n.t('entity.pack.property.name')}
                                />
                            </Col>
                            <Col span={12}>
                                <InputField label={i18n.t('entity.pack.property.price')}
                                            name="price"
                                            type="number"
                                            placeholder={i18n.t('entity.pack.property.price')}
                                />
                            </Col>
                        </Row>
                        <Row gutter={15}>
                            <Col span={12}>
                                <InputField label={i18n.t('entity.pack.property.total_units')}
                                            name="totalUnits"
                                            type="number"
                                            placeholder={i18n.t('entity.pack.property.total_units')}
                                            onChange={event => {
                                                console.log(event.target.value)
                                                props.setFieldValue('totalUnits', event.target.value);
                                                if (action === ACTION.CREATE) props.setFieldValue('pendingUnits', event.target.value);
                                            }}
                                />
                            </Col>
                            <Col span={12}>
                                <InputField label={i18n.t('entity.pack.property.pending_units')}
                                            name="pendingUnits"
                                            type="number"
                                            disabled={action === ACTION.CREATE}
                                            placeholder={i18n.t('entity.pack.property.pending_units')}
                                />
                            </Col>
                        </Row>
                        <Row gutter={15}>
                            <Col span={12}>
                                <DatePickerField label={i18n.t('entity.pack.property.expiration_date')}
                                                 name="expirationDate"
                                                 placeholder={i18n.t('entity.pack.property.expiration_date')}
                                                 allowEmpty={false}
                                                 allowClear={false}
                                                 onChange={value => {
                                                     props.setFieldValue('expirationDate', value.unix());
                                                 }}
                                />
                            </Col>
                            <Col span={12}>
                                <ProviderSelectField label={i18n.t('entity.pack.property.provider')}
                                                     name="provider"
                                                     allowClear={false}
                                                     disabled={action === ACTION.UPDATE}
                                                     placeholder={i18n.t('entity.pack.property.provider')}
                                                     append={<Button shape="circle"
                                                                     size="small"
                                                                     disabled={action === ACTION.UPDATE}
                                                                     icon={<PlusOutlined />}
                                                                     onClick={event => setCreateProviderSidebarIsVisible(true)}
                                                     />}
                                                     onChange={provider => {
                                                         props.setFieldValue('provider', provider)
                                                     }}
                                />
                            </Col>
                        </Row>
                        <Portal.Out id="form-actions"
                                    className="form-actions"
                        />
                        <Portal.In target={actionsPortalTarget}>
                            <Button type="primary"
                                    disabled={props.isSubmitting || !props.isValid}
                                    onClick={props.handleSubmit}>
                                {i18n.t('global.accept')}
                            </Button>
                        </Portal.In>
                    </Form>
                    <DeepChildrenSidebar visible={createProviderSidebarIsVisible}
                                         title={i18n.t('entity.provider.create_title')}
                                         onClose={event => setCreateProviderSidebarIsVisible(false)}>
                        <ProviderForm actionsPortalTarget="deep-children-sidebar-footer"
                                      onSubmit={createProvider}
                        />
                    </DeepChildrenSidebar>
                </Fragment>
            )}
        </Formik>
    );
};

PackForm.defaultProps = {
    actionsPortalTarget: 'sidebar-footer',
    action: ACTION.UPDATE,
    pack: {},
    onSubmit: () => {}
};

export default PackForm;
