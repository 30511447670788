import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { I18nContext } from 'react-i18next';
import FiltersBar from '../../../FiltersBar/FiltersBar';
import { Form, Formik } from 'formik';
import InputField from '../../../Form/Fields/InputField';
import Button from '../../../Button/Button';
import MoreFiltersToggle from '../../../MoreFiltersToggle/MoreFiltersToggle';
import RadioGroupField from '../../../Form/Fields/RadioGroupField';
import RangePickerField from '../../../Form/Fields/RangePickerField';
import SelectField from '../../../Form/Fields/SelectField';
import { AppContext } from '../../../../context/AppProvider';
import ClientSelectField from '../../client/ClientSelectField/ClientSelectField';
import MediaSelectField from '../../media/MediaSelectField/MediaSelectField';
import PackSelectField from '../../pack/PackSelectField/PackSelectField';

const TransactionFilters = props => {
    const {i18n} = useContext(I18nContext);
    const appContext = useContext(AppContext);
    const hasActiveFilters = !!props.filters && Object.keys(props.filters).length;
    const [status, setStatus] = useState([]);

    const findStatus = () => {
        appContext.services.status
            .findList()
            .then(status => setStatus(status));
    };

    useEffect(() => {
        findStatus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <FiltersBar showWarning={!!props.filters && Object.keys(props.filters).length}
                    onReset={() => props.onFilter({})}>
            <Formik enableReinitialize
                    initialValues={{
                        query: props.filters?.query || '',
                        active: props.filters?.active || undefined,
                        shared: props.filters?.active || undefined,
                        creationRange: props.filters?.creationRange || [],
                        client: props.filters?.client || {},
                        media: props.filters?.media || {},
                        status: props.filters?.status || '',
                        pack: props.filters?.pack || {},
                        publicationRange: props.filters?.publicationRange || []
                    }}
                    onSubmit={(values, {setSubmitting}) => {
                        props.onFilter(values);
                        setSubmitting(false);
                    }}>
                {props => (
                    <Form>
                        <Row gutter={15}>
                            <Col span={22}>
                                <Row gutter={15}>
                                    <Col span={24}>
                                        <InputField name="query"
                                                    type="text"
                                                    size="large"
                                                    placeholder={i18n.t('global.search')}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={2}>
                                <Button type="primary"
                                        size="large"
                                        htmlType="submit"
                                        block
                                        loading={props.isSubmitting}
                                        disabled={props.isSubmitting || !props.isValid}
                                        onClick={props.handleSubmit}>
                                    {i18n.t('global.filter')}
                                </Button>
                            </Col>
                        </Row>
                        <MoreFiltersToggle open={hasActiveFilters}>
                            <Row gutter={15}>
                                <Col span={4}>
                                    <RadioGroupField label={i18n.t('global.active')}
                                                     name="active"
                                                     options={[
                                                         {label: i18n.t('global.yes'), value: 'yes'},
                                                         {label: i18n.t('global.no'), value: 'no'},
                                                         {label: i18n.t('global.all'), value: 'all'}
                                                     ]}
                                                     onChange={value => props.setFieldValue('active', value)}
                                    />
                                </Col>
                                <Col span={4}>
                                    <RadioGroupField label={i18n.t('entity.transaction.property.shared')}
                                                     name="shared"
                                                     options={[
                                                         {label: i18n.t('global.yes'), value: 'yes'},
                                                         {label: i18n.t('global.no'), value: 'no'},
                                                         {label: i18n.t('global.all'), value: 'all'}
                                                     ]}
                                                     onChange={value => props.setFieldValue('shared', value)}
                                    />
                                </Col>
                                <Col span={4}>
                                    <ClientSelectField label={i18n.t('entity.client.title')}
                                                       name="client"
                                                       allowClear
                                                       placeholder={i18n.t('entity.client.title')}
                                                       onChange={value => props.setFieldValue('client', value)}
                                    />
                                </Col>
                                <Col span={4}>
                                    <SelectField label={i18n.t('entity.status.title')}
                                                 name="status"
                                                 allowClear
                                                 options={status}
                                                 placeholder={i18n.t('entity.status.title')}
                                                 onChange={value => props.setFieldValue('status', value)}
                                    />
                                </Col>
                                <Col span={4}>
                                    <MediaSelectField label={i18n.t('entity.media.title')}
                                                      name="media"
                                                      allowClear
                                                      placeholder={i18n.t('entity.media.title')}
                                                      onChange={value => props.setFieldValue('media', value)}
                                    />
                                </Col>
                                <Col span={4}>
                                    <PackSelectField label={i18n.t('entity.pack.title')}
                                                     name="pack"
                                                     allowClear
                                                     placeholder={i18n.t('entity.pack.title')}
                                                     onChange={value => props.setFieldValue('pack', value)}
                                    />
                                </Col>
                                <Col span={6}>
                                    <RangePickerField label={i18n.t('global.created_between')}
                                                      name="creationRange"
                                                      onChange={value => props.setFieldValue('creationRange', value)}
                                    />
                                </Col>
                                <Col span={6}>
                                    <RangePickerField label={i18n.t('global.publication_between')}
                                                      name="publicationRange"
                                                      onChange={value => props.setFieldValue('publicationRange', value)}
                                    />
                                </Col>
                            </Row>
                        </MoreFiltersToggle>
                    </Form>
                )}
            </Formik>
        </FiltersBar>
    );
}

TransactionFilters.defaultProps = {
    onFilter: () => {}
};

export default TransactionFilters;
