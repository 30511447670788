import BaseService from './BaseService';
import convertToSelectOptions from '../modules/convert-to-select-options/convert-to-select-options';

export default class UserService extends BaseService {
    entityEndpoint = 'user';

    login(formData) {
        const endpoint = `login_check`;

        return this.fetch(`${this.API_URL}${endpoint}`, {
            method: 'POST',
            body: JSON.stringify(formData)
        }).then(response => {
            this.setToken(response.token);

            return Promise.resolve(response);
        });
    }

    search(page = 1, filters = {}, list = undefined) {
        const adaptedFilters = {
            ...filters,
            creationRange: filters?.creationRange ? filters.creationRange.join(',') : undefined
        };
        return super.search.call(this, this.entityEndpoint, page, adaptedFilters, list);
    }

    findList(convert = true) {
        const endpoint = `${this.entityEndpoint}/list`;

        return this
            .fetch(`${this.API_URL}${endpoint}`, {method: 'GET'})
            .then(response => convert ? convertToSelectOptions(response, 'idUser', 'email') : response);
    }

    findAll(page = 1) {
        return super.findAll.call(this, this.entityEndpoint, page);
    }

    findById(idUser) {
        return super.findById.call(this, this.entityEndpoint, idUser);
    }

    remove(idUser) {
        return super.remove.call(this, this.entityEndpoint, idUser);
    }

    toggleActive(idUser, active) {
        return super.toggleActive.call(this, this.entityEndpoint, idUser, active);
    }

    create(values) {
        return super.create.call(this, this.entityEndpoint, values);
    }

    update(idUser, values) {
        return super.update.call(this, this.entityEndpoint, idUser, values);
    }

    promote(idUser) {
        const endpoint = `${this.entityEndpoint}/${idUser}/promote`;

        return this
            .fetch(`${this.API_URL}${endpoint}`, {method: 'PUT'})
            .then(response => response);
    }

    demote(idUser) {
        const endpoint = `${this.entityEndpoint}/${idUser}/demote`;

        return this
            .fetch(`${this.API_URL}${endpoint}`, {method: 'PUT'})
            .then(response => response);
    }
}
