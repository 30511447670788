export const ACTION = {
    CREATE: 'create',
    READ: 'read',
    UPDATE: 'update',
    DELETE: 'delete'
};

export const PACK_ACTION = {
    ADD: 'add',
    DEDUCT: 'deduct'
};
